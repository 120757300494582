import React from 'react';

import ProductOneProvider from '@contexts/product-one';
import ProductTwoProvider from '@contexts/product-two';
import FeasibilityAnalysisProvider from '@contexts/feasibility-analysis';
import ComparisonBillProvider from '@contexts/comparison-bill';
import ContractedDemandProvider from '@contexts/contracted-demand';
import LowTensionProvider from '@contexts/low-tension';
import WhiteLabelProvider from '@contexts/white-label';

interface SimulatorsRouteProps {
  children: JSX.Element;
}

const SimulatorsRoute: React.FC<SimulatorsRouteProps> = ({ children }) => {
  return (
    <WhiteLabelProvider>
      <ProductOneProvider>
        <ProductTwoProvider>
          <FeasibilityAnalysisProvider>
            <ComparisonBillProvider>
              <LowTensionProvider>
                <ContractedDemandProvider>{children}</ContractedDemandProvider>
              </LowTensionProvider>
            </ComparisonBillProvider>
          </FeasibilityAnalysisProvider>
        </ProductTwoProvider>
      </ProductOneProvider>
    </WhiteLabelProvider>
  );
};

export default SimulatorsRoute;
