import numeral from 'numeral';

import { ISampleMonthSimulation } from '@contexts/types';
import { IFeasibilityAnalysisSimulationUnitReport } from '@contexts/feasibility-analysis/types';

const parseFreeMarketTable = (data: IFeasibilityAnalysisSimulationUnitReport): Record<string, any>[] => {
  function findAclPriceInYear(year: number) {
    const aclObject = data.premises?.aclPrices?.find((item) => parseInt(item.year, 10) === year);
    return aclObject?.fixedPrice;
  }

  const sampleMonthSimulation: ISampleMonthSimulation = data.tariffFlagScenarios.yellow.sampleMonthSimulation;

  const initialValues = {
    demandOffPeak: {
      quantity: data.aclContractedDemandOffPeak,
      tariff: sampleMonthSimulation.targetMarket.tariff.stimulatedDemandOffPeak,
    },
    demandPeak: {
      quantity: data.aclContractedDemandPeak,
      tariff: sampleMonthSimulation.targetMarket.tariff.stimulatedDemandPeak,
    },
    tusdPeak: {
      quantity: sampleMonthSimulation.targetMarket.consumptionPeak,
      tariff: sampleMonthSimulation.targetMarket.tariff.stimulatedDistributionTariffPeak,
    },
    tusdOffPeak: {
      quantity: sampleMonthSimulation.targetMarket.consumptionOffPeak,
      tariff: sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak,
    },
    tePrice: {
      value: findAclPriceInYear(sampleMonthSimulation.report.year),
    },
    totalWithoutTaxes: sampleMonthSimulation.targetMarket.totalWithoutTaxes,
    totalWithTaxes: sampleMonthSimulation.targetMarket.totalWithTaxes,
  };

  const result = [
    {
      item: 'Demanda Contratada Fora Ponta (kW)',
      FreeMarketquantity: numeral(initialValues.demandOffPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.demandOffPeak?.tariff).format('$ 0,0.00') || '-',
      FreeMarkettotal:
        numeral(initialValues.demandOffPeak?.quantity * (initialValues.demandOffPeak?.tariff ?? 0)).format(
          '$ 0,0.00',
        ) || '-',
    },
    {
      item: 'TUSD Ponta (MWh)',
      FreeMarketquantity: numeral(initialValues.tusdPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.tusdPeak?.tariff).format('$ 0,0.00') || '-',
      FreeMarkettotal:
        numeral(initialValues.tusdPeak?.quantity * (initialValues.tusdPeak?.tariff ?? 0)).format('$ 0,0.00') || '-',
    },
    {
      item: 'TUSD Fora Ponta (MWh)',
      FreeMarketquantity: numeral(initialValues.tusdOffPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.tusdOffPeak?.tariff).format('$ 0,0.00') || '-',
      FreeMarkettotal:
        numeral(initialValues.tusdOffPeak?.quantity * (initialValues.tusdOffPeak?.tariff ?? 0)).format('$ 0,0.00') ||
        '-',
    },
    {
      item: 'TE Ponta (MWh)',
      FreeMarketquantity: numeral(initialValues.tusdPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.tePrice.value).format('$ 0,0.00'),
      FreeMarkettotal:
        initialValues.tePrice.value &&
        numeral(initialValues.tusdPeak?.quantity * initialValues.tePrice.value).format('$ 0,0.00'),
    },
    {
      item: 'TE Fora Ponta (MWh)',
      FreeMarketquantity: numeral(initialValues.tusdOffPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.tePrice.value).format('$ 0,0.00'),
      FreeMarkettotal:
        initialValues.tePrice.value &&
        numeral(initialValues.tusdOffPeak?.quantity * initialValues.tePrice.value).format('$ 0,0.00'),
    },
    {
      item: 'Impostos',
      FreeMarketquantity: '-',
      FreeMarkettariff: '-',
      FreeMarkettotal:
        numeral(initialValues.totalWithTaxes - initialValues.totalWithoutTaxes).format('$ 0,0.00') || '-',
    },
    {
      FreeMarketquantity: 'Total (c/ impostos):',
      FreeMarkettotal: numeral(initialValues.totalWithTaxes).format('$ 0,0.00') || '-',
    },
  ];

  if (initialValues.demandPeak.quantity > 0) {
    result.splice(1, 0, {
      item: 'Demanda Contratada Ponta (kW)',
      FreeMarketquantity: numeral(initialValues.demandPeak?.quantity).format('0,0.00') || '-',
      FreeMarkettariff: numeral(initialValues.demandPeak?.tariff).format('$ 0,0.00') || '-',
      FreeMarkettotal:
        (initialValues.demandPeak.tariff &&
          numeral(initialValues.demandPeak?.quantity * initialValues.demandPeak?.tariff).format('$ 0,0.00')) ||
        '-',
    });
  }

  return result;
};

export default parseFreeMarketTable;
