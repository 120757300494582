import React, { useState } from 'react';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';
import { IFeasibilityAnalysisSimulatorInputs } from '@contexts/feasibility-analysis/types';

import FeasibilityAnalysisSimulatorResult from './feasibility-analysis-simulator-result';
import FeasibilityAnalysisSimulatorForm from './feasibility-analysis-simulator-form';

const FeasibilityAnalysisSimulator = () => {
  const [simulatorResult, setSimulatorResult] = useState<IFeasibilityAnalysisSimulation>();
  const [simulatorInputs, setSimulatorInputs] = useState<IFeasibilityAnalysisSimulatorInputs>();

  return (
    <>
      {simulatorResult ? (
        <FeasibilityAnalysisSimulatorResult {...{ simulatorResult, simulatorInputs }} />
      ) : (
        <FeasibilityAnalysisSimulatorForm
          {...{
            setSimulatorResult,
            setSimulatorInputs,
          }}
        />
      )}
    </>
  );
};

export default FeasibilityAnalysisSimulator;
