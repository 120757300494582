import React from 'react';

import AboutMarketplace from '@components/molecules/product-one/simulation/about-marketplace';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import Cover from '@components/molecules/general/simulation/cover';

interface IProductOneSimulationIntroductorySection {
  leadName: string;
}

const ProductOneSimulationIntroductorySection = ({ leadName }: IProductOneSimulationIntroductorySection) => {
  return (
    <>
      <SlideContainer>
        <Cover companyName={leadName} />
      </SlideContainer>
      <SlideContainer>
        <AboutMarketplace />
      </SlideContainer>
    </>
  );
};

export default ProductOneSimulationIntroductorySection;
