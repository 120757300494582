import styled from 'styled-components';

export const TitleContainer = styled.div.attrs({
  className: 'w-full grid grid-cols-1 ml-6',
})``;

export const NormalTitle = styled.h2.attrs({
  className: 'text-7xl font-normal inline leading-none',
})``;

export const BoldTitle = styled.h2.attrs({
  className: 'text-7xl font-black inline leading-none',
})``;

export const GridBody = styled.div.attrs({
  className: 'w-full grid grid-cols-8 justify-between h-full',
})``;

export const GridColumn = styled.div.attrs({
  className: 'w-full flex flex-col gap-6 h-full p-5 col-span-4 justify-between align-start',
})``;

export const ClarkeLogoHorizontal = styled.img.attrs({
  className: 'w-2/4 col-span-full pl-4',
})``;

export const EnergyTowerIllustration = styled.img.attrs({
  className: "w-full h-full object-cover object-center border-1 border-solid border-black",
})``;

export const GridContainerGreen = styled.div.attrs({
  className: 'w-full grid grid-cols-1 justify-between h-full bg-primary-60',
})``;

export const BlackHeaderLine = styled.div.attrs({
  className: 'w-24 h-5 ml-7 bg-black',
})``;
