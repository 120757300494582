import { ENERGY_DISTRIBUTION_COMPANIES as energyDistributionCompanies } from '@utils/constants';

import ComparisonBillProposalPreviewResultItem from '../../simulator/comparison-bill-proposal-preview-result-item';
interface IComparisonBillProposalKeyMetricsPanel {
  energyDistributionCompany?: number;
  currentTotalWithTaxes: number;
  targetTotalWithTaxes: number;
  totalSavingWithTaxes: number;
}
export const ComparisonBillProposalKeyMetricsPanel = ({
  energyDistributionCompany,
  currentTotalWithTaxes,
  targetTotalWithTaxes,
  totalSavingWithTaxes,
}: IComparisonBillProposalKeyMetricsPanel) => {
  const distributionCompaniesList = Object.entries(energyDistributionCompanies);

  const nameEnergyDistributionCompany = distributionCompaniesList.find((edc) => {
    return edc[0] === energyDistributionCompany?.toString();
  });
  return (
    <div>
      <div className="mt-5 border-t border-gray-200">
        <dl className="sm:divide-y sm:divide-gray-200">
          <ComparisonBillProposalPreviewResultItem
            label="Antes: Mercado Cativo"
            description={`Distribuição + Energia (${nameEnergyDistributionCompany})`}
            value={currentTotalWithTaxes}
          />
          <ComparisonBillProposalPreviewResultItem
            label="Depois: Mercado Livre"
            description={`Distribuição (${nameEnergyDistributionCompany}) + Energia (Fornecedor ACL)`}
            value={targetTotalWithTaxes}
          />
          <ComparisonBillProposalPreviewResultItem
            label="Economia"
            description="Total Economizado (com taxas)"
            value={totalSavingWithTaxes}
          />
        </dl>
      </div>
    </div>
  );
};
