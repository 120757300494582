import React from 'react';

import { IComparisonBillProposal } from '@contexts/comparison-bill/types';
import { ComparisonBillSimulationParser } from '@contexts/comparison-bill/parser';

import { SlideContainer } from '@components/molecules/general/simulation-styled-components';
import FreeMarketBill, { IFreeMarketBill } from '@components/molecules/comparison-bill/simulation/free-market-bill';
import CaptiveMarketBill, {
  ICaptiveMarketBill,
} from '@components/molecules/comparison-bill/simulation/captive-market-bill';
import ComparisonResult, {
  IComparisonResult,
} from '@components/molecules/comparison-bill/simulation/comparison-result';

interface IComparisonBillSimulationEconomySection {
  proposal?: IComparisonBillProposal;
}

const ComparisonBillSimulationEconomySection = ({ proposal }: IComparisonBillSimulationEconomySection) => {
  const [captiveMarketBillData, setCaptiveMarketBillData] = React.useState<ICaptiveMarketBill>();
  const [freeMarketBillData, setFreeMarketBillData] = React.useState<IFreeMarketBill>();
  const [comparisonResultData, setComparisonResultData] = React.useState<IComparisonResult>();

  React.useEffect(() => {
    if (proposal) {
      const comparisonBillSimulationParser = new ComparisonBillSimulationParser(proposal);

      const parsedCaptiveMarketBillData = comparisonBillSimulationParser.parseCaptiveMarketBillData();
      setCaptiveMarketBillData(parsedCaptiveMarketBillData);

      const parsedFreeMarketBillData = comparisonBillSimulationParser.parseFreeMarketBillData();
      setFreeMarketBillData(parsedFreeMarketBillData);

      const parsedComparisonResultData = comparisonBillSimulationParser.parseComparisonResultData();
      setComparisonResultData(parsedComparisonResultData);
    }
  }, [proposal]);

  return (
    <>
      {captiveMarketBillData && (
        <SlideContainer withPadding>
          <CaptiveMarketBill {...captiveMarketBillData} />
        </SlideContainer>
      )}
      {freeMarketBillData && (
        <SlideContainer withPadding>
          <FreeMarketBill {...freeMarketBillData} />
        </SlideContainer>
      )}
      {comparisonResultData && (
        <SlideContainer withPadding>
          <ComparisonResult {...comparisonResultData} />
        </SlideContainer>
      )}
    </>
  );
};

export default ComparisonBillSimulationEconomySection;
