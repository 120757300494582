import React from 'react';

import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';

import { isObjectEmpty } from '@utils/common';

import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import ProductEconomySlide from '@components/molecules/general/simulation/economy-slide';

interface IProductOneSimulationGroupEconomySection {
  simulationResults?: Array<IProductOneSimulationExtendedResult | undefined>;
}

const getGroupResultsPerContractDurationData = (
  simulationResults?: Array<IProductOneSimulationExtendedResult | undefined>,
) => {
  if (simulationResults) {
    const filteredSimulationsResults = simulationResults.filter((simulationResult) => !isObjectEmpty(simulationResult));

    const bestSimulationResult = filteredSimulationsResults[0];
    const proposalHasMultipleResults = filteredSimulationsResults.length > 1;

    return { bestSimulationResult, proposalHasMultipleResults };
  } else return { bestSimulationResult: undefined, proposalHasMultipleResults: undefined };
};

const ProductOneSimulationGroupEconomySection = ({ simulationResults }: IProductOneSimulationGroupEconomySection) => {
  return (
    <>
      <SlideContainer>
        <GreenSectionSlide title="Proposta de Economia" />
      </SlideContainer>
      {simulationResults?.map(
        (simulationResult, index) =>
          simulationResult && (
            <SlideContainer key={`product-one-group-result-per-contract-duration-${index}`}>
              <ProductEconomySlide showSideData simulationResult={simulationResult} hasTraderDescription={false} />
            </SlideContainer>
          ),
      )}
    </>
  );
};

export default ProductOneSimulationGroupEconomySection;
