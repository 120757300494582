import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';

import FeasibilityAnalysisFlagEconomy, {
  IFeasibilityAnalysisFlagEconomy,
} from '@components/atoms/feasibility-analysis/feasibility-analysis-flag-economy';

interface IFeasibilityAnalysisSimulatorResultEconomy {
  simulatorResult?: IFeasibilityAnalysisSimulation;
}

const FeasibilityAnalysisSimulatorResultEconomy = ({ simulatorResult }: IFeasibilityAnalysisSimulatorResultEconomy) => {
  return (
    <div>
      {simulatorResult && (
        <div className="mt-5 border-t border-gray-200 mb-12">
          <dl className="sm:divide-y sm:divide-gray-200">
            {['green', 'yellow', 'redP1', 'redP2'].map((tariffFlag) => (
              <FeasibilityAnalysisFlagEconomy
                key={`feasibility-analysis-economy-in-flag-${tariffFlag}`}
                simulationData={simulatorResult}
                tariffFlag={tariffFlag as IFeasibilityAnalysisFlagEconomy['tariffFlag']}
              />
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default FeasibilityAnalysisSimulatorResultEconomy;
