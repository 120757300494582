import React from 'react';

import {
  IFeasibilityAnalysisSimulation,
  IFeasibilityAnalysisSimulationUnitReport,
} from '@contexts/feasibility-analysis/types';
import { FeasibilityAnalysisSimulationParser } from '@contexts/feasibility-analysis/parser';

import FeasibilityAnalysisSimulationFooter from '@components/atoms/feasibility-analysis/simulation/feasibility-analysis-simulation-footer';
import FeasibilityAnalysisSimulationHeader from '@components/atoms/feasibility-analysis/simulation/feasibility-analysis-simulation-header';

import FeasibilityAnalysisProposalSectionBaseLowerSubsection, {
  IFeasibilityAnalysisProposalSectionBaseLowerSubsection,
} from './section-base-lower-subsection';
import FeasibilityAnalysisProposalSectionBaseSideSubsection, {
  IFeasibilityAnalysisProposalSectionBaseSideSubsection,
} from './section-base-side-subsection';
import FeasibilityAnalysisProposalSectionBaseUpperSubsection, {
  IFeasibilityAnalysisProposalSectionBaseUpperSubsection,
} from './section-base-upper-subsection';

export interface IFeasibilityAnalysisTableBase {
  columns: any[];
  data: any[];
}

interface IFeasibilityAnalysisProposalSectionBase {
  leadName: string;
  simulationResult: IFeasibilityAnalysisSimulation;
  unitReport?: IFeasibilityAnalysisSimulationUnitReport;
  generalAnalysis?: boolean;
}

const FeasibilityAnalysisProposalSectionBase = ({
  unitReport,
  leadName,
  simulationResult,
  generalAnalysis,
}: IFeasibilityAnalysisProposalSectionBase) => {
  const [upperSubsectionData, setUpperSubsectionData] =
    React.useState<IFeasibilityAnalysisProposalSectionBaseUpperSubsection>();

  const [sideSubsectionData, setSideSubsectionData] =
    React.useState<IFeasibilityAnalysisProposalSectionBaseSideSubsection>();

  const [lowerSubsectionData, setLowerSubsectionData] =
    React.useState<IFeasibilityAnalysisProposalSectionBaseLowerSubsection>();

  const scenarioWrapper = generalAnalysis
    ? simulationResult.generalReport.tariffFlagScenarios
    : unitReport?.tariffFlagScenarios;

  const parseSubsectionsData = () => {
    if (!scenarioWrapper) return;

    const feasilibyAnalysisSimulationParser = new FeasibilityAnalysisSimulationParser(
      leadName,
      simulationResult,
      unitReport,
      generalAnalysis,
    );

    const defaultScenarioSimulationExtendedResult =
      feasilibyAnalysisSimulationParser.parseFeasibilityAnalysisSimulationExtendedResultByTariffFlag(
        scenarioWrapper.default,
      );

    const parsedUpperSubsectionData = feasilibyAnalysisSimulationParser.parseUpperSubsectionData(scenarioWrapper);
    setUpperSubsectionData(parsedUpperSubsectionData);

    const parsedSideSubsectionData = feasilibyAnalysisSimulationParser.parseSideSubsectionsData(
      defaultScenarioSimulationExtendedResult,
      scenarioWrapper,
    );
    setSideSubsectionData(parsedSideSubsectionData);

    const parsedLowerSubsectionData = feasilibyAnalysisSimulationParser.parseLowerSubsectionData(
      defaultScenarioSimulationExtendedResult,
      scenarioWrapper,
    );
    setLowerSubsectionData(parsedLowerSubsectionData);
  };

  React.useEffect(() => {
    if (simulationResult) parseSubsectionsData();
  }, [simulationResult, unitReport, generalAnalysis]);

  return (
    <div className="flex flex-col p-6 w-full">
      <FeasibilityAnalysisSimulationHeader unitName={unitReport?.nickname} leadName={leadName} />
      <div className="flex flex-col lg:flex-row gap-5 w-full">
        <FeasibilityAnalysisProposalSectionBaseSideSubsection {...sideSubsectionData} />
        <div className="flex flex-col w-full">
          <FeasibilityAnalysisProposalSectionBaseUpperSubsection {...upperSubsectionData} />
          <FeasibilityAnalysisProposalSectionBaseLowerSubsection {...lowerSubsectionData} />
        </div>
      </div>
      <FeasibilityAnalysisSimulationFooter />
    </div>
  );
};

export default FeasibilityAnalysisProposalSectionBase;
