import YellowFlag from '@assets/images/yellow-flag.svg';
import GreenFlag from '@assets/images/green-flag.svg';
import RedFlag from '@assets/images/red-flag.svg';

const TariffFlagEconomyTableColumns = [
  {
    key: 'item',
    label: '',
    icon: '',
    render: (value: any): string => value,
  },
  {
    key: 'greenFlag',
    label: 'Verde',
    icon: GreenFlag,
    render: (value: any): string => value,
  },
  {
    key: 'yellowFlag',
    label: 'Amarela',
    icon: YellowFlag,
    render: (value: any): string => value,
  },
  {
    key: 'redP1Flag',
    label: 'Vermelha P1',
    icon: RedFlag,
    render: (value: any): string => value,
  },
  {
    key: 'redP2Flag',
    label: 'Vermelha P2',
    icon: RedFlag,
    render: (value: any): string => value,
  },
];

export default TariffFlagEconomyTableColumns;
