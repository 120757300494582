import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { validationDatePricesExpirationDate } from '@utils/datetime';
import { IWhiteLabelSupplierPresentation } from '@contexts/white-label/type';
import useWhiteLabel from '@hooks/use-white-label';

import heroLogo from '@assets/newProposal/heroLogo.svg';
import SectionProposalHeroContainer from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import './styles.css';

interface IPresentation extends Omit<IWhiteLabelSupplierPresentation, 'email' | 'phone'> {}
interface CoverSectionProps {
  pricesExpirationDate?: string;
  leadName?: string;
}

function CoverSection({ pricesExpirationDate }: CoverSectionProps) {
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const [presentation, setPresentation] = useState<IPresentation>();

  useEffect(() => {
    if (whiteLabelSupplier) {
      const { presentation } = whiteLabelSupplier;
      setPresentation(presentation);
    }
  }, [whiteLabelSupplier]);

  return (
    <SlideContainer className={twMerge('min-h-fit h-screen', !isManagementPath && 'gradient-container')}>
      <SectionProposalHeroContainer
        isCoverMain={true}
        className="sm:flex-row h-full xl:min-h-100"
        darkTheme={!isManagementPath}
      >
        <div className="main-container min-h-full">
          {isWhiteLabelProposal ? (
            <img
              className="logo-style w-auto max-w-48 rounded-xl"
              src={presentation?.logoUrl as string}
              alt={`logo da fornecedor ${presentation?.name}`}
            />
          ) : (
            <img
              src={heroLogo}
              alt="Logo da Clarke"
              className={twMerge(isManagementPath ? 'logo-style' : 'logo-style w-2/4')}
            />
          )}

          <span className="text-container">
            {isManagementPath ? (
              <h2
                style={{
                  color: `${isWhiteLabelProposal && 'var(--text-white-label-main)'}`,
                }}
                className={twMerge('font-bold', isWhiteLabelProposal && 'text-display-medium 2xl:text-display-large ')}
              >
                Proposta de Economia
              </h2>
            ) : (
              <span className={twMerge('pl-5 sm:w-full sm:ml-10 mb-5 sm:mb-10 sm:font-bold whitespace-nowrap')}>
                <p className="xl:text-heading-2xlarge">Proposta de Economia</p>
                <h1 className="xl:text-display-xlarge ">Clarke Varejo</h1>
              </span>
            )}
            <p
              className={`${isWhiteLabelProposal ? 'text-heading-small text-white-label-main' : 'text-heading-small'}`}
            >
              Válido até {validationDatePricesExpirationDate(pricesExpirationDate)}
            </p>
          </span>
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default CoverSection;
