import { AxiosResponse } from 'axios';
import numeral from 'numeral';

import { getParsedCustomFee, parseEnergyPrices, parseFinancialProjectionPayload } from '@contexts/parser';
import {
  IProductOneProposal,
  IProductOneSimulation,
  IProductOneSimulationExtendedResult,
  IProductOneSimulationUnitExtendedResult,
  IProductOneSimulatorInputs,
} from '@contexts/product-one/types';
import { IGeneralTitleAndValuePair, IResponseBase } from '@contexts/types';

import { ILowTensionProposal } from '@contexts/low-tension/types';
import { calculateProposalManagementFeeMonthly } from '@contexts/calculator/utils';
import { transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';

export const parseProductOneSimulatorResultToProposalPayload = (
  simulatorResult: IProductOneSimulation,
): Record<string, any> => {
  return transformObjectKeysToSnakeCase(simulatorResult);
};

export const parseProductOneSimulationPayload = (simulationData: IProductOneSimulatorInputs): Record<string, any> => {
  const simulationDataWithManualPricesAndDiscounts: IProductOneSimulatorInputs = {
    ...simulationData,
    customFee: getParsedCustomFee(simulationData),
    manualDiscountInputs: simulationData.hasManualDiscount
      ? parseFinancialProjectionPayload(simulationData.manualDiscountInputs, true)
      : undefined,
    manualAclPrice: simulationData.hasManualPrice
      ? parseFinancialProjectionPayload(simulationData.manualAclPrice)
      : undefined,
    calculationType: 'month_to_month',
    totalMigrationCost: simulationData.totalMigrationCost ?? 0,
  };
  return transformObjectKeysToSnakeCase(simulationDataWithManualPricesAndDiscounts);
};

export const parseProductOneSimulatorResult = (createSimulationResponse: AxiosResponse<IResponseBase<any>>) => {
  const simulationInCamelCase = transformObjectKeysToCamelCase(
    createSimulationResponse?.data?.data,
  ) as IProductOneSimulation;
  const proposalPayload = {
    lead: simulationInCamelCase.inputs.lead,
    author: simulationInCamelCase.inputs.author,
    seller: simulationInCamelCase.inputs.seller,
    inputs: simulationInCamelCase.inputs,
    calculatorMetadata: simulationInCamelCase,
  };

  return transformObjectKeysToSnakeCase(proposalPayload);
};

export const parseProductOneProposal = (proposal: Record<string, any>): IProductOneProposal => {
  return transformObjectKeysToCamelCase(proposal) as IProductOneProposal;
};

export const parseProductOneProposals = (proposals: Record<string, any>): IProductOneProposal => {
  return transformObjectKeysToCamelCase(proposals) as IProductOneProposal;
};

const parseProductOneSimulationPremises = (
  managementPrice?: number,
  totalMigrationCost?: number,
  migrationDate?: string,
  targetYear?: number,
  averageConsumption?: number,
  productType?: string,
): Array<IGeneralTitleAndValuePair> => {
  const premises: Array<IGeneralTitleAndValuePair> = [
    {
      title: 'Consumo Médio',
      value: (averageConsumption && numeral(averageConsumption).format('0.000').concat(' MWm')) ?? '-',
    },
    {
      title: 'Duração',
      value: `${targetYear} ano(s)`,
    },
    {
      title: 'Data de Migração',
      value: migrationDate,
    },
    {
      title: 'Fonte de Energia',
      value: 'Incentivada 50%',
    },
    {
      title: 'Investimento',
      value: productType === 'ACL_WHOLESALER' ? 'SMF e Adesão à CCEE' : 'SMF',
    },
  ];

  if (managementPrice && managementPrice > 0) {
    if (managementPrice) {
      premises.push({
        title: 'Custo de Gestão / mês',
        value: (managementPrice && numeral(managementPrice).format('$ 0,0.00')) ?? '-',
      });
    }
  }

  if (totalMigrationCost && totalMigrationCost > 0) {
    premises.push({
      title: 'Custo de Migração (à vista)',
      value: (totalMigrationCost && numeral(totalMigrationCost).format('$ 0,0.00')) ?? '-',
    });
  }

  return premises;
};

export const parseProductOneSimulationResults = (
  proposal: IProductOneProposal | ILowTensionProposal,
): Array<IProductOneSimulationExtendedResult | undefined> | undefined => {
  const parseMarketsFinancialDataComparison = (
    simulationResultYearsList: Array<string>,
    currentSpentByYear: Record<string, number>,
    savingByYearInPercentage: Record<string, number>,
    targetSpentByYear: Record<string, number>,
    guaranteedDiscountSpentByYear?: Record<string, number>,
  ) => {
    const targetSpentByYearWrapper =
      proposal.calculatorMetadata.aclType === 'FIXED_PRICE'
        ? targetSpentByYear
        : (guaranteedDiscountSpentByYear as Record<string, number>);

    const targetSpentByYearList = simulationResultYearsList.map((year) => targetSpentByYearWrapper[year]);
    const currentSpentByYearList = simulationResultYearsList.map((year) => currentSpentByYear[year]);
    const savingByYearPercentageList = simulationResultYearsList.map((year) =>
      Math.round(savingByYearInPercentage[year] * 100),
    );

    const targetSpent = targetSpentByYearList.reduce((accumulated, value) => accumulated + value, 0);
    const currentSpent = currentSpentByYearList.reduce((accumulated, value) => accumulated + value, 0);

    return {
      currentSpent,
      currentSpentByYear,
      currentSpentByYearList,
      savingByYearPercentageList,
      targetSpent,
      targetSpentByYear: targetSpentByYearWrapper,
      targetSpentByYearList,
    };
  };

  if (proposal.calculatorMetadata) {
    const firstResult = proposal.calculatorMetadata.firstResult;
    const secondResult = proposal.calculatorMetadata.secondResult;
    const thirdResult = proposal.calculatorMetadata.thirdResult;

    // @ts-expect-error :: types should be fixed
    const parsedSimulationResult: Array<IProductOneSimulationExtendedResult | undefined> = [
      firstResult,
      secondResult,
      thirdResult,
    ].map((simulationResult) => {
      if (!simulationResult) return undefined;

      const simulationResultYearsList = Object.keys(simulationResult.totalSavingByYear);
      const {
        currentSpent,
        currentSpentByYear,
        currentSpentByYearList,
        savingByYearPercentageList,
        targetSpent,
        targetSpentByYear,
        targetSpentByYearList,
      } = parseMarketsFinancialDataComparison(
        simulationResultYearsList,
        simulationResult.totalCurrentSpentByYear,
        simulationResult.totalSavingByYearPercentage,
        simulationResult.totalTargetSpentByYear,
        simulationResult.totalGuaranteedDiscountSpentByYear,
      );

      const firstUnitReport = simulationResult.unitsReports[0];
      const monthlyManagementPrice = calculateProposalManagementFeeMonthly(simulationResult.unitsReports);
      const migrationDate = proposal.inputs?.units !== undefined ? proposal.inputs?.units[0].migrationDate : '-';

      const parsedPremises = parseProductOneSimulationPremises(
        monthlyManagementPrice,
        simulationResult.totalMigrationCost,
        migrationDate,
        simulationResult.targetYearGroup,
        simulationResult.totalAverageConsumption,
        proposal.inputs.productType,
      );

      const parsedAclPrices = parseEnergyPrices(firstUnitReport.rawPriceList, simulationResult.targetYearGroup);

      const parsedUnits: Array<IProductOneSimulationUnitExtendedResult> = simulationResult.unitsReports.map((unit) => {
        const unitInputs = proposal.inputs?.units?.find((auxiliaryUnit) => auxiliaryUnit.nickname === unit.nickname);
        const parsedUnitAclPrices = parseEnergyPrices(unit.rawPriceList, simulationResult.targetYearGroup);
        const parsedUnitPremises = parseProductOneSimulationPremises(
          unitInputs?.managementPrice,
          undefined,
          unitInputs?.migrationDate,
          simulationResult.targetYearGroup,
          unit.averageConsumption,
        );

        const {
          currentSpent,
          currentSpentByYear,
          currentSpentByYearList,
          savingByYearPercentageList,
          targetSpent,
          targetSpentByYear,
          targetSpentByYearList,
        } = parseMarketsFinancialDataComparison(
          simulationResultYearsList,
          unit.currentSpentByYear,
          unit.savingByYearInPercentage,
          unit.targetSpentByYear,
          simulationResult.totalGuaranteedDiscountSpentByYear,
        );

        return {
          ...unit,
          aclPrices: parsedUnitAclPrices,
          totalSavingByMonth: unit?.savingByMonth,
          leadName: proposal.lead.name,
          currentSpent,
          currentSpentByYear: currentSpentByYear,
          currentSpentByYearList,
          initialExpenses: unit.initialExpenses,
          premises: parsedUnitPremises,
          proposalValidity: proposal.inputs.proposalValidity,
          saving: unit.totalSaving,
          savingByYear: unit.savingByYear,
          savingByYearPercentageList,
          savingPercentage: unit.totalSavingInPercentage,
          simulationResultYearsList,
          targetSpent,
          targetSpentByYear: targetSpentByYear,
          targetSpentByYearList,
        };
      });

      return {
        ...simulationResult,
        aclPrices: parsedAclPrices,
        totalSavingByMonth: simulationResult?.totalSavingByMonth,
        leadName: proposal.lead.name,
        currentSpent,
        currentSpentByYear,
        currentSpentByYearList,
        duration: simulationResult.targetYearGroup,
        initialExpenses: simulationResult.totalInitialExpenses,
        proposalValidity: proposal.inputs.proposalValidity,
        premises: parsedPremises,
        saving: simulationResult.totalSaving,
        savingByYear: simulationResult.totalSavingByYear,
        savingByYearPercentage: simulationResult.totalSavingByYearPercentage,
        savingByYearPercentageList,
        savingPercentage: simulationResult.totalSavingPercentage,
        simulationResultYearsList,
        targetSpent,
        targetSpentByYear,
        targetSpentByYearList,
        unitsReports: parsedUnits,
      };
    });

    return parsedSimulationResult;
  }
};
