import { twMerge } from 'tailwind-merge';

import { ILowTensionProposal } from '@contexts/low-tension/types';
import { IProposalPreviewBodyBase } from '@contexts/types';
import { LOW_TENSION_PATH } from '@routers/constants';
import useLowTension from '@hooks/use-low-tension';

import ProposalPreviewDescriptionAndUsers from '@components/atoms/general/lead-proposals-hub/proposal-preview-description-and-users';
import LeadProposalsHubBodyWrapper from '@components/molecules/simulations-list/lead-proposals-hub-body-wrapper';

import LowTensionProposalKeyMetricsPanel from './helper';

interface ILowTensionProposalPreviewBody extends IProposalPreviewBodyBase {
  leadProposal: ILowTensionProposal;
}

const LowTensionProposalPreviewBody = ({
  leadProposal,
  isMainContent,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: ILowTensionProposalPreviewBody): JSX.Element => {
  const { updateSellerOfLowTensionProposal } = useLowTension();
  const dinamicClass = !isMainContent && 'mx-3';

  const isProposalComplete = leadProposal?.calculatorMetadata !== undefined;

  return (
    <LeadProposalsHubBodyWrapper
      isProposalComplete={isProposalComplete}
      simulationPreviewFooterProps={{
        simulatorType: 'LOW_TENSION',
        simulatorPath: LOW_TENSION_PATH,
        simulationId: leadProposal?.id,
        leadName: leadProposal?.id,
        inputsValuesToCopy: leadProposal?.inputs,
        leadId: leadId,
        selectedProposalId: selectedProposalId,
        setSelectedProposalId: setSelectedProposalId,
        onClickDeleteProposal: onClickDeleteProposal,
        feedbackNotificationsContent: feedbackNotificationsContent,
      }}
    >
      <>
        <ProposalPreviewDescriptionAndUsers
          proposalId={leadProposal.id}
          description={leadProposal.inputs.description}
          author={leadProposal.author}
          seller={leadProposal.seller}
          leadId={leadId}
          updateProposalSeller={updateSellerOfLowTensionProposal}
        />
        <div className={twMerge('mt-8', dinamicClass)}>
          <LowTensionProposalKeyMetricsPanel calculatorMetadata={leadProposal.calculatorMetadata} />
        </div>
      </>
    </LeadProposalsHubBodyWrapper>
  );
};

export default LowTensionProposalPreviewBody;
