import React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/outline';

interface IEconomyCard {
  CardTitle: React.ReactNode;
  valueSaving: string;
  valueSavingPercentage: string;
}

const EconomyCard = ({ CardTitle, valueSaving, valueSavingPercentage }: IEconomyCard) => (
  <div className="p-7 flex flex-col border-black border-solid border rounded-lg">
    <div className="flex gap-3">
      <div className="w-6">
        <CurrencyDollarIcon />
      </div>
      {CardTitle}
    </div>
    <div className="flex flex-wrap gap-2 mb-6 w-full">
      <h1 className="ml-8 text-2xl sm:text-4xl font-black">{valueSaving}</h1>
      <h2 className="text-xl sm:text-2xl text-primary-60">{`(${valueSavingPercentage})`}</h2>
    </div>
  </div>
);

export default EconomyCard;
