import numeral from 'numeral';
import { clone } from 'lodash';

import {
  IFeasibilityAnalysisSimulationGeneralReport,
  IFeasibilityAnalysisSimulationUnitReport,
} from '@contexts/feasibility-analysis/types';

const parsePremisesTable = (
  targetYear: number,
  generalReport?: IFeasibilityAnalysisSimulationGeneralReport,
  unitReport?: IFeasibilityAnalysisSimulationUnitReport,
  generalAnalysis?: boolean,
): Record<string, any>[] => {
  const managementPrice = generalAnalysis
    ? generalReport?.tariffFlagScenarios.default.totalManagementPrice
    : unitReport?.managementPrice;

  const result = [
    {
      title: 'Gestão da Clarke',
      value: managementPrice ? numeral(managementPrice).format('$ 0,0.00') : '-',
    },
    {
      title: 'Duração',
      value: `${targetYear} ano(s)`,
    },
    {
      title: 'Fonte',
      value: 'Incentivada 50%',
    },
    {
      title: 'Investimento',
      value: 'CCEE e SMF',
    },
  ];

  const finalResult = clone(result);
  if (!generalAnalysis) {
    const unitData = [
      {
        title: 'Data de Migração',
        value: unitReport?.migrationDate ?? '-',
      },
      {
        title: 'Encargos ACL',
        value: unitReport?.premises.general.securityAndReservationCharges
          ? `${numeral(unitReport?.premises.general.securityAndReservationCharges).format('$ 0,0.00')}/MWh`
          : '-',
      },
    ];
    finalResult.splice(1, 0, ...unitData);
  }

  return finalResult;
};

export default parsePremisesTable;
