import { get } from 'lodash';
import { HeroIcon, NumericInput } from '@clarke-energia/foton';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';

export interface TimeFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  formatProps: NumberFormatProps;
  options?: RegisterOptions;
  className?: string;
  error?: string;
  tooltipContent?: string;
  disabled?: boolean;
  step?: number;
}
export function TimeFormField<T extends FieldValues>({
  field,
  id,
  label,
  formatProps,
  options,
  error,
  tooltipContent,
  disabled = false,
  step = 1,
}: TimeFormFieldProps<T>) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = error ? error : (get(errors, field)?.message as string | undefined);

  const increaseTime = (currentTime: number) => {
    let hours = currentTime ? Math.floor(currentTime / 100) : 6;
    let minutes = currentTime ? currentTime % 100 : 0;

    minutes += step;
    if (minutes >= 60) {
      hours++;
      minutes -= 60;
    }
    if (hours >= 24) {
      hours = 0;
    }
    const incresedTime = `${String(hours).padStart(2, '0')}${String(minutes).padStart(2, '0')}`;

    //@ts-expect-error -> react hook form typing convention
    setValue(field, incresedTime);
  };
  const decreaseTime = (currentTime: number) => {
    let hours = currentTime ? Math.floor(currentTime / 100) : 6;
    let minutes = currentTime ? currentTime % 100 : 0;

    minutes -= step;
    if (minutes < 0) {
      hours--;
      minutes += 60;
    }
    if (hours < 0) {
      hours = 23;
    }
    const decresedTime = `${String(hours).padStart(2, '0')}${String(minutes).padStart(2, '0')}`;

    //@ts-expect-error -> react hook form typing convention
    setValue(field, decresedTime);
  };

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="mt-3 relative">
          <NumericInput
            id={id}
            name={name}
            label={label}
            value={value}
            step={step}
            placeholder={formatProps.placeholder}
            formatProps={formatProps}
            required={(options?.required as boolean) || false}
            error={fieldError}
            onBlur={onBlur}
            onChange={{
              valueArgType: formatProps.isNumericString ? 'string' : 'number',
              callback: (value) => (options?.onChange ? options?.onChange(value) : onChange(value)),
            }}
            disabled={disabled}
            tooltipContent={tooltipContent}
          />
          <div className="h-[28px] absolute flex flex-col top-[28px] right-1 justify-between">
            <HeroIcon
              icon="ChevronUpIcon"
              onClick={() => increaseTime(value)}
              className="h-full cursor-pointer rounded-xl hover:bg-neutral-10"
            />
            <HeroIcon
              icon="ChevronDownIcon"
              onClick={() => decreaseTime(value)}
              className="h-full cursor-pointer rounded-xl hover:bg-neutral-10"
            />
          </div>
        </div>
      )}
    />
  );
}
