import numeral from 'numeral';
import { ISimplifiedProposalEconomyChartWithoutSubtitle } from '..';

const buildChartWithLabelsData = ({
  savingValues,
  targetValues,
  currentValues,
  labels,
  whiteLabelSupplier,
}: ISimplifiedProposalEconomyChartWithoutSubtitle) => {
  return {
    labels: labels,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          grace: 80,
          beginAtZero: true,
          position: 'bottom',
          grid: {
            display: false,
          },
          font: {
            size: 10,
            color: 'neutral-50',
          },
        },
        'livre-y-axis': {
          display: true,
          grace: 80,
          beginAtZero: true,
          position: 'left',
          grid: {
            display: false,
          },
          font: {
            size: 8,
            color: 'neutral-50',
          },
          ticks: {
            min: 0,
            callback: function (value: any) {
              return value >= 1000000 ? numeral(value).format('$0.0a') : numeral(value).format('$0,0'); // Customize the tick labels
            },
          },
        },
        'economia-y-axis': {
          display: false,
          grace: 80,
          beginAtZero: true,
          type: 'linear',
          position: 'right',
          ticks: {
            callback(value: any, _index: any, _ticks: any) {
              return `${value} %`;
            },
          },
        },
      },
      plugins: {
        datalabels: {
          padding: 5,
          borderRadius: 30,
          backgroundColor: 'black',
          color: '#FAFAFA',
          font: {
            size: '10px',
          },
          borderWidth: 1,
          borderColor: 'black',
          formatter(value: any) {
            return `${value}%`;
          },
        },
        legend: {
          display: false,
        },
      },
    },
    datasets: [
      {
        yAxisID: 'economia-y-axis',
        type: 'line',
        label: 'Economia (%)',
        borderColor: '#000',
        borderWidth: 2,
        fill: false,
        data: savingValues,
      },
      {
        type: 'bar',
        label: 'Mercado Cativo',
        backgroundColor: whiteLabelSupplier?.colors?.brighter ?? '#C5C5C5',
        data: currentValues,
        borderColor: 'white',
        borderRadius: 10,
        borderWidth: 2,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        yAxisID: 'livre-y-axis',
        type: 'bar',
        label: 'Mercado Livre',
        backgroundColor: whiteLabelSupplier?.colors?.main ?? '#00BF6A',
        borderRadius: 10,
        data: targetValues,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  };
};

export default buildChartWithLabelsData;
