import { twMerge } from 'tailwind-merge';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import useWhiteLabel from '@hooks/use-white-label';
import BarChart from '@components/atoms/general/bar-chart';

import buildChartWithLabelsData from './helper';
import { ISimplifiedProposalEconomyChartData } from '..';

export type ISubtitle = {
  color: string;
  description: string;
};

type ISubtitleData = {
  subtitleData: Array<ISubtitle>;
};

type ISubtitleDescription = {
  subtitleItemDescription: ISubtitle;
  isWhiteLabel?: boolean;
};

const Subtitle = ({ subtitleItemDescription, isWhiteLabel }: ISubtitleDescription): JSX.Element => {
  const colorBoxClassFixed = 'h-[0.625rem] w-6 rounded-full';
  const whiteLabelSupplierColors: Record<string, string> = {
    'primary-60': 'white-label-main',
    'neutral-30': 'white-label-brighter',
    black: 'white-label-darker',
  };
  const colorBoxClassDynamic = isWhiteLabel
    ? `bg-${whiteLabelSupplierColors[subtitleItemDescription.color]}`
    : `bg-${subtitleItemDescription.color}`;
  return (
    <div className="flex flex-row gap-4 items-center">
      <div className={twMerge(colorBoxClassFixed, colorBoxClassDynamic)} />
      <h1 className="text-caption-medium text-neutral-100">{subtitleItemDescription.description}</h1>
    </div>
  );
};

const SubtitleList = ({ subtitleData }: ISubtitleData): JSX.Element => {
  const { isWhiteLabelProposal } = useWhiteLabel();
  return (
    <div className="w-full flex flex-row pt-4 justify-center gap-6">
      {subtitleData.map((subtitleItemDescription) => (
        <Subtitle
          key={`description-${subtitleItemDescription.description}`}
          subtitleItemDescription={subtitleItemDescription}
          isWhiteLabel={isWhiteLabelProposal}
        />
      ))}
    </div>
  );
};

const SimplifiedProposalEconomyChart = ({
  labels,
  currentValues,
  targetValues,
  savingValues,
  subtitleData,
}: ISimplifiedProposalEconomyChartData): JSX.Element => {
  if (!targetValues || !currentValues || !savingValues) return <></>;
  const { whiteLabelSupplier } = useWhiteLabel();
  const chartData = buildChartWithLabelsData({
    currentValues,
    targetValues,
    savingValues,
    labels,
    whiteLabelSupplier,
  });

  return (
    <div className="w-full h-full px-[0.875rem] pt-5">
      <div className="h-[13.125rem]">
        <BarChart
          plugins={[ChartDataLabels]}
          options={chartData.options}
          datasets={chartData.datasets}
          labels={chartData.labels}
        />
      </div>
      <SubtitleList subtitleData={subtitleData} />
    </div>
  );
};

export default SimplifiedProposalEconomyChart;
