export const SimulatorFormTooltipsContentIndexer = {
  aclType:
    'Escolha entre Preço Fixo e Desconto Garantido. \
     A escolha modificará de forma nítida o resultado da simulação. \
     Por enquanto, somente a opção Preço Fixo está disponível. ',
  leadName:
    'O grupo ao qual a proposta será vinculada. Caso não exista, \
    crie um novo grupo clicando no botão ao lado.',
  customFee:
    'A margem inputada será aplicada aos preços \
    de energia utilizados na simulação.',
  proposalValidity:
    'A validade da proposta é calculada automaticamente. \
    Somente vendedores Clarke são capazes de alterar o valor calculado. \
    Parceiros comerciais não tem permissão para alterar o valor.',
  financialProjection: {
    hasFinancialProjection:
      'Ao selecionar essa opção, os preços usados na  \
       simulação serão os inseridos abaixo. Caso escolha \
       "não", os preços utilizados serão definidos automaticamente \
       pelo sistema. Certifique-se de manter essa opção selecionada \
       como "sim" durante todo o preenchimento, para que o sistema os \
       utilize no cálculo, caso assim deseje.',
    startYear:
      'A partir do ano inicial, serão listados os anos de \
       acordo com os grupos de anos escolhidos. Lembre-se de \
       inserir um ano que contemple a data de migração de todas as unidades.',
  },
  state:
    'Escolha o estado da federação onde o cliente está localizado. \
    Com base nisso, será escolhida uma projeção financeira específica para sua região.',
  targetYears:
    'Os grupos de anos serão usados para simular diferentes durações \
     de contrato e seus resultados. Selecione de 1 a 3.',
  targetYear: 'O grupo de anos será usado para definir a duração de contrato e seus resultados.',

  unit: {
    companyType: 'O tipo de empresa influencia nos picos de consumo ao longo do dia, impactando na economia final.',
    consumption:
      'Não é necessário preencher todos os meses. \
       Caso pelo menos 1 ou mais meses sejam preenchidos, o sistema irá \
       preencher o restante com a média dos meses preenchidos.',
    managementPrice:
      'O valor mensal de gestão cobrado pela Clarke.\
      Ao selecionar Calcular Automaticamente, o custo de gestão será calculado \
      com base na fórmula estebelecida pelo time de vendas.',
    totalMigrationCost:
      'O valor informado será somado ao custo final. Deixe em R$ 0,00 caso não haja custo de migração.',
    energyDistributionCompany:
      'Caso a distribuidora selecionada não possuia suas taxas \
       armazenadas em nosso banco e dados, de acordo com a tarifa selecionada,\
       a simulação poderá conter erros. Caso isso aconteça, entre em contato \
       com o time de Gestão de Clientes para se certificar de que a combinação \
       distribuidora x tarifa está registrada na alimentadora de tarifas.',
    icmsType: 'A classificação de empresa irá influenciar no tipo de ICMS utilizado na simulação.',
    initialExpenses:
      'O investimento inicial é definido automaticamente pelo sistema, \
       mas será sobreposto pelo valor inputado nesse campo. \
       Caso opte pelo valor padrão, deixe em  branco.',
    injectedEnergyOffPeak: 'test',
    injectedEnergyPeak: 'test',
    lowTensionType:
      'Caso seja BT Optante, o investimento inicial da unidade será R$ 10 mil. \
      Caso seja BT Padrão, será R$ 100 mil.',
    migrationDate:
      'Caso esteja utilizando valores manuais de desconto ou preço,\
       certifique-se de que o ano de migração dessa unidade seja igual \
       ou maior que o ano inicial das projeções informadas.\
       Caso contrário, apenas informe uma data válida a partir do dia atual.',
    powerGeneratorCost: 'Insira o custo do gerador de energia, caso haja.',
    tariff:
      'Insira o conjunto tarifário da unidade. Caso a tarifa seja do tipo Azul, \
       será necessário informar os valores de demanda (logo abaixo) em ponta.',
  },
};
