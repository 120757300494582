import {
  LeadProposalsHubHistoryAccordion,
  LeadProposalsHubHistory,
} from '@components/atoms/general/lead-proposals-hub/history';
import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';
import { ILeadProposalsHubHistoryBase } from '@contexts/types';
import FeasibilityAnalysisProposalPreviewBody from '../body';

export interface IFeasibilityAnalysisLeadProposalsHub extends ILeadProposalsHubHistoryBase {
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
  proposalsList: Array<IFeasibilityAnalysisProposal>;
}

const FeasibilityAnalysisLeadProposalsHubHistory = ({
  leadId,
  proposalsList,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IFeasibilityAnalysisLeadProposalsHub): JSX.Element => {
  return (
    <LeadProposalsHubHistory>
      <>
        {proposalsList?.map((proposal) => (
          <LeadProposalsHubHistoryAccordion
            key={`lead-proposals-hub-historic-feasibility-analysis-${proposal?.id}`}
            id={proposal?.id}
            sellerName={proposal.seller?.name}
            createdAt={proposal?.createdAt}
          >
            <FeasibilityAnalysisProposalPreviewBody
              leadProposal={proposal}
              leadId={leadId}
              selectedProposalId={selectedProposalId}
              setSelectedProposalId={setSelectedProposalId}
              onClickDeleteProposal={onClickDeleteProposal}
              feedbackNotificationsContent={feedbackNotificationsContent}
            />
          </LeadProposalsHubHistoryAccordion>
        ))}
      </>
    </LeadProposalsHubHistory>
  );
};
export default FeasibilityAnalysisLeadProposalsHubHistory;
