import styled from 'styled-components';

export const BoldTitle = styled.h2.attrs({
  className: 'text-5xl font-black inline',
})``;

export const NormalText = styled.div.attrs({
  className: 'text-xs',
})``;

export const LargeText = styled.div.attrs({
  className: 'text-xl',
})``;

export const XLargeText = styled.div.attrs({
  className: 'text-3xl self-end',
})``;

export const XXLargeText = styled.div.attrs({
  className: 'text-7xl font-black',
})``;

export const LargeBoldText = styled.div.attrs({
  className: 'text-xl font-black',
})``;

export const GridBody = styled.div.attrs({
  className: 'w-full grid grid-cols-8 justify-between h-full',
})``;

export const GridColumnLower = styled.div.attrs({
  className: 'w-full h-full flex flex-col p-5 col-span-3 gap-6 justify-start align-start',
})``;

export const GridColumnBigger = styled.div.attrs({
  className: 'w-full h-full flex flex-col p-5 col-span-5 align-middle',
})``;

export const GridContainer = styled.div.attrs({
  className: 'w-full grid grid-cols-1 justify-between h-full',
})``;

export const FlexItemsEnd = styled.div.attrs({
  className: 'h-full col-span-full flex flex-col justify-end',
})``;

export const GreenHeaderLine = styled.div.attrs({
  className: 'w-24 h-5 bg-primary-60',
})``;

export const EconomyBox = styled.div.attrs({
  className: 'w-full flex mb-20 border-t-1 border-solid border-black py-2',
})``;

export const EconomyBoxColumn = styled.div.attrs({
  className: 'w-full flex flex-col gap-2',
})``;

export const EconomyResultBox = styled.div.attrs({
  className: 'w-full flex justify-between bg-primary-60 border-t-1 border-solid border-black pb-6 pt-2 mt-5',
})``;
