import numeral from 'numeral';

import { ISampleMonthSimulation } from '@contexts/types';
import {
  IFeasibilityAnalysisSimulationTariffFlagScenario,
  IFeasibilityAnalysisSimulationUnitReport,
} from '@contexts/feasibility-analysis/types';

export interface IFeasibilityAnalysisSimulationAcrTable {
  data: Array<Record<string, string | undefined>>;
  monthLabel: string;
  year: string | number;
}

const parseCaptiveMarketTable = (
  report: IFeasibilityAnalysisSimulationUnitReport,
  yellowTariffFlagScenario: IFeasibilityAnalysisSimulationTariffFlagScenario,
): IFeasibilityAnalysisSimulationAcrTable => {
  const sampleMonthSimulation: ISampleMonthSimulation = report.tariffFlagScenarios.yellow.sampleMonthSimulation;
  const initialValues = {
    demandOffPeak: {
      quantity: report.acrContractedDemandOffPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.demandOffPeak,
    },
    demandPeak: {
      quantity: report.acrContractedDemandPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.demandPeak,
    },
    tusdPeak: {
      quantity: sampleMonthSimulation.currentMarket.consumptionPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak,
    },
    tusdOffPeak: {
      quantity: sampleMonthSimulation.currentMarket.consumptionOffPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak,
    },
    tePeak: {
      quantity: sampleMonthSimulation.currentMarket.consumptionPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak,
    },
    teOffPeak: {
      quantity: sampleMonthSimulation.currentMarket.consumptionOffPeak,
      tariff: sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak,
    },
    totalWithoutTaxes: sampleMonthSimulation.currentMarket.totalWithoutTaxes,
    totalWithTaxes: sampleMonthSimulation.currentMarket.totalWithTaxes,
  };

  const data = [
    {
      item: 'Demanda Contratada Fora Ponta (kW)',
      CaptiveMarketquantity: numeral(initialValues.demandOffPeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.demandOffPeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        numeral(initialValues.demandOffPeak?.quantity * initialValues.demandOffPeak?.tariff).format('$ 0,0.00') || '-',
    },
    {
      item: 'TUSD Ponta (MWh)',
      CaptiveMarketquantity: numeral(initialValues.tusdPeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.tusdPeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        numeral(initialValues.tusdPeak?.quantity * (initialValues.tusdPeak?.tariff ?? 0)).format('$ 0,0.00') || '-',
    },
    {
      item: 'TUSD Fora Ponta (MWh)',
      CaptiveMarketquantity: numeral(initialValues.tusdOffPeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.tusdOffPeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        numeral(initialValues.tusdOffPeak?.quantity * (initialValues.tusdOffPeak?.tariff ?? 0)).format('$ 0,0.00') ||
        '-',
    },
    {
      item: 'TE Ponta (MWh)',
      CaptiveMarketquantity: numeral(initialValues.tePeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.tePeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        numeral(initialValues.tePeak?.quantity * (initialValues.tePeak?.tariff ?? 0)).format('$ 0,0.00') || '-',
    },
    {
      item: 'TE Fora Ponta (MWh)',
      CaptiveMarketquantity: numeral(initialValues.teOffPeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.teOffPeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        numeral(initialValues.teOffPeak?.quantity * (initialValues.teOffPeak?.tariff ?? 0)).format('$ 0,0.00') || '-',
    },
    {
      item: 'Impostos',
      CaptiveMarketquantity: '-',
      CaptiveMarkettariff: '-',
      CaptiveMarkettotal:
        numeral(initialValues.totalWithTaxes - initialValues.totalWithoutTaxes).format('$ 0,0.00') || '-',
    },
    {
      CaptiveMarketquantity: 'Total (c/ impostos):',
      CaptiveMarkettotal: numeral(initialValues.totalWithTaxes).format('$ 0,0.00') || '-',
    },
  ];

  if (initialValues.demandPeak.quantity > 0) {
    data.splice(1, 0, {
      item: 'Demanda Contratada Ponta (kW)',
      CaptiveMarketquantity: numeral(initialValues.demandPeak?.quantity).format('0,0.00') || '-',
      CaptiveMarkettariff: numeral(initialValues.demandPeak?.tariff).format('$ 0,0.00') || '-',
      CaptiveMarkettotal:
        (initialValues.demandPeak.tariff &&
          numeral(initialValues.demandPeak?.quantity * initialValues.demandPeak.tariff).format('$ 0,0.00')) ||
        '-',
    });
  }

  return {
    data,
    monthLabel: yellowTariffFlagScenario.sampleMonthSimulation.report.monthLabel,
    year: yellowTariffFlagScenario.sampleMonthSimulation.report.year,
  };
};

export default parseCaptiveMarketTable;
