import { AxiosPromise } from 'axios';

import {
  ISimulationPayloadBase,
  ISimulationReportBase,
  ISimulationResultBase,
  ISampleMonthSimulation,
  ISimulationExtendedResultBase,
  IGeneralTitleAndValuePair,
  ISimulationBase,
  IProposalBase,
  IResponseBase,
  IUpdateSellerPayload,
} from '@contexts/types';

export interface ILowTensionContext {
  createLowTensionSimulation: (
    simulationData: ILowTensionSimulatorInputs,
  ) => AxiosPromise<IResponseBase<ILowTensionSimulation>>;
  createLowTensionProposal(simulatorResult: ILowTensionSimulation): AxiosPromise<IResponseBase<ILowTensionProposal>>;
  getLowTensionProposals: (
    leadId?: string,
    sellerId?: string,
  ) => AxiosPromise<IResponseBase<Array<ILowTensionProposal>>>;
  getLowTensionProposalById: (proposalId: string) => AxiosPromise<IResponseBase<ILowTensionProposal>>;
  deleteLowTensionProposalsHandler: (leadId: string, proposalsIds: Array<string>) => AxiosPromise<IResponseBase>;
  deleteLowTensionAllProposalsByLeadIdHandler: (leadId: string) => AxiosPromise<IResponseBase>;
  updateSellerOfLowTensionProposal: (updateSellerData: IUpdateSellerPayload) => AxiosPromise<IResponseBase>;
}

export type ILowTensionSimulatorInputs = ISimulationPayloadBase;
export type ILowTensionProposal = IProposalBase<ILowTensionSimulatorInputs, ILowTensionSimulation>;

export interface ILowTensionSimulation extends ISimulationBase<ILowTensionSimulatorInputs> {
  firstResult: ILowTensionSimulationResult;
  secondResult?: ILowTensionSimulationResult;
  thirdResult?: ILowTensionSimulationResult;
}

export interface ILowTensionSimulationResult extends ISimulationResultBase {
  targetYearGroup: number;
  unitsReports: Array<ILowTensionUnitReport>;
}

export interface ILowTensionSimulationExtendedResult extends ISimulationExtendedResultBase {
  unitsReports: Array<ILowTensionUnitReport>;
  premises: Array<IGeneralTitleAndValuePair>;
}

export interface ILowTensionUnitReport extends ISimulationReportBase {
  premises: ILowTensionUnitReportPremises;
  sampleMonthSimulation?: ISampleMonthSimulation;
}

interface ILowTensionUnitReportPremises {
  aclContractedDemandOffPeak: number;
  aclContractedDemandPeak?: number;
  acrContractedDemandOffPeak: number;
  acrContractedDemandPeak?: number;
  averageBillValue: number;
  consumptionOffPeak: Record<string, any>;
  consumptionPeak: Record<string, any>;
  distributedGenerationMonthlyCost: Record<string, any> | null;
  energyDistributionCompany: number;
  icmsType: string;
  injectedEnergyOffPeak?: Record<string, any>;
  injectedEnergyPeak?: Record<string, any>;
  migrationDate: string;
  nickname: string;
  powerGeneratorCost: number;
  solarPlantInOperation: boolean;
  targetVolumeGenerated: number;
  tariff: number;
}

export interface ILowTensionProposalGroupEconomyData {
  simulationResultYearsList: Array<string>;
  totalCurrentSpent: number;
  totalCurrentSpentByYearList: Array<number>;
  totalSavingByYearPercentageList: Array<number>;
  totalTargetSpent: number;
  totalTargetSpentByYearList: Array<number>;
}

export enum LowTensionTypeEnum {
  OPTING = 'BT Optante',
  STANDARD = 'BT Padrão',
}
