import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';

import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import BackCover from '@components/molecules/general/simulation-back-cover';

import AboutTeam from '@components/molecules/general/simulation/about-team';
import AboutService from '@components/molecules/general/simulation/about-service';
import ProcessFlow from '@components/molecules/general/simulation/process-flow';
import ServiceProposal from '@components/molecules/general/simulation/service-proposal';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import MarketConditions from '@components/molecules/product-one/simulation/market-conditions';
import CompetitiveAnalysis from '@components/molecules/general/simulation/competitor-comparison';
import { ICompetitiveAnalysisData } from '@components/molecules/general/simulation/competitor-comparison/parser';

export interface IProductOneSimulationConclusiveSection {
  groupManagementPrice: number;
  competitiveAnalysis?: ICompetitiveAnalysisData[] | undefined;
  simulationResult?: IProductOneSimulationExtendedResult;
}

const ProductOneSimulationConclusiveSection = ({
  groupManagementPrice,
  competitiveAnalysis,
  simulationResult,
}: IProductOneSimulationConclusiveSection) => {
  return (
    <>
      <SlideContainer>
        <GreenSectionSlide title="Por que eu preciso da Clarke para economizar?" />
      </SlideContainer>
      <SlideContainer>
        <MarketConditions />
      </SlideContainer>
      <SlideContainer>
        <AboutTeam />
      </SlideContainer>
      <SlideContainer>
        <AboutService />
      </SlideContainer>
      <SlideContainer>
        <ProcessFlow />
      </SlideContainer>
      {competitiveAnalysis && competitiveAnalysis.length >= 2 && (
        <SlideContainer>
          <CompetitiveAnalysis competitiveAnalysis={competitiveAnalysis} />
        </SlideContainer>
      )}
      <SlideContainer>
        <ServiceProposal
          proposalValidity={simulationResult?.proposalValidity}
          unitsNumber={simulationResult?.unitsReports?.length}
          totalMigrationCost={simulationResult?.totalMigrationCost}
          groupManagementPrice={groupManagementPrice}
        />
      </SlideContainer>
      <SlideContainer>
        <BackCover />
      </SlideContainer>
    </>
  );
};

export default ProductOneSimulationConclusiveSection;
