import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import { isArrayEmpty } from '@utils/common';
import { ISimulationPayloadBase } from '@contexts/types';

interface IValidateSimulatorFormFields {
  values: ISimulationPayloadBase;
  setError: UseFormSetError<ISimulationPayloadBase>;
  clearErrors: UseFormClearErrors<ISimulationPayloadBase>;
  onlyFields?: Array<keyof ISimulationPayloadBase | string>;
}

function validateSimulatorFormFields({ values, setError, clearErrors, onlyFields }: IValidateSimulatorFormFields) {
  let isFormValid = true;

  clearErrors();

  const shouldFieldBeValidated = (values: Array<keyof ISimulationPayloadBase | string>) => {
    if (!onlyFields) return true;
    else return onlyFields.find((ignoredField) => values.includes(ignoredField));
  };

  const validationHandler = (fieldName: keyof ISimulationPayloadBase, message: string, error?: boolean) => {
    if (error) {
      setError(fieldName as keyof ISimulationPayloadBase, {
        type: 'manual',
        message: message,
      });
      isFormValid = false;
    } else clearErrors([fieldName]);
  };

  if (shouldFieldBeValidated(['targetYear'])) {
    validationHandler(
      'targetYear',
      'Selecione um grupo de ano(s)',
      values.targetYear !== undefined && !isFinite(values.targetYear ?? undefined),
    );
  }

  if (shouldFieldBeValidated(['targetYears'])) {
    validationHandler(
      'targetYears',
      'Selecione entre um a três grupos de ano(s)',
      isArrayEmpty(values.targetYears) || (values.targetYears && values.targetYears.length > 3),
    );
  }

  values.units?.forEach((unit, unitNum) => {
    const consumptionPeakFieldName = `units.${unitNum}.consumptionPeak` as keyof ISimulationPayloadBase;
    if (shouldFieldBeValidated([consumptionPeakFieldName])) {
      const filteredConsumptionPeakList = Object.values(unit.consumptionPeak ?? {}).filter(
        (value) => value && isFinite(value),
      );

      validationHandler(
        consumptionPeakFieldName,
        'Você deve informar pelo menos um valor do histórico ponta',
        isArrayEmpty(filteredConsumptionPeakList),
      );
    }

    const consumptionOffPeakFieldName = `units.${unitNum}.consumptionOffPeak` as keyof ISimulationPayloadBase;
    if (shouldFieldBeValidated([consumptionOffPeakFieldName])) {
      const filteredConsumptionOffPeakList = Object.values(unit.consumptionOffPeak ?? {}).filter(
        (value) => value && isFinite(value),
      );

      validationHandler(
        consumptionOffPeakFieldName,
        'Você deve informar pelo menos um valor do histórico fora ponta',
        isArrayEmpty(filteredConsumptionOffPeakList),
      );
    }

    const consumptionFieldName = `units.${unitNum}.consumption` as keyof ISimulationPayloadBase;
    if (shouldFieldBeValidated([consumptionFieldName])) {
      const filteredConsumptionList = Object.values(unit.consumption ?? {}).filter((value) => value && isFinite(value));

      validationHandler(
        consumptionFieldName,
        'Você deve informar pelo menos um valor do histórico',
        isArrayEmpty(filteredConsumptionList),
      );
    }
  });

  return isFormValid;
}

export default validateSimulatorFormFields;
