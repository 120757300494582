import React from 'react';

import { ENERGY_DISTRIBUTION_COMPANIES } from '@utils/constants';
import { getCalculatorName } from '@utils/common';

import { COMPARISON_BILL_PATH } from '@routers/constants';
import {
  IComparisonBillSimulation,
  IComparisonBillSimulatorInputsWithMultipleUnits,
} from '@contexts/comparison-bill/types';
import useComparisonBill from '@hooks/use-comparison-bill';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import ComparisonBillSimulatorResultCore
  from '@components/organisms/comparison-bill/simulator/comparison-bill-simulator-result-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { SimulatorResultFooter } from '@components/molecules/general/simulation-calculator-result-footer';
import { useAuth } from '@src/auth-wrapper';

export interface IComparisonBillSimulationProps {
  simulatorResult?: IComparisonBillSimulation;
  simulatorInputs?: IComparisonBillSimulatorInputsWithMultipleUnits;
}

const ComparisonBillSimulatorResult = ({ simulatorResult, simulatorInputs }: IComparisonBillSimulationProps) => {
  const { user } = useAuth();
  const { createComparisonBillProposalHandler } = useComparisonBill();

  const [loading, setLoading] = React.useState(false);
  const [simulationId, setSimulationId] = React.useState<string>();
  const [leadName, setLeadName] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [energyDistributionCompany, setEnergyDistributionCompany] = React.useState<string>();

  const sendComparisonBill = () => {
    if (simulatorResult) {
      setLoading(true);
      createComparisonBillProposalHandler(simulatorResult)
        .then((response) => {
          setSimulationId(response.data.data.id);
          setLeadName(response.data.data.lead.name);
        })
        .catch(() => setErrorMessage('Houve um erro ao gerar simulação. Tente novamente.'))
        .finally(() => setLoading(false));
    }
  };

  React.useEffect(() => {
    const energyDistributionCompany =
      ENERGY_DISTRIBUTION_COMPANIES[simulatorResult?.unit.energyDistributionCompany as number] ?? undefined;
    setEnergyDistributionCompany(energyDistributionCompany);
  }, [simulatorResult]);

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_VIEWED, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  }, []);

  return (
    <SimulatorLayoutBase
      title="Resultado de Simulação"
      simulatorType="COMPARISON_BILL"
      simulatorFlow="SIMULATOR_RESULT"
    >
      <div className="col-span-full w-full">
        <ComparisonBillSimulatorResultCore
          {...{
            simulatorResult,
            energyDistributionCompany,
          }}
        />
        <SimulatorResultFooter
          simulatorType="COMPARISON_BILL"
          simulationId={simulationId}
          generateSimulation={sendComparisonBill}
          leadName={leadName}
          calculationInputs={simulatorInputs}
          simulatorPath={COMPARISON_BILL_PATH}
          {...{ loading, errorMessage, setErrorMessage }}
        />
      </div>
    </SimulatorLayoutBase>
  );
};
export default ComparisonBillSimulatorResult;
