import numeral from 'numeral';

import { IFeasibilityAnalysisSimulationTariffFlagScenarios } from '@contexts/feasibility-analysis/types';

export interface ITariffFlagEconomyTable {
  data: Record<string, string>[];
  monthLabel: string;
  year: string | number;
}

const parseTariffFlagEconomyTable = (
  tariffFlagScenarios: IFeasibilityAnalysisSimulationTariffFlagScenarios,
): ITariffFlagEconomyTable => {
  const initialValues = {
    greenFlag: {
      quantity: tariffFlagScenarios.green.sampleMonthSimulation.report.totalSavingWithTaxes,
      percentage: tariffFlagScenarios.green.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes,
    },
    yellowFlag: {
      quantity: tariffFlagScenarios.yellow.sampleMonthSimulation.report.totalSavingWithTaxes,
      percentage: tariffFlagScenarios.yellow.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes,
    },
    redP1Flag: {
      quantity: tariffFlagScenarios.redP1.sampleMonthSimulation.report.totalSavingWithTaxes,
      percentage: tariffFlagScenarios.redP1.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes,
    },
    redP2Flag: {
      quantity: tariffFlagScenarios.redP2.sampleMonthSimulation.report.totalSavingWithTaxes,
      percentage: tariffFlagScenarios.redP2.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes,
    },
  };

  const data = [
    {
      item: 'Economia Mensal',
      greenFlag: `${numeral(initialValues.greenFlag.quantity).format('$ 0,0.00')} (${numeral(
        initialValues.greenFlag.percentage,
      ).format('% 0,0')})`,
      yellowFlag: `${numeral(initialValues.yellowFlag.quantity).format('$ 0,0.00')} (${numeral(
        initialValues.yellowFlag.percentage,
      ).format('% 0,0')})`,
      redP1Flag: `${numeral(initialValues.redP1Flag.quantity).format('$ 0,0.00')} (${numeral(
        initialValues.redP1Flag.percentage,
      ).format('% 0,0')})`,
      redP2Flag: `${numeral(initialValues.redP2Flag.quantity).format('$ 0,0.00')} (${numeral(
        initialValues.redP2Flag.percentage,
      ).format('% 0,0')})`,
    },
  ];

  return {
    data,
    year: tariffFlagScenarios.yellow.sampleMonthSimulation.report.year,
    monthLabel: tariffFlagScenarios.yellow.sampleMonthSimulation.report.monthLabel,
  };
};

export default parseTariffFlagEconomyTable;
