import { useState, useEffect, Fragment } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import * as OutlineIcons from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import { HeroIconSolidOrOutline } from '../hero-icon';

interface IconOption {
  value: string;
  label: string;
}

export interface IconSelectFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions<T>;
  className?: string;
  index: number;
}

export function HeroIconSelectInput<T extends FieldValues>({
  field,
  label,
  options,
  className,
  placeholder,
}: IconSelectFormFieldProps<T>) {
  const [selectedIcon, setSelectedIcon] = useState<keyof typeof OutlineIcons>();
  const [isOpen, setIsOpen] = useState(false);

  const outlineIconOptions: IconOption[] = Object.entries(OutlineIcons).map(([key]) => ({
    value: key,
    label: key,
  }));

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<T>();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const initialValue = getValues(field) as keyof typeof OutlineIcons;
    if (initialValue) {
      setSelectedIcon(initialValue);
    }
  }, [field, getValues]);

  return (
    <Controller
      control={control}
      name={field}
      rules={{ ...options, deps: [field] }}
      render={({ field: { onChange, value } }) => (
        <div className="relative grid grid-cols-1 gap-2 min-h-fit h-full">
          <label className="flex text-sm font-medium text-black">
            {label}
            {options?.required && <p className="text-red-500">*</p>}
          </label>
          <div
            className={`min-w-fit w-full h-7 border  bg-white border-neutral-30 rounded-md shadow-sm p-2 cursor-pointer ${className}`}
            onClick={openModal}
          >
            {selectedIcon ? (
              <div className="flex items-center justify-center">
                <HeroIconSolidOrOutline id="heroicon.select" icon={selectedIcon} className="h-6 w-6 text-neutral-50" />
              </div>
            ) : (
              <span className="text-neutral-50 text-paragraph-medium">{placeholder || 'Selecione um ícone'}</span>
            )}
          </div>

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-neutral-50 bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Selecione um ícone
                      </Dialog.Title>
                      <div className="mt-4 grid grid-cols-5 gap-4">
                        {outlineIconOptions.map((icon) => (
                          <button
                            id={`button.${icon.value}`}
                            key={icon.value}
                            type="button"
                            className="flex items-center justify-center p-2 border border-neutral-30 rounded-md hover:bg-gray-100"
                            onClick={() => {
                              setSelectedIcon(icon.value as keyof typeof OutlineIcons);
                              onChange(icon.value);
                              closeModal();
                            }}
                          >
                            <HeroIconSolidOrOutline
                              id="heroicon.select"
                              icon={icon.value as keyof typeof OutlineIcons}
                              className="h-6 w-6 text-gray-500"
                            />
                          </button>
                        ))}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      )}
    />
  );
}
