import styled from 'styled-components';

export const TitleContainer = styled.div.attrs({
  className: 'w-full grid grid-cols-1 ml-6',
})``;

export const BoldTitle = styled.h2.attrs({
  className: 'text-5xl font-black inline',
})``;

export const Description = styled.h2.attrs({
  className: 'text-sm',
})``;

export const GridBody = styled.div.attrs({
  className: 'w-full grid grid-cols-8 justify-between h-full',
})``;

export const GridColumnLower = styled.div.attrs({
  className: 'w-full flex flex-col gap-6 h-full p-5 col-span-3 justify-between align-start',
})``;

export const GridColumnBigger = styled.div.attrs({
  className: 'w-full flex flex-col col-span-5 p-5',
})``;

export const BillBox = styled.div.attrs({
  className: "flex self-end bg-transparent w-full justify-end",
})``;

export const EnergyBill = styled.div.attrs({
  className: "flex w-full relative bottom-6 right-10 bg-transparent justify-end",
})``;

export const GridContainer = styled.div.attrs({
  className: 'w-full grid grid-cols-1 justify-between h-full',
})``;

export const GreenHeaderLine = styled.div.attrs({
  className: 'w-24 h-5 bg-primary-60',
})``;
