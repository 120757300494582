import React from 'react';

export const columnsFreeMarketEnergyBill = [
  {
    key: 'item',
    label: 'Item',
    render: (value: any): React.FC => value,
  },
  {
    key: 'quantity',
    label: 'Quantidade',
    render: (value: any): string => value,
  },
  {
    key: 'tariff',
    label: 'Tarifa',
    render: (value: any): string => value,
  },
  {
    key: 'total',
    label: 'Total',
    render: (value: any): string => value,
  },
];

export default columnsFreeMarketEnergyBill;
