import { NumericInput } from '@clarke-energia/foton';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';
import { get } from 'lodash';

export interface NumericFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  formatProps: Pick<
    NumberFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'format'
    | 'mask'
    | 'isNumericString'
  >;
  options?: RegisterOptions;
  className?: string;
  error?: string;
  tooltipContent?: string;
  disabled?: boolean;
}
export function NumericFormField<T extends FieldValues>({
  field,
  id,
  label,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    format,
    mask,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    isNumericString,
  },
  options,
  error,
  tooltipContent,
  disabled,
}: NumericFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = error ? error : (get(errors, field)?.message as string | undefined);

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="mt-3">
          <NumericInput
            id={id}
            name={name}
            label={label}
            value={value}
            placeholder={placeholder}
            formatProps={{
              thousandSeparator: thousandSeparator,
              decimalSeparator: decimalSeparator,
              decimalScale: decimalScale,
              fixedDecimalScale: fixedDecimalScale,
              allowNegative: allowNegative,
              prefix: prefix,
              suffix: suffix,
              format: format,
              mask: mask,
            }}
            required={(options?.required as boolean) || false}
            error={fieldError}
            onBlur={onBlur}
            onChange={{
              valueArgType: isNumericString ? 'string' : 'number',
              callback: (value) => (options?.onChange ? options?.onChange(value) : onChange(value)),
            }}
            tooltipContent={tooltipContent}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}
