import React from 'react';
import { twMerge } from 'tailwind-merge';
import { HeroIcon } from '@clarke-energia/foton';
import { Transition } from '@headlessui/react';

export enum DynamicTypeEnum {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}
export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  dynamicType?: DynamicTypeEnum;
  children?: React.ReactNode;
  label: string;
  descriptionElement?: React.ReactNode;
  initiallyOpen?: boolean;
  disabled?: boolean;
  customAttrs?: Record<string, string>;
}

const NewAccordion: React.FC<AccordionProps> = ({
  dynamicType = DynamicTypeEnum.submit,
  descriptionElement,
  label,
  initiallyOpen = false,
  customAttrs,
  disabled,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initiallyOpen);
  const iconBaseClass = 'cursor-pointer w-5 h-5 transition-transform ease-linear duration-300';
  const rotateClass = isOpen ? '-rotate-180' : '-rotate-0';
  const iconClass = `${iconBaseClass} ${rotateClass}`;
  const colorClass = disabled ? 'bg-neutral-10 text-neutral-60 border-neutral-20' : 'border-black';
  const staticClasses =
    'flex justify-between items-center border py-3 px-5 rounded hover:bg-neutral-10 focus:ring-2 focus:ring-offset-2 focus:ring-primary-60';
  const dynamicClasses = [colorClass].join(' ');

  return (
    <div className="flex flex-col" {...customAttrs}>
      <button type={dynamicType} onClick={() => setIsOpen(!isOpen)} className={twMerge(staticClasses, dynamicClasses)}>
        <div className="flex text-start items-center w-full">
          <p className="text-paragraph-small font-medium grow">{label}</p>
          <div className="flex items-center space-x-5 grow-0">
            {descriptionElement}
            <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
          </div>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="mt-4">{children}</div>
      </Transition>
    </div>
  );
};

export default NewAccordion;
