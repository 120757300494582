import React, { useState } from 'react';

import { IComparisonBillSimulation } from '@contexts/comparison-bill/types';

import { IComparisonBillSimulatorInputsWithMultipleUnits } from '@contexts/comparison-bill/types';

import ComparisonBillSimulatorResult from './comparison-bill-simulator-result';
import ComparisonBillSimulatorForm from './comparison-bill-simulator-form';

const ComparisonBillSimulator = () => {
  const [simulatorResult, setSimulatorResult] = useState<IComparisonBillSimulation>();
  const [simulatorInputs, setSimulatorInputs] = useState<IComparisonBillSimulatorInputsWithMultipleUnits>();

  return (
    <>
      {simulatorResult ? (
        <ComparisonBillSimulatorResult {...{ simulatorResult, simulatorInputs }} />
      ) : (
        <ComparisonBillSimulatorForm {...{ setSimulatorResult, setSimulatorInputs }} />
      )}
    </>
  );
};

export default ComparisonBillSimulator;
