import React from 'react';

import MarketplacePage from '@assets/images/marketplace-page.png';

const AboutMarketplace = () => (
  <div className="flex flex-col lg:flex-row w-full h-fit lg:h-screen">
    <div className="w-full lg:w-1/2 flex justify-center p-8 lg:p-10 items-center">
      <h1 className="text-center text-heading-small sm:text-heading-large lg:text-heading-2xlarge xl:text-display-small font-normal">
        Somos o <span className="font-bold">primeiro marketplace</span> de Mercado Livre de Energia do Brasil e com mais
        de <span className="font-bold">50 fornecedoras!</span>
      </h1>
    </div>
    <div className="w-full h-[400px] sm:h-[600px] md:h-[800px] lg:h-full lg:w-1/2 grid grid-cols-5 relative overflow-hidden">
      <div className="col-start-3 col-span-4 bg-primary-60"></div>
      <img
        className="absolute right-0 h-full w-full rounded-l-large pl-6 lg:pl-0 py-6 md:py-10 lg:py-10 desktop:py-6 object-right object-contain"
        src={MarketplacePage}
        alt="marketplace-page"
      />
    </div>
  </div>
);

export default AboutMarketplace;
