import React, { useEffect, useState } from 'react';
import { ButtonIcon } from '@clarke-energia/foton';

import { DescriptionWithNumber } from '@components/atoms/simplified-proposal/description-with-number';
import Carousel from '@components/molecules/carousel';

export interface IAnnualBreakdownColumn {
  year: string;
  totalCurrentSpentByYear: string;
  totalTargetSpentByYear: string;
  totalSavingByYear: string;
  rawPriceList: string;
}

export const AnnualBreakdownColumn = ({
  year,
  rawPriceList,
  totalCurrentSpentByYear,
  totalTargetSpentByYear,
  totalSavingByYear,
}: IAnnualBreakdownColumn) => {
  return (
    <div className="flex flex-col gap-5 w-fit max-w-full">
      <h3 className="text-heading-xsmall font-bold mb-4">{year}</h3>
      <div className="grid grid-cols-2 whitespace-nowrap gap-5 gap-x-7 items-start justify-around min-w-fit min-h-full">
        <DescriptionWithNumber
          textDescription={'Mercado Cativo'}
          value={totalCurrentSpentByYear}
          valueTextColor={'black'}
        />
        <DescriptionWithNumber
          textDescription={'Mercado Livre'}
          value={totalTargetSpentByYear}
          valueTextColor={'black'}
        />
        {rawPriceList && (
          <DescriptionWithNumber
            textDescription={'Preço de Fornecimento'}
            value={rawPriceList}
            valueTextColor={'black'}
          />
        )}
        <DescriptionWithNumber textDescription={'Economia'} value={totalSavingByYear} valueTextColor={'primary'} />
      </div>
    </div>
  );
};

export interface IAnnualBreakdownColumns {
  annualBreakdownDataList: Array<IAnnualBreakdownColumn>;
}
export const AnnualBreakdownColumns: React.FC<IAnnualBreakdownColumns> = ({
  annualBreakdownDataList,
}: IAnnualBreakdownColumns) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemsToSlide, setItemsToSlide] = useState(1);
  const [itemsWidth, setItemsWidth] = useState(100);
  const [paddingInline, setPaddingInline] = useState(10);

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setItemsWidth(380);
      setPaddingInline(10);
      setItemsToSlide(1);
    }
  }, [windowWidth]);

  const renderAnnualBreakdownColumn = (annualBreakdownData: IAnnualBreakdownColumn) => {
    return (
      
          <AnnualBreakdownColumn
            key={annualBreakdownData.year}
            year={annualBreakdownData.year}
            totalCurrentSpentByYear={annualBreakdownData.totalCurrentSpentByYear}
            totalTargetSpentByYear={annualBreakdownData.totalTargetSpentByYear}
            totalSavingByYear={annualBreakdownData.totalSavingByYear}
            rawPriceList={annualBreakdownData.rawPriceList}
          />
    );
  };

  const {
    nextItem,
    previousItem,
    component: CarouselComponent,
    renderDots,
  } = Carousel({
    itemsWidth: itemsWidth,
    paddingInline: paddingInline,
    dots: true,
    itemsToSlide: itemsToSlide,
    children: annualBreakdownDataList.map(renderAnnualBreakdownColumn),
  });


  return (
    <>
      {annualBreakdownDataList.length >= 2 ? (
         <div className="flex flex-col gap-y-5 relative">
         <div className="flex items-center w-full">
           <ButtonIcon icon="ChevronLeftIcon" kind="secondary" onClick={previousItem} className="border-none" />
           <div className="overflow-hidden">{CarouselComponent}</div>
           <ButtonIcon icon="ChevronRightIcon" kind="secondary" onClick={nextItem} className="border-none" />
         </div>
         {renderDots && <div className="flex justify-center mt-auto w-full">{renderDots()}</div>}
       </div>
      ) : (
        annualBreakdownDataList.map(renderAnnualBreakdownColumn)
      )}
    </>
  );
};
