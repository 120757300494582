import React from 'react';

import { SlideContainer } from '@components/molecules/general/simulation-styled-components';
import BackCover from '@components/molecules/general/simulation-back-cover';

const ComparisonBillSimulationConclusiveSection = () => {
  return (
    <>
      <SlideContainer>
        <BackCover />
      </SlideContainer>
    </>
  );
};

export default ComparisonBillSimulationConclusiveSection;
