import React, { useState } from 'react';

import { ILowTensionSimulatorInputs } from '@contexts/low-tension/types';
import { ILowTensionSimulation } from '@contexts/low-tension/types';

import LowTensionSimulatorForm from './low-tension-simulator-form';
import LowTensionSimulatorResult from './low-tension-simulator-result';

const LowTensionSimulator = (): JSX.Element => {
  const [simulatorResult, setSimulatorResult] = useState<ILowTensionSimulation>();
  const [simulatorInputs, setSimulatorInputs] = useState<ILowTensionSimulatorInputs>();

  return (
    <>
      {simulatorResult && simulatorInputs ? (
        <LowTensionSimulatorResult {...{ simulatorInputs, simulatorResult, setSimulatorResult }} />
      ) : (
        <LowTensionSimulatorForm {...{ setSimulatorResult, setSimulatorInputs }} />
      )}
    </>
  );
};

export default LowTensionSimulator;
