import React from 'react';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { LOW_TENSION_PATH } from '@routers/constants';

import { getCalculatorName } from '@utils/common';

import { ILowTensionSimulation, ILowTensionSimulatorInputs } from '@contexts/low-tension/types';
import useLowTension from '@hooks/use-low-tension';

import LowTensionSimulatorResultCore from '@components/organisms/low-tension/simulator/low-tension-simulator-result-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { SimulatorResultFooter } from '@components/molecules/general/simulation-calculator-result-footer';
import { useAuth } from '@src/auth-wrapper';

export interface ILowTensionSimulationProps {
  simulatorResult: ILowTensionSimulation;
  simulatorInputs: ILowTensionSimulatorInputs;
  setSimulatorResult?: React.Dispatch<React.SetStateAction<ILowTensionSimulation | undefined>>;
}

const LowTensionSimulatorResult = ({ simulatorResult, simulatorInputs }: ILowTensionSimulationProps): JSX.Element => {
  const { createLowTensionProposal } = useLowTension();
  const { user } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [simulationId, setSimulationId] = React.useState<string>();
  const [leadName, setLeadName] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const createProposal = async () => {
    try {
      setLoading(true);

      createLowTensionProposal({ ...simulatorResult, inputs: simulatorInputs })
        .then((response) => {
          setSimulationId(response.data.data.id);
          setLeadName(response.data.data.lead.name);
        })
        .catch(() => {
          setErrorMessage('Ocorreu um erro. Tente novamente.');
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setErrorMessage('Ocorreu um erro. Tente novamente.');
      setLoading(false);
    }
  };

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_VIEWED, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  }, []);

  return (
    <SimulatorLayoutBase title="Resultado de Simulação" simulatorType="LOW_TENSION" simulatorFlow="SIMULATOR_RESULT">
      <div className="col-span-full w-full">
        <LowTensionSimulatorResultCore
          {...{
            simulatorInputs,
            simulatorResult,
          }}
        />
        <SimulatorResultFooter
          simulatorType="LOW_TENSION"
          simulatorPath={LOW_TENSION_PATH}
          leadName={leadName}
          generateSimulation={createProposal}
          calculationInputs={simulatorInputs}
          {...{
            errorMessage,
            loading,
            simulationId,
            setErrorMessage,
          }}
        />
      </div>
    </SimulatorLayoutBase>
  );
};

export default LowTensionSimulatorResult;
