import { Table } from '@clarke-energia/foton';
import numeral from 'numeral';
import { twMerge } from 'tailwind-merge';

import tradersLogo from '@utils/traders';

import GridElements from '@components/atoms/general/grid-elements';
import { IEconomyChart } from '@components/molecules/general/economy-chart';
import { TableHeader } from '@components/molecules/general/table-with-superheader';

import ClarkeHorizontalLogo from '@assets/images/clarke-green-horizontal.png';

import {
  IProductTwoSimulationExtendedResult,
  IProductTwoSimulationUnitExtendedResult,
} from '@contexts/product-two/types';

import { IEconomySlide } from '.';
import EconomyChartWithLabels from '../../economy-chart-with-labels';
import SuperheaderContainer from '../../superheader-container';

interface ITraderDescription {
  retailTraderId: string;
  hasTraderDescription?: boolean;
}

const isOnPrintMode = window.location.search.includes('print');

const TraderDescription = ({ retailTraderId, hasTraderDescription }: ITraderDescription) => {
  const dynamicClass = !hasTraderDescription && 'invisible';

  return (
    <>
      <h1 className={twMerge('text-neutral-50 text-caption-medium font-normal mb-4', dynamicClass)}>Fornecedora:</h1>
      <img
        className="object-contain max-h-7 max-w-[5.313rem] md:max-h-9 md:max-w-[9.625rem]"
        src={tradersLogo[retailTraderId]}
      />
    </>
  );
};

export const ProposalValidity = ({ simulationResult }: IEconomySlide) => {
  return (
    <>
      {simulationResult.proposalValidity && (
        <h2 className="text-xs font-normal mt-6">
          Proposta firme com <strong>validade até: {simulationResult.proposalValidity}</strong>
        </h2>
      )}
    </>
  );
};
export const InvestmentCaption = () => {
  return (
    <>
      <h2 className="text-xs font-normal mt-6">
        ¹ O investimento inicial pode sofrer alteração após análise detalhada executada pela(s) distribuidora(s).
      </h2>
    </>
  );
};
interface PremisesAndEconomyChartSection extends IEconomySlide {
  PremisesTableColumns: Array<Record<string, any>>;
  PremisesTableHeaders: Array<TableHeader>;
  chartData: IEconomyChart | undefined;
}

const PremisesAndEconomyChartSection = ({
  simulationResult,
  PremisesTableHeaders,
  showSideData,
  chartData,
}: PremisesAndEconomyChartSection) => {
  const numberOfYearsToCreateColumns = Object.entries(chartData?.labels || []).length > 5;

  return (
    <div
      className={`flex flex-col w-full h-fit ${
        numberOfYearsToCreateColumns && isOnPrintMode ? 'justify-start' : 'justify-end'
      } gap-6 xl:flex-row xl:gap-0`}
    >
      {showSideData && (
        <div
          className={`overflow-x-auto w-full ${
            numberOfYearsToCreateColumns && isOnPrintMode ? 'xl:w-[23%]' : 'xl:w-2/6'
          } h-full  xl:pr-6`}
        >
          <Table
            key="premisesTableData"
            data={simulationResult.premises}
            superHeader={PremisesTableHeaders[0].label}
            tableColumns={[
              {
                accessor: 'title',
                fixedWidthInRem: 10,
              },
              {
                accessor: 'value',
                fixedWidthInRem: 10,
                renderAsElement: (entry) => <div className="flex justify-end">{entry.value}</div>,
              },
            ]}
          />
        </div>
      )}
      <div
        className={`w-full h-full overflow-x-auto  ${
          numberOfYearsToCreateColumns && isOnPrintMode ? 'xl:w-[88.33%]' : 'xl:w-4/6'
        }`}
      >
        <div className="min-w-full h-full w-fit xl:w-full">
          <SuperheaderContainer superHeader="Detalhamento da economia projetada ano a ano:">
            {chartData && <EconomyChartWithLabels {...chartData} />}
          </SuperheaderContainer>
        </div>
      </div>
    </div>
  );
};

const PricesAndEconomyTableSection = ({
  showSideData,
  simulationResult,
  proposalValidity,
  PricesTableHeaders,
  economyProjectionTable,
}: PricesAndEconomyTableSection) => {
  const numberOfYearsToCreateColumns = Object.entries(economyProjectionTable?.data[0] ?? []).length > 8;

  return (
    <div
      className={`flex flex-col-reverse ${
        numberOfYearsToCreateColumns && isOnPrintMode ? 'justify-start' : 'justify-end'
      } gap-6 xl:flex-row xl:gap-0`}
    >
      {showSideData && (
        <div
          className={`overflow-x-auto  ${
            numberOfYearsToCreateColumns && isOnPrintMode ? ' xl:w-[23%]' : 'w-full xl:w-2/6'
          } xl:pr-6`}
        >
          {simulationResult.aclPrices && (
            <SuperheaderContainer superHeader={PricesTableHeaders(proposalValidity!)[0]?.label || ''}>
              <div className="px-5">
                <GridElements key="pricesTableData" data={simulationResult.aclPrices} />
              </div>
            </SuperheaderContainer>
          )}
        </div>
      )}
      <div
        className={`flex flex-col w-full overflow-x-auto ${
          numberOfYearsToCreateColumns && isOnPrintMode ? 'xl:w-[88.33%] overflow-x-hidden mb-[-3%]' : 'xl:w-4/6'
        }`}
      >
        {economyProjectionTable?.data && simulationResult?.simulationResultYearsList && (
          <Table
            data={economyProjectionTable.data}
            tableColumns={economyProjectionTable.columnDefinitions}
            superHeader={economyProjectionTable.superheader}
            lastRow={{
              fontBold: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

interface PricesAndEconomyTableSection extends IEconomySlide {
  PricesTableColumns: Array<Record<string, any>>;
  PricesTableHeaders: (proposalValidit?: string) => Array<TableHeader>;
  economyProjectionTable: any;
  simulationResult: IEconomySlide['simulationResult'];
}

export const ProposalData = ({ simulationResult }: IEconomySlide) => {
  return (
    <div className="flex pt-5 justify-between">
      <div className="grid col-span-1">
        <h2 className="text-heading-medium font-black truncate ... min-[25rem]:text-display-medium mb-[16px] md:text-display-large lg:text-display-xlarge">
          {numeral((simulationResult as IProductTwoSimulationExtendedResult).totalSaving).format('$ 0,0.00')}
        </h2>
        <h2 className="text-sm font-normal min-[22.813rem]:text-base md:text-xl">
          Economia mensal líquida:
          {
            //TODO after migration remove this conditional and use only totalSavingByMonth
            numeral(
              simulationResult?.totalSavingByMonth
                ? simulationResult?.totalSavingByMonth
                : simulationResult?.averageMonthlySaving,
            ).format('$ 0,0.00')
          }
        </h2>
      </div>
      <h2 className="text-heading-medium text-primary-60 min-[25rem]:text-display-medium md:text-display-large lg:text-display-xlarge">
        {numeral(simulationResult.savingPercentage).format('% 0,0')}
      </h2>
    </div>
  );
};

export const ColumnMajorHeader = ({ simulationResult }: IEconomySlide) => {
  return (
    <div className="flex flex-row w-full mb-[-3%]">
      <div
        className={`invisible w-0 ${
          simulationResult.simulationResultYearsList?.length > 5 && isOnPrintMode ? 'xl:w-[28%]' : 'xl:w-2/6'
        } xl:visible`}
      >
        <img className="w-[13.813rem]" src={ClarkeHorizontalLogo} alt="clarke-logo" />
      </div>
      <div className="flex flex-col w-full xl:w-4/6">
        <div className="flex flex-col xl:flex-row justify-between pb-4 border-b-2 border-solid border-neutral-30 w-full">
          <h2 className="text-heading-xsmall font-black md:text-heading-small">
            Proposta de economia em {simulationResult.simulationResultYearsList?.length}
            {simulationResult.simulationResultYearsList?.length > 1 ? ' anos' : ' ano'}
          </h2>
          <h2 className="text-sm font-normal min-[22.812rem]:text-base md:text-xl">
            {(simulationResult as IProductTwoSimulationUnitExtendedResult).nickname ?? simulationResult.leadName}
          </h2>
        </div>
        <ProposalData simulationResult={simulationResult} />
      </div>
    </div>
  );
};

export interface IMainContentColumnMajor extends PremisesAndEconomyChartSection, PricesAndEconomyTableSection {}

export const MainContentColumnMajor = ({
  showSideData,
  simulationResult,
  proposalValidity,
  chartData,
  PremisesTableColumns,
  PremisesTableHeaders,
  PricesTableColumns,
  PricesTableHeaders,
  hasTraderDescription,
  economyProjectionTable,
}: IMainContentColumnMajor) => {
  const showSideDataClassName = showSideData ? 'justify-start' : 'justify-end';
  return (
    <div
      className={twMerge(
        'flex flex-col justify-between mb-4 mt-12 h-fit',
        hasTraderDescription ? 'md:mt-0' : 'md:mt-10',
      )}
    >
      {hasTraderDescription && (
        <div className="flex justify-end flex-col pb-1 mb-4">
          <TraderDescription
            hasTraderDescription={hasTraderDescription}
            retailTraderId={(simulationResult as IProductTwoSimulationExtendedResult).retailTraderId}
          />
        </div>
      )}
      <div className={twMerge('flex flex-col justify-end h-full gap-3 xl:gap-5', showSideDataClassName)}>
        <PremisesAndEconomyChartSection
          simulationResult={simulationResult}
          PremisesTableColumns={PremisesTableColumns}
          PremisesTableHeaders={PremisesTableHeaders}
          showSideData={showSideData}
          chartData={chartData}
        />
        <PricesAndEconomyTableSection
          showSideData={showSideData}
          simulationResult={simulationResult}
          proposalValidity={proposalValidity}
          PricesTableColumns={PricesTableColumns}
          PricesTableHeaders={PricesTableHeaders}
          economyProjectionTable={economyProjectionTable}
        />
      </div>
      <div className="flex flex-col xl:flex-row-reverse xl:justify-between">
        <InvestmentCaption />
        <ProposalValidity simulationResult={simulationResult} />
      </div>
    </div>
  );
};