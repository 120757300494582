import React from 'react';

import numeral from 'numeral';

interface IComparisonBillSimulationItem {
  label: string;
  description: string;
  value?: number;
}

const ComparisonBillSimulatorResultItem = ({ label, description, value }: IComparisonBillSimulationItem) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
        <p className="font-bold text-md text-green-700">{description}</p>
        <p className="mt-2">{numeral(value).format('$ 0,0')}</p>
      </dd>
    </div>
  );
};

export default ComparisonBillSimulatorResultItem;
