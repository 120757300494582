import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { useAuth } from '@src/auth-wrapper';

import { ISimulationPayloadBase } from '@contexts/types';
import { LowTensionTypeEnum } from '@contexts/low-tension/types';

import {
  COMPANY_TYPES,
  ENERGY_DISTRIBUTION_COMPANIES,
  LOW_TENSION_OPTING_INITIAL_EXPENSES,
  LOW_TENSION_STANDARD_INITIAL_EXPENSES,
  TARIFFS,
  DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE,
} from '@utils/constants';
import { isValidDate } from '@utils/datetime';

import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';

import { GenericFormField } from '../fields/generic-form-field';
import { SelectFormField } from '../fields/select-form-field';
import { NumericFormField } from '../fields/numeric-form-field';
import { calculateUnitMigrationDate } from '@components/molecules/form/simulator/default-values-getters';

export function SimulatorUnitGeneralSubsection({ unitNum, fieldsController }: ISimulatorFormUnitSection) {
  const { watch, setValue, trigger } = useFormContext();
  const { user } = useAuth();

  const handleChangeLowTensionType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == 'OPTING' || event.target.value == 'STANDARD') {
      const initialExpensesValue =
        event.target.value == 'OPTING' ? LOW_TENSION_OPTING_INITIAL_EXPENSES : LOW_TENSION_STANDARD_INITIAL_EXPENSES;
      setValue(`units.${unitNum}.initialExpenses`, initialExpensesValue);
    }
  };

  const isStateES =
    watch(`units.${unitNum}.energyDistributionCompany`) == '29' ||
    watch(`units.${unitNum}.energyDistributionCompany`) == '30';

  const customFee = watch('customFee');

  const handleChangeIsCachoeiroCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!customFee) {
        setValue('customFee.type', 'PERCENTAGE');
        setValue('customFee.auxiliarFields.uniqueMargin', DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-6 gap-x-7">
        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.nickname`}
          field={`units.${unitNum}.nickname`}
          label="Nome"
          placeholder="Unidade Urca"
          options={{ required: { value: true, message: 'O nome da unidade é obrigatório' } }}
          className="col-span-1 lg:col-span-2"
        />
        {fieldVerificator({ fieldName: 'tariff', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
          <SelectFormField<ISimulationPayloadBase>
            id={`units.${unitNum}.tariff`}
            field={`units.${unitNum}.tariff`}
            label="Tarifas"
            inputOptions={Object.entries(TARIFFS).map((tariffEntry) => ({
              value: tariffEntry[0],
              optionLabel: `${tariffEntry[1].subgroup} ${tariffEntry[1].tariff_modality}`,
            }))}
            transform={{
              output: (value) => parseInt(value as string),
            }}
            options={{
              required: { value: true, message: 'Escolha uma tarifa' },
            }}
            tooltipContent={tooltipsContent.unit.tariff}
          />
        )}
        <SelectFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.icmsType`}
          field={`units.${unitNum}.icmsType`}
          label="Tipo de Empresa"
          inputOptions={[
            {
              value: 'ICMS_COMERCIAL',
              optionLabel: 'Comercial',
            },
            {
              value: 'ICMS_INDUSTRIAL',
              optionLabel: 'Industrial',
            },
          ]}
          options={{ required: { value: true, message: 'Escolha uma classificação' } }}
          tooltipContent={tooltipsContent.unit.icmsType}
        />
        <SelectFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.energyDistributionCompany`}
          field={`units.${unitNum}.energyDistributionCompany`}
          label="Distribuidora"
          inputOptions={Object.entries(ENERGY_DISTRIBUTION_COMPANIES).map((edcEntry) => ({
            value: edcEntry[0],
            optionLabel: edcEntry[1],
          }))}
          transform={{
            output: (value) => parseInt(value as string),
          }}
          options={{
            required: { value: true, message: 'Escolha uma distribuidora' },
          }}
          tooltipContent={tooltipsContent.unit.energyDistributionCompany}
        />
        {isStateES && (
          <Checkbox
            id="isCachoeiroCustomer"
            name="isCachoeiroCustomer"
            label="Localizado em Cachoeiro"
            onChange={handleChangeIsCachoeiroCustomer}
          />
        )}
        {fieldVerificator({ fieldName: 'companyType', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
          <SelectFormField<ISimulationPayloadBase>
            id={`units.${unitNum}.companyType`}
            field={`units.${unitNum}.companyType`}
            label="Tipo"
            inputOptions={Object.entries(COMPANY_TYPES).map((tariffEntry) => ({
              value: tariffEntry[0],
              optionLabel: tariffEntry[1],
            }))}
            transform={{
              output: (value) => parseInt(value as string),
            }}
            options={{ required: { value: true, message: 'Escolha um tipo' } }}
            tooltipContent={tooltipsContent.unit.companyType}
          />
        )}
        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.migrationDate`}
          field={`units.${unitNum}.migrationDate`}
          label="Data de Migração"
          placeholder="dd/mm/aaaa"
          options={{
            required: { value: true, message: 'Insira uma data válida' },
            onChange: () => {
              trigger(['manualPricesOrDiscountsStartYear']);
            },
          }}
          tooltipContent={tooltipsContent.unit.migrationDate}
        />
        {fieldVerificator({ fieldName: 'lowTensionType', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
          <SelectFormField
            id="lowTensionType"
            field="lowTensionType"
            label="Tipo de Baixa Tensão"
            inputOptions={Object.entries(LowTensionTypeEnum).map((lowTensionTypeEntry) => ({
              value: lowTensionTypeEntry[0],
              optionLabel: lowTensionTypeEntry[1],
            }))}
            tooltipContent={tooltipsContent.unit.lowTensionType}
            options={{
              onChange: handleChangeLowTensionType,
              required: { value: true, message: 'Selecione o tipo de baixa tensão' },
            }}
          />
        )}
        {!user?.isCommercialDealer &&
          fieldVerificator({ fieldName: 'powerGeneratorCost', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
            <NumericFormField<ISimulationPayloadBase>
              id={`units.${unitNum}.powerGeneratorCost`}
              field={`units.${unitNum}.powerGeneratorCost`}
              label="Custo do Gerador"
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              tooltipContent={tooltipsContent.unit.powerGeneratorCost}
            />
          )}
      </div>
    </div>
  );
}
