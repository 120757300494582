import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { IComparisonBillSimulatorInputsWithMultipleUnits } from '@contexts/comparison-bill/types';

import SimulatorFormGeneralSection from '@components/molecules/form/simulator/sections/simulator-form-general-section';
import SimulatorFormTargetYearsSection from '@components/molecules/form/simulator/sections/simulator-form-target-years-section';
import SimulatorFormFinancialProjectionSection from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';
import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';

interface IComparisonBillSimulatorFormCore {
  formController: UseFormReturn<IComparisonBillSimulatorInputsWithMultipleUnits, any>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: IComparisonBillSimulatorInputsWithMultipleUnits) => void;
}

const ComparisonBillSimulatorFormCore = ({ formController, formRef, onSubmit }: IComparisonBillSimulatorFormCore) => {
  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormGeneralSection<IComparisonBillSimulatorInputsWithMultipleUnits> />
      <SimulatorFormTargetYearsSection />
      <SimulatorFormFinancialProjectionSection uniqueTargetYears />
      <SimulatorFormUnitsSection<IComparisonBillSimulatorInputsWithMultipleUnits> unitary />
    </form>
  );
};

export default ComparisonBillSimulatorFormCore;
