import React from 'react';

import { IComparisonBillProposal } from '@contexts/comparison-bill/types';
import useComparisonBill from '@hooks/use-comparison-bill';

import ComparisonBillLeadProposalsHub from '@components/organisms/comparison-bill/lead-proposals-hub';
import LeadsHistory from '@components/organisms/general/leads-history';

const ComparisonBillLeadsHistory = (): JSX.Element => {
  const { deleteComparisonBillAllProposalsByLeadIdHandler } = useComparisonBill();

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  return (
    <LeadsHistory<IComparisonBillProposal>
      simulatorType="COMPARISON_BILL"
      deleteAllProposalsByLeadIdHandler={deleteComparisonBillAllProposalsByLeadIdHandler}
      selectedLeadId={selectedLeadId}
      setSelectedLeadId={setSelectedLeadId}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <>
        {isDrawerOpen && selectedLeadId && (
          <ComparisonBillLeadProposalsHub
            selectedProposalId={selectedLeadId}
            setSelectedProposalId={setSelectedLeadId}
            setIsDrawerOpen={setIsDrawerOpen}
            leadId={selectedLeadId}
          />
        )}
      </>
    </LeadsHistory>
  );
};

export default ComparisonBillLeadsHistory;
