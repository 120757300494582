import React from 'react';

import { ReactComponent as ClarkeLogo } from '@assets/images/new-clarke-logo.svg';

export interface IFeasibilityAnalysisSimulationHeader {
  unitName?: string;
  leadName: string;
}

const FeasibilityAnalysisSimulationHeader: React.FC<IFeasibilityAnalysisSimulationHeader> = ({
  unitName,
  leadName,
}) => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-row w-full">
      <div className="flex w-1/3 md:mb-20 lg:w-2/4">
        <ClarkeLogo />
      </div>
      <h2 className="flex w-full text-xl md:text-4xl font-black mt-3 mb-10 md:mb-20 md:ml-5">
        Análise de Viabilidade - {unitName ?? leadName}
      </h2>
    </div>
  );
};

export default FeasibilityAnalysisSimulationHeader;
