import React from 'react';

import EconomyChart, { IEconomyChart } from '@components/molecules/general/economy-chart';
import TableWithSuperheader from '@components/molecules/general/table-with-superheader';

import TariffFlagEconomyTableColumns from './tariff-flag-economy-table/columns';
import { IFeasibilityAnalysisTableBase } from '.';
import { ITariffFlagEconomyTable } from './tariff-flag-economy-table/parser';

export interface IFeasibilityAnalysisProposalSectionBaseLowerSubsection {
  chartData?: IEconomyChart;
  economyProjectionTable?: IFeasibilityAnalysisTableBase;
  tariffFlagEconomyTable?: ITariffFlagEconomyTable;
}

const FeasibilityAnalysisProposalSectionBaseLowerSubsection = ({
  chartData,
  economyProjectionTable,
  tariffFlagEconomyTable,
}: IFeasibilityAnalysisProposalSectionBaseLowerSubsection) => {
  return (
    <div className="flex flex-col">
      {tariffFlagEconomyTable && (
        <div className="my-5">
          <TableWithSuperheader
            lastRowHighlight={false}
            columns={TariffFlagEconomyTableColumns}
            tableHeaders={[
              {
                label: `Economia por Bandeira Tarifária | ${tariffFlagEconomyTable.monthLabel} de ${tariffFlagEconomyTable.year}`,
                columnsRange: 5,
              },
            ]}
            data={tariffFlagEconomyTable.data}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-2 mb-5">
        {chartData && <EconomyChart {...chartData} />}
        {economyProjectionTable && (
          <TableWithSuperheader
            id="economyProjectionTableDataTable"
            lastRowHighlight
            columns={economyProjectionTable.columns}
            data={economyProjectionTable.data}
          />
        )}
      </div>
    </div>
  );
};

export default FeasibilityAnalysisProposalSectionBaseLowerSubsection;
