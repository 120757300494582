import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';

import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import ProductEconomySlide from '@components/molecules/general/simulation/economy-slide';

interface IProductOneSimulationUnitsEconomySection {
  simulationResults?: Array<IProductOneSimulationExtendedResult | undefined>;
}

const ProductOneSimulationUnitsEconomySection = ({ simulationResults }: IProductOneSimulationUnitsEconomySection) => {
  return (
    <>
      {simulationResults && (simulationResults[0] as IProductOneSimulationExtendedResult)?.unitsReports.length > 1 && (
        <>
          <SlideContainer>
            <GreenSectionSlide title="Detalhamento por Unidade" />
          </SlideContainer>
          {simulationResults[0]?.unitsReports?.map(
            (simulationResult, index) =>
              simulationResult && (
                <SlideContainer key={`product-one-unit-result-per-contract-duration-${index}`}>
                  <ProductEconomySlide showSideData simulationResult={simulationResult} />
                </SlideContainer>
              ),
          )}
        </>
      )}
    </>
  );
};

export default ProductOneSimulationUnitsEconomySection;
