import React from 'react';

import { getCalculatorName } from '@utils/common';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';
import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { IFeasibilityAnalysisSimulatorInputs } from '@contexts/feasibility-analysis/types';
import useFeasibilityAnalysis from '@hooks/use-feasibility-analysis';

import FeasibilityAnalysisSimulatorResultCore from '@components/organisms/feasibility-analysis/simulator/feasibility-analysis-simulator-result-core';
import { useAuth } from '@src/auth-wrapper';

export interface IFeasibilityAnalysisSimulatorResult {
  simulatorResult: IFeasibilityAnalysisSimulation;
  simulatorInputs?: IFeasibilityAnalysisSimulatorInputs;
}

const FeasibilityAnalysisSimulatorResult = ({
  simulatorResult,
  simulatorInputs,
}: IFeasibilityAnalysisSimulatorResult): JSX.Element => {
  const { createFeasibilityAnalysisProposal } = useFeasibilityAnalysis();
  const { user } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [simulationId, setSimulationId] = React.useState<string>();
  const [leadName, setLeadName] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const sendFeasibilityAnalysis = () => {
    setLoading(true);
    createFeasibilityAnalysisProposal(simulatorResult)
      .then((response) => {
        setSimulationId(response.data.data.id);
        setLeadName(response.data.data.lead.name);
      })
      .catch(() => {
        setErrorMessage('Ocorreu um erro. Tente novamente.');
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_VIEWED, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  }, []);

  return (
    <FeasibilityAnalysisSimulatorResultCore
      {...{
        simulatorInputs,
        simulatorResult,
        sendFeasibilityAnalysis,
        loading,
        leadName,
        simulationId,
        setErrorMessage,
        errorMessage,
      }}
    />
  );
};

export default FeasibilityAnalysisSimulatorResult;
