import { ILeadProposalsHubBase } from '@contexts/types';
import { IComparisonBillProposal } from '@contexts/comparison-bill/types';

import useComparisonBill from '@hooks/use-comparison-bill';
import useLeadProposals from '@hooks/use-lead-proposals';

import { LeadProposalsHubContainer } from '@components/atoms/general/lead-proposals-hub/container';
import LeadProposalsHubHeader from '@components/molecules/general/lead-proposals-hub/header';
import ComparisonBillLeadProposalsHubHistory from '@components/molecules/comparison-bill/lead-proposals-hub/history';
import ComparisonBillProposalPreviewBody from '@components/molecules/comparison-bill/lead-proposals-hub/body';

const ComparisonBillLeadProposalsHub = ({
  setIsDrawerOpen,
  selectedProposalId,
  setSelectedProposalId,
}: ILeadProposalsHubBase): JSX.Element => {
  const { getComparisonBillProposalsHandler, deleteComparisonBillProposalsHandler } = useComparisonBill();
  const { feedbackNotificationsContent, loading, leadProposals, leadId, alertMessage, onClickDeleteProposal } =
    useLeadProposals<IComparisonBillProposal>(getComparisonBillProposalsHandler, deleteComparisonBillProposalsHandler);

  const mostRecentProposal = leadProposals[0];

  return (
    <LeadProposalsHubContainer setIsDrawerOpen={setIsDrawerOpen} loading={loading} alertMessage={alertMessage}>
      <>
        <LeadProposalsHubHeader
          titleContent={mostRecentProposal?.lead.name}
          createdAt={mostRecentProposal?.createdAt}
        />
        <ComparisonBillProposalPreviewBody
          leadProposal={mostRecentProposal}
          leadId={leadId}
          selectedProposalId={selectedProposalId}
          setSelectedProposalId={setSelectedProposalId}
          onClickDeleteProposal={onClickDeleteProposal}
          feedbackNotificationsContent={feedbackNotificationsContent}
          isMainContent
        />
        {leadProposals.length > 1 && (
          <ComparisonBillLeadProposalsHubHistory
            leadId={leadId}
            selectedProposalId={selectedProposalId}
            proposalsList={leadProposals.slice(1)}
            setSelectedProposalId={setSelectedProposalId}
            onClickDeleteProposal={onClickDeleteProposal}
            feedbackNotificationsContent={feedbackNotificationsContent}
          />
        )}
      </>
    </LeadProposalsHubContainer>
  );
};

export default ComparisonBillLeadProposalsHub;
