import React from 'react';
import { useNavigate } from 'react-router-dom';

import { HOME_PATH, GENERAL_SUPPLIERS_PATH } from '@routers/constants';

import { ContractGeneratorTypeEnum } from '@contexts/types';
import { Layout, LayoutProps } from '@clarke-energia/foton';

interface IContractLayoutBase<T> extends LayoutProps {
  children: React.ReactNode;
  contractType: keyof typeof ContractGeneratorTypeEnum;
  contractFlow?: 'CONTRACT_GENERATOR_FORM' | 'CONTRACT_UPDATE_FORM' | 'WHITE_LABEL_LIST';
}

const ContractLayoutBase = <T,>({ children, contractType, contractFlow, ...layoutProps }: IContractLayoutBase<T>) => {
  const baseLinks = [
    {
      label: 'Home',
      url: `${HOME_PATH}`,
    },
    {
      label: ContractGeneratorTypeEnum[contractType],
      url: '#',
    },
  ];

  const contractFormLinks =
    contractFlow === 'CONTRACT_GENERATOR_FORM'
      ? [
          {
            label: 'Fornecedora',
            url: './',
          },
          {
            label: 'Adicionar',
            url: '#',
          },
        ]
      : contractFlow === 'CONTRACT_UPDATE_FORM'
      ? [
          {
            label: 'Fornecedora',
            url: './../',
          },
          {
            label: 'Editar',
            url: '#',
          },
        ]
      : [
          { label: 'Fornecedoras', url: GENERAL_SUPPLIERS_PATH },
          {
            label: 'Lista',
            url: '#',
          },
        ];

  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks, ...contractFormLinks];
  const navigate = useNavigate();
  const headerButtons: LayoutProps['headerButtons'] =
    contractFlow === 'WHITE_LABEL_LIST'
      ? [
          {
            kind: 'primary',
            label: 'Adicionar fornecedora',
            icon: 'PlusIcon',
            className: 'text-paragraph-medium',
            onClick: () => navigate('./adicionar'),
          },
        ]
      : [];
  return (
    <Layout
      {...layoutProps}
      navigationHistoryLinks={navigationHistoryLinks}
      darkerBackground
      headerButtons={headerButtons}
      className="h-full overflow-y-hidden pb-16"
    >
      <div className="col-span-full h-full p-2 md:p-5 overflow-y-auto">{children}</div>
    </Layout>
  );
};

export default ContractLayoutBase;
