import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import config from '@config';
import { IUpdateSellerPayload } from '@contexts/types';

import {
  IFeasibilityAnalysisContext,
  IFeasibilityAnalysisSimulatorInputs,
  IFeasibilityAnalysisSimulation,
} from './types';
import { parseFeasibilityAnalysisSimulationPayload, parseFeasibilityAnalysisProposalPayload } from './parser';

export const FeasibilityAnalysisContext = createContext({} as IFeasibilityAnalysisContext);

interface Provider {
  children: React.ReactNode;
}

const FeasibilityAnalysisProvider: React.FC<Provider> = ({ children }) => {
  const feasibilityAnalysisCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v2/feasibility-analysis`;
  const calculatorApiClient = axios.create({
    baseURL: feasibilityAnalysisCalculatorBaseURL,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  function createFeasibilityAnalysisSimulation(simulationData: IFeasibilityAnalysisSimulatorInputs): AxiosPromise {
    const simulationPayload = parseFeasibilityAnalysisSimulationPayload(simulationData);
    return calculatorApiClient.post('/simulation', simulationPayload);
  }

  function createFeasibilityAnalysisProposal(proposalData: IFeasibilityAnalysisSimulation): AxiosPromise {
    const proposalPayload = parseFeasibilityAnalysisProposalPayload(proposalData);
    return calculatorApiClient.post('proposals', proposalPayload);
  }

  function getFeasibilityAnalysisProposals(leadId?: string, sellerId?: string): AxiosPromise {
    return calculatorApiClient.get('proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  function getFeasibilityAnalysisProposalById(proposalId?: string): AxiosPromise {
    return calculatorApiClient.get(`proposals/${proposalId}`);
  }

  function deleteFeasibilityAnalysisProposalsHandler(leadId: string, proposalsIds: Array<string>): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteFeasibilityAnalysisAllProposalsByLeadIdHandler(leadId: string): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }

  function updateSellerOfFeasibilityAnalysisProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise {
    return calculatorApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  return (
    <FeasibilityAnalysisContext.Provider
      value={{
        createFeasibilityAnalysisSimulation,
        createFeasibilityAnalysisProposal,
        getFeasibilityAnalysisProposalById,
        getFeasibilityAnalysisProposals,
        deleteFeasibilityAnalysisProposalsHandler,
        deleteFeasibilityAnalysisAllProposalsByLeadIdHandler,
        updateSellerOfFeasibilityAnalysisProposal,
      }}
    >
      {children}
    </FeasibilityAnalysisContext.Provider>
  );
};

export default FeasibilityAnalysisProvider;
