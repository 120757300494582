import React from 'react';
import numeral from 'numeral';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';

export interface IFeasibilityAnalysisFlagEconomy {
  simulationData: IFeasibilityAnalysisSimulation;
  tariffFlag: 'green' | 'yellow' | 'redP1' | 'redP2';
}

const FeasibilityAnalysisFlagEconomy: React.FC<IFeasibilityAnalysisFlagEconomy> = ({ simulationData, tariffFlag }) => {
  const tariffFlagTranslator: Record<IFeasibilityAnalysisFlagEconomy['tariffFlag'], string> = {
    green: 'Verde',
    yellow: 'Amarela',
    redP1: 'Vermelha P1',
    redP2: 'Vermelha P2',
  };

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">
        Total economizado na Bandeira {tariffFlagTranslator[tariffFlag]} ({simulationData?.targetYear} ano(s))
      </dt>
      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
        <p className="font-bold text-md text-green-700">
          {numeral(simulationData?.generalReport.tariffFlagScenarios[tariffFlag].totalSavingWithInitialExpenses).format(
            '$ 0,0',
          )}
          (
          {numeral(
            // @ts-expect-error: provisory threatment to backend-generated ambiguous types
            simulationData?.generalReport.tariffFlagScenarios[tariffFlag]['totalSavingWithInitialExpensesInPercentage'],
          ).format('% 0.00')}
          )
        </p>
        <p className="mt-2">
          {numeral(simulationData?.generalReport.tariffFlagScenarios[tariffFlag].averageSavingByMonth).format('$ 0,0')}/
          mês
        </p>
      </dd>
    </div>
  );
};

export default FeasibilityAnalysisFlagEconomy;
