import React from 'react';

import TableWithSuperheader from '@components/molecules/general/table-with-superheader';

import FreeMarketTableColumns from './free-market-table/columns';
import FreeMarketTableHeaders from './free-market-table/headers';

import CaptiveMarketTableColumns from './captive-market-table/columns';
import CaptiveMarketTableHeaders from './captive-market-table/headers';
import UnitsResumeTableColumns from './units-resume-table/columns';
import { IFeasibilityAnalysisSimulationAcrTable } from './captive-market-table/parser';

export interface IFeasibilityAnalysisProposalSectionBaseUpperSubsection {
  aclTableData?: Array<Record<string, string>>;
  acrTable?: IFeasibilityAnalysisSimulationAcrTable;
  generalAnalysis?: boolean;
  unitsResumeTableData?: Record<string, string | number | JSX.Element>[];
}

const FeasibilityAnalysisProposalSectionBaseUpperSubsection = ({
  aclTableData,
  acrTable,
  generalAnalysis,
  unitsResumeTableData,
}: IFeasibilityAnalysisProposalSectionBaseUpperSubsection) => {
  if (generalAnalysis && unitsResumeTableData) {
    return (
      <div className="flex flex-row gap-5 w-full overflow-x-auto">
        <TableWithSuperheader
          columns={UnitsResumeTableColumns}
          data={unitsResumeTableData}
          tableHeaders={[
            {
              label: 'Lista de Unidades',
              columnsRange: 6,
            },
          ]}
        />
      </div>
    );
  } else {
    return (
      <div className="flex flex-row gap-5 w-full overflow-x-auto">
        {acrTable && (
          <div className="flex w-full lg:w-3/5">
            <TableWithSuperheader
              lastRowHighlight
              columns={CaptiveMarketTableColumns}
              tableHeaders={CaptiveMarketTableHeaders(acrTable.monthLabel, acrTable.year)}
              data={acrTable.data}
            />
          </div>
        )}
        {aclTableData && (
          <div className="flex w-full lg:w-2/5">
            <TableWithSuperheader
              lastRowHighlight
              columns={FreeMarketTableColumns}
              tableHeaders={FreeMarketTableHeaders}
              data={aclTableData}
            />
          </div>
        )}
      </div>
    );
  }
};

export default FeasibilityAnalysisProposalSectionBaseUpperSubsection;
