import { useState, useEffect, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useContextSelector } from 'use-context-selector';
import { IFeedbackData } from './use-management-contract';

import { WhiteLabelContext, IWhiteLabelContext } from '@contexts/white-label';
import { useParams } from 'react-router-dom';
import { useFieldArray } from 'react-hook-form';
import { debounce } from 'lodash';
import useLeads from '@hooks/use-leads';
import { useAuth } from '@src/auth-wrapper';
import Auth0ApiService from '@services/auth0';
import { IWhiteLabelSupplierPayload, IWhiteLabelSupplierUser } from '@contexts/white-label/type';
import { transformObjectKeysToCamelCase } from '@utils/common';
import { ILeadsPaginated } from './use-leads-history';
interface IWhiteLabelContract extends IWhiteLabelContext {
  isLoading: boolean;
  isWhiteLabelProposal: boolean;
  feedbackData: IFeedbackData | undefined;
  setFeedbackData: React.Dispatch<React.SetStateAction<IFeedbackData | undefined>>;
  useLogoEffect: () => boolean;
  useLoadingEffect: () => boolean;
  useWhiteLabelSupplierFormUsers: () => {
    supplierId: string | undefined;
    fields: IWhiteLabelSupplierUser[];
    usersWhiteLabelSupplier: IWhiteLabelSupplierUser[];
    searchText: string | undefined;
    handleInputChange: (value: string[]) => void;
    setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
    remove: (index: number) => void;
  };
}

function useWhiteLabelSupplierFormUsers() {
  const { user } = useAuth();
  const { fields, append, remove } = useFieldArray<IWhiteLabelSupplierPayload, 'users'>({
    name: 'users',
    rules: { minLength: 1, required: { value: true, message: 'Selecione um usuário' } },
  });

  const supplierId = useParams<{ id: string }>()?.id;
  const { getWhiteLabelSupplierById } = useWhiteLabel();
  const [usersWhiteLabelSupplier, setUsersWhiteLabelSupplier] = useState<IWhiteLabelSupplierPayload['users']>([]);
  const { getLeads } = useLeads();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>();
  const [leadList, setLeadList] = useState<ILeadsPaginated>();

  const {
    authStatus: { accessToken },
  } = useAuth();
  const auth0ApiService = accessToken ? new Auth0ApiService(accessToken) : null;

  useEffect(() => {
    supplierId && getWhiteLabelSupplierById(supplierId);
  }, [supplierId]);

  const parseLeadList = (leadList: Record<string, any>): ILeadsPaginated => {
    return transformObjectKeysToCamelCase(leadList) as ILeadsPaginated;
  };

  const fetchData = (searchText?: string, currentPage?: number) => {
    setLoading(true);
    getLeads(currentPage, searchText, true, 'WHITE_LABEL', user?.id)
      .then((response) => {
        const parsedLeadList = parseLeadList(response.data.data);
        setLeadList(parsedLeadList);
      })
      .finally(() => setLoading(false));
  };

  const debouncedFetchData = useCallback(
    debounce((searchText?: string, currentPage?: number) => {
      fetchData(searchText, currentPage);
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedFetchData(searchText, 1);
  }, [searchText, debouncedFetchData]);

  async function fetchWhiteLabelSupplierUsers() {
    const response = await auth0ApiService?.getSellersUsers('Vendas White Label', undefined, true);
    const parsedUsersList = response?.data.map((user) => ({
      id: user.app_metadata.uuid ?? '',
      email: user.email ?? '',
      name: user.name ?? '',
      pictureUrl: user.picture ?? '',
    }));
    if (parsedUsersList) {
      setUsersWhiteLabelSupplier(parsedUsersList);
    }
  }

  useEffect(() => {
    fetchWhiteLabelSupplierUsers();
  }, []);

  const handleInputChange = (users: string[]) => {
    if (!users) return;
    users.map((userId) => {
      const selectedUser = usersWhiteLabelSupplier.find((user) => user.id === userId);
      const isUserAlreadyInList = fields.some((field) => field.email === selectedUser?.email);
      if (!isUserAlreadyInList && selectedUser) {
        append(selectedUser);
      }
    });
  };

  return {
    loading,
    leadList,
    supplierId,
    fields,
    usersWhiteLabelSupplier,
    searchText,
    handleInputChange,
    setSearchText,
    remove,
  };
}

function useLogoEffect() {
  const [isDocumentsAttached, setIsDocumentsAttached] = useState<boolean>(false);
  const fileLogo = useWatch({ name: 'fileList' }) as File;

  useEffect(() => {
    if (fileLogo instanceof File && fileLogo !== null) {
      setIsDocumentsAttached(true);
    } else {
      setIsDocumentsAttached(false);
    }
  }, []);

  return isDocumentsAttached;
}

function useLoadingEffect() {
  const { isLoading, setFeedbackData } = useWhiteLabel();

  useEffect(() => {
    if (isLoading) {
      setFeedbackData({ kind: 'neutral', label: 'Aguarde', message: 'Estamos processando a solicitação.' });
    }
  }, [isLoading]);

  return isLoading;
}
const useWhiteLabel = (): IWhiteLabelContract => {
  const [feedbackData, setFeedbackData] = useState<IFeedbackData | undefined>();
  const openSuccessModalWhiteLabel = useContextSelector(WhiteLabelContext, (sales) => sales.openSuccessModalWhiteLabel);
  const setOpenSuccessModalWhiteLabel = useContextSelector(
    WhiteLabelContext,
    (sales) => sales.setOpenSuccessModalWhiteLabel,
  );
  const openErrorModalWhiteLabel = useContextSelector(WhiteLabelContext, (sales) => sales.openErrorModalWhiteLabel);
  const setOpenErrorModalWhiteLabel = useContextSelector(
    WhiteLabelContext,
    (sales) => sales.setOpenErrorModalWhiteLabel,
  );

  const whiteLabelSupplier = useContextSelector(WhiteLabelContext, (sales) => sales.whiteLabelSupplier);
  const allWhiteLabelSuppliers = useContextSelector(WhiteLabelContext, (sales) => sales.allWhiteLabelSuppliers);
  const isLoading = useContextSelector(WhiteLabelContext, (sales) => sales.isLoading);
  const isWhiteLabelProposal = useContextSelector(WhiteLabelContext, (sales) => sales.isWhiteLabelProposal);
  const isUserMissingSupplier = useContextSelector(WhiteLabelContext, (sales) => sales.isUserMissingSupplier);
  const createNewWhiteLabelSupplier = useContextSelector(
    WhiteLabelContext,
    (sales) => sales.createNewWhiteLabelSupplier,
  );
  const getWhiteLabelSupplierByUserId = useContextSelector(
    WhiteLabelContext,
    (sales) => sales.getWhiteLabelSupplierByUserId,
  );
  const getWhiteLabelSupplierById = useContextSelector(WhiteLabelContext, (sales) => sales.getWhiteLabelSupplierById);
  const getAllWhiteLabelSuppliers = useContextSelector(WhiteLabelContext, (sales) => sales.getAllWhiteLabelSuppliers);
  const updateWhiteLabelSupplier = useContextSelector(WhiteLabelContext, (sales) => sales.updateWhiteLabelSupplier);
  const deleteWhiteLabelSupplier = useContextSelector(WhiteLabelContext, (sales) => sales.deleteWhiteLabelSupplier);
  return {
    whiteLabelSupplier,
    allWhiteLabelSuppliers,
    isLoading,
    isWhiteLabelProposal,
    isUserMissingSupplier,
    openSuccessModalWhiteLabel,
    setOpenSuccessModalWhiteLabel,
    openErrorModalWhiteLabel,
    setOpenErrorModalWhiteLabel,
    createNewWhiteLabelSupplier,
    updateWhiteLabelSupplier,
    getAllWhiteLabelSuppliers,
    getWhiteLabelSupplierByUserId,
    getWhiteLabelSupplierById,
    feedbackData,
    setFeedbackData,
    useLogoEffect,
    useLoadingEffect,
    useWhiteLabelSupplierFormUsers,
    deleteWhiteLabelSupplier,
  };
};

export default useWhiteLabel;
