// GENERAL PATHS
export const HOME_PATH = '/';
export const LOGIN_PATH = '/login';
export const DOES_NOT_EXIST_PATH = '/does-not-exist';

export const GENERAL_SUPPLIERS_PATH = 'fornecedoras';
export const GENERAL_LIST_PATH = 'lista';
export const LEAD_PROPOSALS_PATH = 'lista/grupo-comercial/:leadId';
export const GENERAL_CALCULATOR_PATH = 'calculadora';
export const GENERAL_CONTRACT_PATH = 'contrato';
export const GENERAL_SIMULATION_PATH = ':id';
export const GENERAL_SIMPLIFIED_PROPOSAL_PATH = 'proposta-simplificada/:id';
export const SIMULATION_NOT_FOUND_PATH = '/proposta-nao-encontrada';

// SIMULATOR PATHS
export const CLARKE_MANAGEMENT_PATH = '/clarke-gestao';
export const CLARKE_RETAIL_PATH = '/clarke-varejo';
export const COMPARISON_BILL_PATH = '/comparativo-contas';
export const FEASIBILITY_ANALYSIS_PATH = '/analise-viabilidade';
export const CONTRACTED_DEMAND_PATH = '/demanda-contratada';
export const LOW_TENSION_PATH = '/baixa-tensao';

// WHITE LABEL SUPPLIERS DOMAIN
export const WHITE_LABEL_PATH = '/white-label';
export const ADD_SUPPLIERS_PROJECTION_PATH = '/adicionar';
export const UPSERT_SUPPLIERS_PROJECTION_PATH = '/editar';

// OTHER PLATFORMS PATHS
export const ADMIN_PATH = 'https://admin.clarke.com.br/';
