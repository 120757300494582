const CaptiveMarketTableColumns = [
  {
    key: 'item',
    label: 'Item',
    render: (value: any): string => value,
  },
  {
    key: 'CaptiveMarketquantity',
    label: 'Quantidade',
    render: (value: any): string => value,
  },
  {
    key: 'CaptiveMarkettariff',
    label: 'Tarifa',
    render: (value: any): string => value,
  },
  {
    key: 'CaptiveMarkettotal',
    label: 'Total',
    render: (value: any): string => value,
  },
];

export default CaptiveMarketTableColumns;
