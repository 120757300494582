import React from 'react';
import { FeedbackNotification } from '@clarke-energia/foton';
import { useForm, FormProvider } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName, isObjectEmpty } from '@utils/common';
import {
  compareAndGetProposalValidity,
  getSimulatorInitialValues,
} from '@components/molecules/form/simulator/default-values-getters';

import { IProposalUser, ISimulationUnitPayloadBase } from '@contexts/types';
import { ILowTensionSimulatorInputs } from '@contexts/low-tension/types';
import { parseLowTensionSimulatorResult } from '@contexts/low-tension/parser';
import { ILowTensionSimulation } from '@contexts/low-tension/types';
import { parseUnitsConsumptionHistory } from '@contexts/calculator/units-payload-handlers';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import useLowTension from '@hooks/use-low-tension';

import LowTensionSimulatorFormCore from '@components/organisms/low-tension/simulator/low-tension-simulator-form-core';
import { CalculatorFormFooter } from '@components/molecules/calculator-form-footer';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';

interface ILowTensionSimulatorForm {
  setSimulatorResult: React.Dispatch<React.SetStateAction<ILowTensionSimulation | undefined>>;
  setSimulatorInputs: React.Dispatch<React.SetStateAction<ILowTensionSimulatorInputs | undefined>>;
}

const LowTensionSimulatorForm = ({ setSimulatorResult, setSimulatorInputs }: ILowTensionSimulatorForm): JSX.Element => {
  const { CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const { createLowTensionSimulation } = useLowTension();

  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formController = useForm<ILowTensionSimulatorInputs>({
    defaultValues: getSimulatorInitialValues('LOW_TENSION') as ILowTensionSimulatorInputs,
    mode: 'all',
  });
  const {
    watch,
    reset,
    formState: { errors },
  } = formController;
  const formValues = watch();

  const { user } = useAuth();
  const authorInfo =
    user &&
    ({
      id: user.id,
      name: user.name,
      pictureUrl: user.pictureUrl,
    } as IProposalUser);

  const onSubmit = (values: ILowTensionSimulatorInputs) => {
    setLoading(true);

    const parsedUnits = parseUnitsConsumptionHistory(values.units, true) as ISimulationUnitPayloadBase[];
    const finalValues: ILowTensionSimulatorInputs = {
      ...values,
      units: parsedUnits,
      proposalValidity: compareAndGetProposalValidity(values.proposalValidity),
      author: authorInfo,
    };

    setSimulatorInputs(finalValues);
    CopyFormValues(finalValues, false, false);

    createLowTensionSimulation(finalValues)
      .then((response) => {
        const parsedCalculatorResponse = parseLowTensionSimulatorResult(response);
        setSimulatorResult(parsedCalculatorResponse);
        eventTracker.trackEvent(eventLabels.FORM_SUBMITTED, {
          form: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
      })
      .catch((error) => {
        eventTracker.trackEvent(eventLabels.ERROR_MESSAGE_WHEN_CALCULATION_FAILS, {
          product: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
        alert('Ocorreu um erro. Verifique o formulário e tente novamente.');

        throw error;
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (location.href.includes('refazer-calculo')) {
      PasteFormValues(reset, 'CLARKE_MANAGEMENT', false);
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_SIMULATOR, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      eventTracker.trackEvent(eventLabels.FORM_STARTED, {
        form: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, []);

  return (
    <SimulatorLayoutBase<ILowTensionSimulatorInputs>
      title="Simulador"
      simulatorType="LOW_TENSION"
      simulatorFlow="SIMULATOR_FORM"
      setFormValues={reset}
      formValues={formValues}
    >
      <div className="col-span-full flex flex-col w-full lg:w-3/4 p-7">
        <FormProvider {...formController}>
          {customClipboardFeedbackNotificationContent && (
            <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
              <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
            </div>
          )}
          <LowTensionSimulatorFormCore {...{ formRef, onSubmit, formController }} />
          <CalculatorFormFooter error={!isObjectEmpty(errors)} {...{ formRef, loading, onSubmit }} />
        </FormProvider>
      </div>
    </SimulatorLayoutBase>
  );
};

export default LowTensionSimulatorForm;
