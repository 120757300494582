import React from 'react';
import numeral from 'numeral';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';

export interface IFeasibilityAnalysisFlagEconomy {
  simulationData: IFeasibilityAnalysisSimulation;
  tariffFlag: 'green' | 'yellow' | 'redP1' | 'redP2';
}

export const FeasibilityAnalysisProposalKeyMetricsPanel: React.FC<IFeasibilityAnalysisFlagEconomy> = ({
  simulationData,
  tariffFlag,
}) => {
  const tariffFlagTranslator: Record<IFeasibilityAnalysisFlagEconomy['tariffFlag'], string> = {
    green: 'Verde',
    yellow: 'Amarela',
    redP1: 'Vermelha P1',
    redP2: 'Vermelha P2',
  };

  return (
    <div className="py-5 flex flex-col border-b-1 border-neutral-30">
      <dt className="text-paragraph-small text-neutral-50">
        Total economizado na Bandeira {tariffFlagTranslator[tariffFlag]} ({simulationData?.targetYear} ano(s))
      </dt>
      <dd className="mt-1 flex flex-row gap-2">
        <p className="font-semibold text-paragraph-medium">
          {numeral(simulationData?.generalReport.tariffFlagScenarios[tariffFlag].totalSavingWithInitialExpenses).format(
            '$ 0,0',
          )}{' '}
          (
          {numeral(
            // @ts-expect-error: provisory threatment to backend-generated ambiguous types
            simulationData?.generalReport.tariffFlagScenarios[tariffFlag]['totalSavingWithInitialExpensesInPercentage'],
          ).format('% 0.00')}
          )
        </p>
        <p className="text-paragraph-medium">
          ({numeral(simulationData?.generalReport.tariffFlagScenarios[tariffFlag].averageSavingByMonth).format('$ 0,0')}{' '}
          / mês)
        </p>
      </dd>
    </div>
  );
};
