import {
  COMPARISON_BILL_PATH,
  CONTRACTED_DEMAND_PATH,
  FEASIBILITY_ANALYSIS_PATH,
  GENERAL_LIST_PATH,
  LEAD_PROPOSALS_PATH,
  LOW_TENSION_PATH,
  CLARKE_MANAGEMENT_PATH,
  CLARKE_RETAIL_PATH,
} from './constants';

export const getSimulatorPath = () => {
  const currentPath = window.location.pathname;
  if (currentPath.includes(CLARKE_MANAGEMENT_PATH)) {
    return CLARKE_MANAGEMENT_PATH;
  } else if (currentPath.includes(FEASIBILITY_ANALYSIS_PATH)) {
    return FEASIBILITY_ANALYSIS_PATH;
  } else if (currentPath.includes(COMPARISON_BILL_PATH)) {
    return COMPARISON_BILL_PATH;
  } else if (currentPath.includes(CONTRACTED_DEMAND_PATH)) {
    return CONTRACTED_DEMAND_PATH;
  } else if (currentPath.includes(CLARKE_RETAIL_PATH)) {
    return CLARKE_RETAIL_PATH;
  } else if (currentPath.includes(LOW_TENSION_PATH)) {
    return LOW_TENSION_PATH;
  }
};

export const buildLeadProposalsPath = (leadId: string) => {
  const paramatrizedPath = LEAD_PROPOSALS_PATH.replace(':leadId', leadId);
  return `${getSimulatorPath()}/${paramatrizedPath}`;
};

export const buildProposalsHistoryPath = () => {
  return `${getSimulatorPath()}/${GENERAL_LIST_PATH}`;
};

export const buildCreateContractPath = (leadId: string, proposalId: string) => {
  return `${getSimulatorPath()}/contrato?proposalId=${proposalId}&leadId=${leadId}`;
};
