import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';
import { getCalculatorName, isArrayEmpty, parseStringToBoolean } from '@utils/common';

import { AclTypeEnum, ChargesCoverageTypeForManualAclPricesEnum, ISimulationUnitPayloadBase } from '@contexts/types';
import { IProductOneSimulatorInputs } from '@contexts/product-one/types';

import { eventLabels } from '@services/eventTracking/eventsLabels';
import eventTracker from '@services/eventTracking';

import { RadioFormField } from '@components/atoms/form/fields/radio-form-field';
import { ManualInputsTargetYearRow } from '@components/atoms/form/manual-inputs-target-year-row';
import { IGeneralFormSelectableInputsOptions } from '@components/atoms/form/types';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';

import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '../static-data';
import { calculateUnitMigrationDate } from '../default-values-getters';

interface ISimulatorFormFinancialProjectionSection {
  uniqueTargetYears?: boolean;
}

const aclTypesIndexer: Record<keyof typeof AclTypeEnum, any> = {
  FIXED_PRICE: {
    label: 'Informar preços manualmente?',
    helperFieldName: 'hasManualPrice',
    fieldName: 'manualAclPrice',
    eventTrackingLabel: eventLabels.INSERT_MANUAL_PRICES_CHECKED,
    type: 'FIXED_PRICE',
    fieldPrefix: 'R$ ',
  },
  GUARANTEED_DISCOUNT: {
    label: 'Informar descontos manualmente?',
    helperFieldName: 'hasManualDiscount',
    fieldName: 'manualDiscountInputs',
    eventTrackingLabel: eventLabels.INSERT_MANUAL_DISCOUNT_CHECKED,
    type: 'GUARANTEED_DISCOUNT',
    fieldSuffix: '%',
  },
};

const SimulatorFormFinancialProjectionSection = ({
  uniqueTargetYears,
}: ISimulatorFormFinancialProjectionSection): JSX.Element => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { isDirty },
  } = useFormContext();

  const { user } = useAuth();

  const migrationYear = Number(calculateUnitMigrationDate(true));

  const [inputCharacteristics, setInputCharacteristics] = React.useState<any>();

  const aclType = watch('aclType');
  const startYear = watch('manualPricesOrDiscountsStartYear');
  const units = watch('units');

  const targetYears = uniqueTargetYears ? [watch('targetYear')] : watch('targetYears');
  const isInputActive = inputCharacteristics && watch(inputCharacteristics.helperFieldName);

  const defaultBooleanInputOptions: Array<IGeneralFormSelectableInputsOptions> = [
    {
      id: 'true',
      value: 'true',
      optionLabel: 'Sim',
      defaultChecked: isInputActive,
    },
    {
      id: 'false',
      value: 'false',
      optionLabel: 'Não',
      defaultChecked: !isInputActive,
    },
  ];

  function getMigrationStartYear(units: ISimulationUnitPayloadBase[]) {
    const migrationDateList = units.map((unit) =>
      unit.migrationDate ? Number(unit.migrationDate.slice(-4)) : undefined,
    );
    const validUnitMigrationDateList = migrationDateList.filter((value) => value !== undefined) as number[];

    //@ts-expect-error :: invalid type for Math.min method
    const migrationStartYear = migrationDateList ? Math.min(validUnitMigrationDateList) : undefined;

    return migrationStartYear;
  }

  function checkValidStartYear(startYear: number) {
    if (units && getMigrationStartYear(units)) {
      const migrationStartYear = getMigrationStartYear(units);
      if (startYear != migrationStartYear) {
        return `O valor do ano inicial deve ser igual a data de início de migração de ${migrationStartYear}`;
      }
    } else {
      if (!startYear || startYear < migrationYear) {
        return `Insira um valor maior ou igual a ${migrationYear}`;
      }
    }
  }

  const clearInput = () => {
    setValue(aclTypesIndexer.FIXED_PRICE.fieldName, Object());
    setValue(aclTypesIndexer.FIXED_PRICE.helperFieldName, false);

    setValue(aclTypesIndexer.GUARANTEED_DISCOUNT.fieldName, Object());
    setValue(aclTypesIndexer.GUARANTEED_DISCOUNT.helperFieldName, false);
  };

  React.useEffect(() => {
    if (!startYear) {
      setValue('manualPricesOrDiscountsStartYear', migrationYear);
    }
  }, []);

  React.useEffect(() => {
    setInputCharacteristics(aclTypesIndexer[aclType as keyof typeof AclTypeEnum]);
    if (isDirty) clearInput();
  }, [aclType]);

  React.useEffect(() => {
    if (!isInputActive) {
      clearInput();
      clearErrors([aclTypesIndexer.FIXED_PRICE.fieldName, aclTypesIndexer.GUARANTEED_DISCOUNT.fieldName]);
    }
  }, [isInputActive]);

  return (
    <>
      {!user?.isCommercialDealer && !user?.isWhiteLabel && inputCharacteristics && (
        <div className="mt-3 bg-neutral-10 rounded-medium pt-4 pb-6 px-6">
          <div className="flex justify-between items-center py-4 px-3">
            <RadioFormField
              label={inputCharacteristics.label}
              field={inputCharacteristics.helperFieldName}
              inputOptions={defaultBooleanInputOptions}
              options={{
                disabled: isArrayEmpty(targetYears),
                onChange: (event) => {
                  const parsedChoosedOption = parseStringToBoolean(event.target.value);
                  setValue(inputCharacteristics.helperFieldName, parsedChoosedOption);

                  parsedChoosedOption &&
                    eventTracker.trackEvent(inputCharacteristics.eventTrackingLabel, {
                      product: getCalculatorName(location.href),
                      user_groups: user?.groups ? user?.groups.join(', ') : '',
                      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
                    });
                },
              }}
              tooltipContent={tooltipsContent.financialProjection.hasFinancialProjection}
            />
            {isInputActive && (
              <div className="flex gap-5 mt-5 items-start">
                <NumericFormField
                  id="manualPricesOrDiscountsStartYear"
                  field="manualPricesOrDiscountsStartYear"
                  label="Ano Inicial"
                  formatProps={{
                    allowNegative: false,
                    thousandSeparator: '',
                    decimalScale: 0,
                    format: '####',
                  }}
                  options={{
                    required: { value: isInputActive ? true : false, message: 'Insira uma data válida' },
                    validate: (value) => checkValidStartYear(value),
                  }}
                  className="mt-3"
                  tooltipContent={tooltipsContent.financialProjection.startYear}
                />
              </div>
            )}
          </div>
          {isInputActive && (
            <div className="flex flex-col gap-6 mt-6 w-full">
              {[...targetYears].sort().map((yearGroup) => (
                <ManualInputsTargetYearRow
                  key={`manual-prices-or-discounts-year-group-row-${yearGroup}`}
                  manualInputFieldPrefix={inputCharacteristics.fieldPrefix}
                  manualInputFieldSuffix={inputCharacteristics.fieldSuffix}
                  manualInputsFieldName={inputCharacteristics.fieldName}
                  startYear={startYear}
                  targetYear={yearGroup}
                />
              ))}
            </div>
          )}
          {isInputActive && (
            <div className="mt-6 w-fit">
              <SelectFormField<IProductOneSimulatorInputs>
                id={`manualAclPricesHasChargesCoverage`}
                field={`manualAclPricesHasChargesCoverage`}
                label="Encargos"
                inputOptions={Object.entries(ChargesCoverageTypeForManualAclPricesEnum).map(
                  (chargesCoverageTypeConditionEntry) => ({
                    value: chargesCoverageTypeConditionEntry[0],
                    optionLabel: chargesCoverageTypeConditionEntry[1],
                  }),
                )}
                options={{
                  required: { value: true, message: 'Esse campo é obrigatório' },
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SimulatorFormFinancialProjectionSection;
