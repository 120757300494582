import React from 'react';

const FreeMarketPricesTableColumns = [
  {
    label: '',
    render: (value: React.FC): React.FC => value,
  },
];

export default FreeMarketPricesTableColumns;
