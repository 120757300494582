import styled from 'styled-components';

export const SlideContainerGreater = styled.div`
  width: 1920px;
  height: 1280px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${(props: { withPadding?: boolean }) => (props.withPadding ? '2rem' : 0)};
`;
