import React from 'react';

import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';

import { SlideContainerGreater } from '@components/organisms/feasibility-analysis/simulation/styles';

import Cover from '@components/molecules/feasibility-analysis/simulation/cover';

interface IFeasibilityAnalysisSimulationIntroductorySection {
  hasMultipleUnits?: boolean;
  simulationResult?: IFeasibilityAnalysisProposal;
}

const FeasibilityAnalysisSimulationIntroductorySection = ({
  hasMultipleUnits,
  simulationResult,
}: IFeasibilityAnalysisSimulationIntroductorySection) => {
  return (
    <>
      {hasMultipleUnits && simulationResult && (
        <SlideContainerGreater>
          <Cover companyName={simulationResult.lead.name} />
        </SlideContainerGreater>
      )}
    </>
  );
};

export default FeasibilityAnalysisSimulationIntroductorySection;
