import React from 'react';
import axios, { AxiosPromise } from 'axios';
import { createContext } from 'use-context-selector';

import { useAuth } from '@src/auth-wrapper';
import config from '@config';
import { IProposalLead, IResponseBase, SimulatorTypeEnum } from '@contexts/types';

import { ILeadPayLoad, ILeads, ILeadsContext } from './types';

export const LeadsContext = createContext({} as ILeadsContext);

interface Provider {
  children: React.ReactNode;
}

const LeadsProvider: React.FC<Provider> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const leadCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v1/lead`;
  const calculatorApiClient = axios.create({
    baseURL: leadCalculatorBaseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  function createLead(leadPayLoad: ILeadPayLoad): AxiosPromise<IResponseBase<IProposalLead>> {
    return calculatorApiClient.post('/', leadPayLoad);
  }

  function getLeads(
    page?: number,
    name?: string,
    ignoreLeadsWithoutProposals?: boolean,
    simulatorName?: keyof typeof SimulatorTypeEnum,
    sellerId?: string,
  ): AxiosPromise<IResponseBase<ILeads>> {
    const legacySimulatorNamesIndexer: Record<keyof typeof SimulatorTypeEnum, string> = {
      CLARKE_MANAGEMENT: 'PRODUCT_ONE',
      CLARKE_RETAIL: 'PRODUCT_TWO',
      LOW_TENSION: 'LOW_TENSION',
      COMPARISON_BILL: 'COMPARISON_BILL',
      FEASIBILITY_ANALYSIS: 'FEASIBILITY_ANALYSIS',
      CONTRACTED_DEMAND: 'CONTRACTED_DEMAND',
      WHITE_LABEL: 'WHITE LABEL',
    };

    return calculatorApiClient.get('/', {
      params: {
        page: page,
        term: name,
        ignore_leads_without_proposals: ignoreLeadsWithoutProposals,
        simulator_name: simulatorName && legacySimulatorNamesIndexer[simulatorName],
        linked_user_id: sellerId,
      },
    });
  }

  function getLeadById(id?: string): AxiosPromise<IResponseBase<IProposalLead>> {
    return calculatorApiClient.get(`/${id}`);
  }

  return <LeadsContext.Provider value={{ getLeads, createLead, getLeadById }}>{children}</LeadsContext.Provider>;
};

export default LeadsProvider;
