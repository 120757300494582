const parseCaptiveMarketBillMinimalTableData = (acrValue: number | undefined) => {
  return [
    {
      item: 'Fatura de Distribuição + Energia',
      value: `${acrValue}`,
    },
    {
      item: 'Total Distribuição + Energia',
      value: `${acrValue}`,
    },
  ];
};

export default parseCaptiveMarketBillMinimalTableData;
