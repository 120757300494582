import React from 'react';
import numeral from 'numeral';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';

const parseUnitsResumeTable = (
  simulationResult: IFeasibilityAnalysisSimulation,
): Record<string, string | number | JSX.Element>[] =>
  simulationResult.unitsReport.map((unit) => {
    return {
      unit: unit.nickname,
      aclDemmandPeak: unit.aclContractedDemandPeak || '-',
      aclDemmandOffPeak: unit.aclContractedDemandOffPeak || '-',
      acrDemmandPeak: unit.acrContractedDemandPeak || '-',
      acrDemmandOffPeak: unit.acrContractedDemandOffPeak || '-',
      economyWithInitialExpenses: (
        <div>
          {`${numeral(unit.tariffFlagScenarios.default.totalSavingWithInitialExpenses).format('$ 0,0.00')}`}{' '}
          <div className="text-primary-60 font-semibold inline">
            {`(${numeral(unit.tariffFlagScenarios.default.totalSavingInPercentageWithInitialExpenses).format(
              '% 0,0',
            )})`}
          </div>
        </div>
      ),
    };
  });

export default parseUnitsResumeTable;
