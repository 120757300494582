import { AxiosResponse } from 'axios';

import { parseFinancialProjectionPayload } from '@contexts/parser';
import { ILowTensionProposals, ILowTensionProposal, ILowTensionSimulatorInputs } from '@contexts/low-tension/types';
import { IBusinessHours, IResponseBase } from '@contexts/types';

import {
  insertSubstringAtIndex,
  isStringEmpty,
  transformObjectKeysToCamelCase,
  transformObjectKeysToSnakeCase,
} from '@utils/common';

import { ILowTensionSimulation } from './types';

export const parseLowTensionSimulatorResultToProposalPayload = (
  simulatorResult: ILowTensionSimulation,
): Record<string, any> => {
  const proposalPayload = {
    leadId: null,
    type: 'low_tension_v1',
    lead: simulatorResult.inputs.lead,
    author: simulatorResult.inputs.author,
    seller: simulatorResult.inputs.seller,
    inputs: simulatorResult.inputs,
    calculatorMetadata: simulatorResult,
  };

  return transformObjectKeysToSnakeCase(proposalPayload);
};

export const parseLowTensionSimulationPayload = (simulationData: ILowTensionSimulatorInputs): Record<string, any> => {
  const isBusinessHoursEmpty = (businessHours: IBusinessHours | null) => {
    if (isStringEmpty(businessHours?.openingTime) || isStringEmpty(businessHours?.closingTime)) return true;
  };

  const parseBusinessHours = (businessHours: IBusinessHours) => {
    const parsedBusinessHours: IBusinessHours = {
      openingTime: insertSubstringAtIndex(businessHours.openingTime, ':', 2),
      closingTime: insertSubstringAtIndex(businessHours.closingTime, ':', 2),
    };

    return parsedBusinessHours;
  };

  simulationData.units = simulationData.units?.map((unit) => {
    const businessHours = unit.businessHours;
    if (businessHours) {
      businessHours.businessDays = parseBusinessHours(businessHours.businessDays);

      if (isBusinessHoursEmpty(businessHours.saturday)) businessHours.saturday = null;
      else businessHours.saturday = parseBusinessHours(businessHours.saturday as IBusinessHours);
      if (isBusinessHoursEmpty(businessHours.sunday)) businessHours.sunday = null;
      else businessHours.sunday = parseBusinessHours(businessHours.sunday as IBusinessHours);
    }

    return unit;
  });

  const simulationDataWithManualPricesAndDiscounts = {
    ...simulationData,
    manualDiscountInputs: simulationData.hasManualDiscount
      ? parseFinancialProjectionPayload(simulationData.manualDiscountInputs, true)
      : undefined,
    manualAclPrice: simulationData.hasManualPrice
      ? parseFinancialProjectionPayload(simulationData.manualAclPrice)
      : undefined,
    calculationType: 'month_to_month',
  };

  return transformObjectKeysToSnakeCase(simulationDataWithManualPricesAndDiscounts);
};

export const parseLowTensionSimulatorResult = (
  createSimulationResponse: AxiosResponse<IResponseBase<any>>,
): ILowTensionSimulation => {
  const simulatorResult: Record<string, any> = createSimulationResponse.data.data;
  const responseWithOrderedAclResults: Record<string, any> = {
    ...simulatorResult,
    first_result: simulatorResult.first_result,
    second_result: simulatorResult.second_result,
    third_result: simulatorResult.third_result,
  };

  return transformObjectKeysToCamelCase(responseWithOrderedAclResults) as ILowTensionSimulation;
};

export const parseLowTensionProposal = (proposal: Record<string, any>): ILowTensionProposal => {
  const camelizedProposal = transformObjectKeysToCamelCase(proposal) as ILowTensionProposal;
  return {
    ...camelizedProposal,
    calculatorMetadata: {
      ...camelizedProposal.calculatorMetadata,
      aclType: camelizedProposal.inputs.aclType,
    },
  };
};

export const parseLowTensionProposals = (proposals: Record<string, any>): ILowTensionProposals => {
  return transformObjectKeysToCamelCase(proposals) as ILowTensionProposals;
};
