import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { GenericFormField } from '../../fields/generic-form-field';
import { IManagementContractPayload } from '@contexts/management-contract/types';

interface IContractUnitGeneralSubsection extends ISimulatorFormUnitSection {
  unitNickname?: string;
}

export function ContractUnitGeneralSubsection({ unitNum, fieldsController }: IContractUnitGeneralSubsection) {
  return (
    <>
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.name`}
        field={`inputs.units.${unitNum}.name`}
        label="Nome da UC"
      />
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.address`}
        field={`inputs.units.${unitNum}.address`}
        label="Endereço da UC"
      />
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.installationNumber`}
        field={`inputs.units.${unitNum}.installationNumber`}
        label="Número da Instalação elétrica da UC"
      />
    </>
  );
}
