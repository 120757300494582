const FreeMarketTableColumns = [
  {
    key: 'FreeMarketquantity',
    label: 'Quantidade',
    render: (value: any): string => value,
  },
  {
    key: 'FreeMarkettariff',
    label: 'Tarifa',
    render: (value: any): string => value,
  },
  {
    key: 'FreeMarkettotal',
    label: 'Total',
    render: (value: any): string => value,
  },
];

export default FreeMarketTableColumns;
