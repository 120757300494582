import React from 'react';

import barcode from '@assets/images/barcode.png';

import './style.css';

interface Column {
  key?: string;
  label?: string;
  render: (value?: any) => any;
}

export interface IBillGeneralData {
  title: string;
  groupName: string;
  unitName: string;
}

interface ITableProps {
  columns: Column[] | any[];
  data: any[];
  billGeneralData: IBillGeneralData;
  showBarcode?: boolean;
  dashedItemsIndexes?: number[];
  id?: string;
}

const BillComponent: React.FC<ITableProps> = ({
  data,
  id,
  columns,
  billGeneralData,
  showBarcode,
  dashedItemsIndexes,
}: ITableProps) => {
  const rowClassname = (index: number) => {
    if (index === data.length - 1) {
      return 'bill-table-body-box-highlight';
    } else if (dashedItemsIndexes?.find((e) => e === index)) {
      return 'bill-dashed-table-body';
    } else {
      return 'bill-table-body-box';
    }
  };

  return (
    <div className="bill-table-section">
      <div className="bill-general-data">
        <div className="bill-general-data-title">{billGeneralData.title}</div>
        <div className="bill-general-data-grid">
          <div className="bill-general-data-column">
            <div className="bill-general-data-normal-text">{billGeneralData.groupName}</div>
            <div className="bill-general-data-normal-text">Unidade: {billGeneralData.unitName}</div>
          </div>
        </div>
      </div>
      <table id={id} className="bill-default-table">
        <thead className="bill-default-table-header">
          <tr className="bill-table-head-box">
            {columns.map((columnName, index) => (
              <>
                {columnName.label && (
                  <th key={index.toString()} scope="col" className="bill-table-head-title">
                    {columnName.label}
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody className="bill-default-table-body">
          {data &&
            data.map((item, index) => (
              <tr key={index.toString()} className={rowClassname(index)}>
                {columns.map((column, columnIndex) =>
                  column.key ? (
                    <td
                      key={columnIndex.toString()}
                      className={`bill-table-body-title ${
                        index === data.length - 1 && 'bill-table-body-title-highlight'
                      }`}
                    >
                      {column.render(item[column.key])}
                    </td>
                  ) : (
                    column.render(item)
                  ),
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {showBarcode && (
        <div className="barcode">
          <img src={barcode} />
        </div>
      )}
    </div>
  );
};

export default BillComponent;
