import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { RadioButton, Tooltip } from '@clarke-energia/foton';

import { IGeneralFormSelectableInputsOptions } from '../types';

export interface RadioFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  inputOptions: Array<IGeneralFormSelectableInputsOptions>;
  label: string;
  options?: RegisterOptions;
  tooltipContent?: string;
}

export function RadioFormField<T extends FieldValues>({
  field,
  inputOptions,
  label,
  options,
  tooltipContent,
}: RadioFormFieldProps<T>) {
  const { control, watch } = useFormContext<T>();
  const formValue = watch(field);

  return (
    <div className="flex flex-col pt-4 space-y-3">
      <div className="flex gap-2 mb-2">
        <label className="text-paragraph-medium">
          {label}
          {options?.required && <span className="text-danger-60">*</span>}
        </label>
        {tooltipContent && <Tooltip content={tooltipContent} />}
      </div>
      <div className="flex gap-6 flex-wrap">
        {inputOptions.map(({ id, value, optionLabel, defaultChecked }, index) => (
          <Controller
            key={id}
            control={control}
            name={field}
            rules={options}
            render={({ field: { name, onBlur, onChange } }) => (
              <RadioButton
                checked={value === String(formValue) || defaultChecked}
                disabled={options?.disabled}
                id={id}
                label={optionLabel}
                name={name}
                onBlur={onBlur}
                onChange={options?.onChange ?? onChange}
                required={(options?.required as boolean) || false}
                value={value}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
}
