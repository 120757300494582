import YellowFlag from '@assets/images/yellow-flag.svg';

const CaptiveMarketTableHeaders = (month: any, year: any) => [
  {
    label: `Fatura ${month} ${year}`,
    icon: null,
    columnsRange: 1,
  },
  {
    label: 'Simulação Mercado Cativo',
    icon: YellowFlag,
    columnsRange: 3,
  },
];

export default CaptiveMarketTableHeaders;
