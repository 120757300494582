import { ILeadProposalsHubBase } from '@contexts/types';
import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';

import useFeasibilityAnalysis from '@hooks/use-feasibility-analysis';
import useLeadProposals from '@hooks/use-lead-proposals';

import { LeadProposalsHubContainer } from '@components/atoms/general/lead-proposals-hub/container';
import LeadProposalsHubHeader from '@components/molecules/general/lead-proposals-hub/header';
import FeasibilityAnalysisLeadProposalsHubHistory from '@components/molecules/feasibility-analysis/lead-proposals-hub/history';
import FeasibilityAnalysisProposalPreviewBody from '@components/molecules/feasibility-analysis/lead-proposals-hub/body';

const FeasibilityAnalysisLeadProposalsHub = ({
  setIsDrawerOpen,
  selectedProposalId,
  setSelectedProposalId,
}: ILeadProposalsHubBase): JSX.Element => {
  const { getFeasibilityAnalysisProposals, deleteFeasibilityAnalysisProposalsHandler } = useFeasibilityAnalysis();
  const { feedbackNotificationsContent, loading, leadProposals, leadId, alertMessage, onClickDeleteProposal } =
    useLeadProposals<IFeasibilityAnalysisProposal>(
      getFeasibilityAnalysisProposals,
      deleteFeasibilityAnalysisProposalsHandler,
    );

  const mostRecentProposal = leadProposals[0];
  return (
    <LeadProposalsHubContainer setIsDrawerOpen={setIsDrawerOpen} loading={loading} alertMessage={alertMessage}>
      <>
        <LeadProposalsHubHeader
          titleContent={mostRecentProposal?.lead.name}
          createdAt={mostRecentProposal?.createdAt}
        />
        <FeasibilityAnalysisProposalPreviewBody
          leadProposal={mostRecentProposal}
          leadId={leadId}
          selectedProposalId={selectedProposalId}
          setSelectedProposalId={setSelectedProposalId}
          onClickDeleteProposal={onClickDeleteProposal}
          feedbackNotificationsContent={feedbackNotificationsContent}
          isMainContent
        />
        {leadProposals.length > 1 && (
          <FeasibilityAnalysisLeadProposalsHubHistory
            selectedProposalId={selectedProposalId}
            leadId={leadId}
            setSelectedProposalId={setSelectedProposalId}
            proposalsList={leadProposals.slice(1)}
            onClickDeleteProposal={onClickDeleteProposal}
            feedbackNotificationsContent={feedbackNotificationsContent}
          />
        )}
      </>
    </LeadProposalsHubContainer>
  );
};

export default FeasibilityAnalysisLeadProposalsHub;
