import React from 'react';

import { IFeasibilityAnalysisSimulatorResult } from '@pages/feasibility-analysis/simulator/feasibility-analysis-simulator-result';

import FeasibilityAnalysisSimulatorResultEconomy from '@components/molecules/feasibility-analysis/simulator/feasibility-analysis-simulator-result-economy';
import { SimulatorResultFooter } from '@components/molecules/general/simulation-calculator-result-footer';

import { FEASIBILITY_ANALYSIS_PATH } from '@routers/constants';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';

interface IFeasibilityAnalysisSimulatorResultCore extends IFeasibilityAnalysisSimulatorResult {
  simulationId?: string;
  loading?: boolean;
  errorMessage?: string;
  leadName?: string;
  sendFeasibilityAnalysis: () => void;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FeasibilityAnalysisSimulatorResultCore = ({
  simulatorResult,
  simulatorInputs,
  simulationId,
  loading,
  errorMessage,
  leadName,
  setErrorMessage,
  sendFeasibilityAnalysis,
}: IFeasibilityAnalysisSimulatorResultCore) => {
  return (
    <SimulatorLayoutBase
      title="Resultado de Simulação"
      simulatorType="FEASIBILITY_ANALYSIS"
      simulatorFlow="SIMULATOR_RESULT"
    >
      <div className="col-span-full w-full">
        <FeasibilityAnalysisSimulatorResultEconomy {...{ simulatorResult }} />
        <SimulatorResultFooter
          simulatorType="FEASIBILITY_ANALYSIS"
          simulationId={simulationId}
          generateSimulation={sendFeasibilityAnalysis}
          leadName={leadName}
          calculationInputs={simulatorInputs}
          simulatorPath={FEASIBILITY_ANALYSIS_PATH}
          {...{ loading, errorMessage, setErrorMessage }}
        />
      </div>
    </SimulatorLayoutBase>
  );
};

export default FeasibilityAnalysisSimulatorResultCore;
