import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ILowTensionSimulatorInputs } from '@contexts/low-tension/types';

import SimulatorFormGeneralSection from '@components/molecules/form/simulator/sections/simulator-form-general-section';
import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';
import SimulatorFormTargetYearsSection from '@components/molecules/form/simulator/sections/simulator-form-target-years-section';
interface ILowTensionSimulatorFormCore {
  formController: UseFormReturn<ILowTensionSimulatorInputs>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: ILowTensionSimulatorInputs) => void;
}

const LowTensionSimulatorFormCore = ({ formController, formRef, onSubmit }: ILowTensionSimulatorFormCore) => {
  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormGeneralSection<ILowTensionSimulatorInputs>
        fields={{
          productType: true,
          aclType: false,
        }}
      />
      <SimulatorFormTargetYearsSection outputAsArray />
      <SimulatorFormUnitsSection<ILowTensionSimulatorInputs>
        fieldsController={{
          powerGeneratorCost: false,
          tariff: false,
          demand: false,
          lowTensionType: true,
          businessHours: true,
          companyType: true,
        }}
        unifiedConsumption
      />
    </form>
  );
};

export default LowTensionSimulatorFormCore;
