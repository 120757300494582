import { useEffect } from 'react';
import { NavigationLinkProps } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';

import {
  COMPARISON_BILL_PATH,
  CONTRACTED_DEMAND_PATH,
  FEASIBILITY_ANALYSIS_PATH,
  CLARKE_MANAGEMENT_PATH,
  GENERAL_CALCULATOR_PATH,
  GENERAL_LIST_PATH,
  HOME_PATH,
  CLARKE_RETAIL_PATH,
  LOW_TENSION_PATH,
  WHITE_LABEL_PATH,
  GENERAL_SUPPLIERS_PATH,
  UPSERT_SUPPLIERS_PROJECTION_PATH,
} from '@routers/constants';
import { useAuth } from '@src/auth-wrapper';

const generateSimulatorNavigationLinkProps = (
  label: string,
  iconName: NavigationLinkProps['iconName'],
  simulatorPath: string,
  isMenuCollapsed: boolean,
  description?: string,
): NavigationLinkProps => ({
  label,
  iconName,
  path: `${simulatorPath}/${GENERAL_CALCULATOR_PATH}`,
  sublinks: !isMenuCollapsed
    ? [
        {
          label: 'Simulador',
          path: `${simulatorPath}/${GENERAL_CALCULATOR_PATH}`,
          iconName: 'CalculatorIcon',
        },
        {
          label: 'Histórico',
          path: `${simulatorPath}/${GENERAL_LIST_PATH}`,
          iconName: 'Bars3Icon',
        },
      ]
    : undefined,
});
const defaultNavigationLinks = (isMenuCollapsed: boolean): Array<NavigationLinkProps> => {
  return [
    { label: 'Home', path: HOME_PATH, iconName: 'HomeIcon' },
    generateSimulatorNavigationLinkProps(
      'Clarke Gestão',
      'BuildingOffice2Icon',
      CLARKE_MANAGEMENT_PATH,
      isMenuCollapsed,
    ),
    generateSimulatorNavigationLinkProps(
      'Clarke Varejo',
      'BuildingStorefrontIcon',

      CLARKE_RETAIL_PATH,
      isMenuCollapsed,
    ),
    generateSimulatorNavigationLinkProps(
      'Análise de Viabilidade',
      'ChartBarIcon',
      FEASIBILITY_ANALYSIS_PATH,
      isMenuCollapsed,
    ),
    generateSimulatorNavigationLinkProps(
      'Comparativo de Contas',
      'BanknotesIcon',
      COMPARISON_BILL_PATH,
      isMenuCollapsed,
    ),
    // TODO Adjust low tension simulation and include simulator again at menu
    // generateSimulatorNavigationLinkProps(
    //   'Baixa Tensão',
    //   'AdjustmentsHorizontalIcon',
    //   LOW_TENSION_PATH,
    //   isMenuCollapsed,
    // ),
    {
      label: 'Demanda Contratada',
      path: `${CONTRACTED_DEMAND_PATH}/${GENERAL_CALCULATOR_PATH}`,
      iconName: 'BoltIcon',
    },
  ];
};

export const generateSidebarNavigationLinksPropsForAdmin = (isMenuCollapsed: boolean): Array<NavigationLinkProps> => {
  return defaultNavigationLinks(isMenuCollapsed);
};

export const generateSidebarNavigationLinksPropsForSuperAdmin = (
  isMenuCollapsed: boolean,
): Array<NavigationLinkProps> => {
  return [
    ...defaultNavigationLinks(isMenuCollapsed),
    {
      label: 'White Label',
      path: `${WHITE_LABEL_PATH}/${GENERAL_SUPPLIERS_PATH}`,
      iconName: 'DocumentPlusIcon',
    },
  ];
};

export const generateSidebarNavigationLinksPropsForCommercialDealer = (
  isMenuCollapsed: boolean,
): Array<NavigationLinkProps> => {
  return [
    { label: 'Home', path: HOME_PATH, iconName: 'HomeIcon' },

    generateSimulatorNavigationLinkProps(
      'Clarke Varejo',
      'BuildingStorefrontIcon',
      CLARKE_RETAIL_PATH,
      isMenuCollapsed,
      'Experiência do mercado atual, com a economia do mercado livre. As características são pré-definidas de acordo com os fornecedores.',
    ),

    generateSimulatorNavigationLinkProps(
      'Clarke Gestão',
      'BuildingStorefrontIcon',
      CLARKE_MANAGEMENT_PATH,
      isMenuCollapsed,
      'Experiência do mercado atual, com a economia do mercado livre. As características são pré-definidas de acordo com os fornecedores.',
    ),
  ];
};
export const generateSidebarNavigationLinksPropsForWhiteLabel = (
  isMenuCollapsed: boolean,
): Array<NavigationLinkProps> => {
  const { user } = useAuth();
  const { whiteLabelSupplier, getWhiteLabelSupplierByUserId } = useWhiteLabel();

  useEffect(() => {
    if (user) {
      user?.isWhiteLabel && getWhiteLabelSupplierByUserId(user.id);
    }
  }, [user]);

  return [
    { label: 'Home', path: HOME_PATH, iconName: 'HomeIcon' },
    {
      label: 'Editar Fornecedora',
      path: `${WHITE_LABEL_PATH}/${GENERAL_SUPPLIERS_PATH}${UPSERT_SUPPLIERS_PROJECTION_PATH}/${whiteLabelSupplier?.id}`,
      iconName: 'BuildingOffice2Icon',
    },
    generateSimulatorNavigationLinkProps(
      'Simulador de Economia',
      'BuildingStorefrontIcon',
      CLARKE_MANAGEMENT_PATH,
      isMenuCollapsed,
      'Experiência do mercado atual, com a economia do mercado livre. As características são pré-definidas de acordo com os fornecedores.',
    ),
  ];
};
