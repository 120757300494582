import styled from 'styled-components';

export const TitleContainer = styled.div.attrs({
  className: 'w-full h-fit grid grid-cols-1 p-4',
})``;

export const NormalTitle = styled.h2.attrs({
  className: 'text-5xl font-normal inline',
})``;

export const BoldTitle = styled.h2.attrs({
  className: 'text-6xl mt-4 leading-tight font-semibold inline',
})``;

export const GridBody = styled.div.attrs({
  className: 'w-full grid grid-cols-8 justify-between h-full',
})``;

export const GridColumn = styled.div.attrs({
  className: 'w-full grid col-span-4 justify-between h-full p-6',
})``;

export const ClarkeLogoTopLeftCorner = styled.img.attrs({
  className: 'w-2/4 col-span-full pl-4',
})``;

export const IllustrationImage = styled.img.attrs({
  className: 'w-full h-full object-cover object-center border-1 border-solid border-black',
})``;

export const GridContainerGreen = styled.div.attrs({
  className: 'w-full grid grid-cols-1 justify-between h-full bg-primary-60',
})``;
