import { useContextSelector } from 'use-context-selector';

import { LowTensionContext } from '@contexts/low-tension';
import { ILowTensionContext } from '@contexts/low-tension/types';

const useLowTension = (): ILowTensionContext => {
  const createLowTensionSimulation = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.createLowTensionSimulation,
  );
  const createLowTensionProposal = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.createLowTensionProposal,
  );
  const getLowTensionProposals = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.getLowTensionProposals,
  );
  const getLowTensionProposalById = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.getLowTensionProposalById,
  );
  const deleteLowTensionProposalsHandler = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.deleteLowTensionProposalsHandler,
  );
  const deleteLowTensionAllProposalsByLeadIdHandler = useContextSelector(
    LowTensionContext,
    (sales) => sales.deleteLowTensionAllProposalsByLeadIdHandler,
  );
  const updateSellerOfLowTensionProposal = useContextSelector(
    LowTensionContext,
    (lowTension) => lowTension.updateSellerOfLowTensionProposal,
  );

  return {
    createLowTensionSimulation,
    createLowTensionProposal,
    getLowTensionProposals,
    getLowTensionProposalById,
    deleteLowTensionProposalsHandler,
    deleteLowTensionAllProposalsByLeadIdHandler,
    updateSellerOfLowTensionProposal,
  };
};

export default useLowTension;
