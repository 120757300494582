import React from 'react';

import { IComparisonBillSimulationProps } from '@pages/comparison-bill/simulator/comparison-bill-simulator-result';

import ComparisonBillSimulatorResultItem from '@components/molecules/comparison-bill/simulator/comparison-bill-simulator-result-item';

interface IComparisonBillSimulationCore extends IComparisonBillSimulationProps {
  energyDistributionCompany?: string;
}

const ComparisonBillSimulatorResultCore = ({
  simulatorResult,
  energyDistributionCompany,
}: IComparisonBillSimulationCore) => {
  return (
    <div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Unidade {simulatorResult?.unit.nickname}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Grupo Comercial</p>
      </div>
      <div className="mt-5 border-t border-gray-200 mb-12">
        <dl className="sm:divide-y sm:divide-gray-200">
          <ComparisonBillSimulatorResultItem
            label="Antes: Mercado Cativo"
            description={`Distribuição + Energia (${energyDistributionCompany})`}
            value={simulatorResult?.tariffFlagScenarios.default.sampleMonthSimulation.currentMarket.totalWithTaxes}
          />
          <ComparisonBillSimulatorResultItem
            label="Depois: Mercado Livre"
            description={`Distribuição (${energyDistributionCompany}) + Energia (Fornecedor ACL)`}
            value={simulatorResult?.tariffFlagScenarios.default.sampleMonthSimulation.targetMarket.totalWithTaxes}
          />
          <ComparisonBillSimulatorResultItem
            label="Economia"
            description="Total Economizado (com taxas)"
            value={simulatorResult?.tariffFlagScenarios.default.sampleMonthSimulation.report.totalSavingWithTaxes}
          />
        </dl>
      </div>
    </div>
  );
};

export default ComparisonBillSimulatorResultCore;
