import React from 'react';

const UnitsResumeTableColumns = [
  {
    key: 'unit',
    label: 'Unidade',
    render: (value: any): string => value,
  },
  {
    key: 'acrDemmandPeak',
    label: 'Demanda Cativo P (kW)',
    render: (value: any): string => value,
  },
  {
    key: 'acrDemmandOffPeak',
    label: 'Demanda Cativo FP (kW)',
    render: (value: any): string => value,
  },
  {
    key: 'aclDemmandPeak',
    label: 'Demanda Livre P (kW)',
    render: (value: any): string => value,
  },
  {
    key: 'aclDemmandOffPeak',
    label: 'Demanda Livre FP (kW)',
    render: (value: any): string => value,
  },
  {
    key: 'economyWithInitialExpenses',
    label: 'Economia Total',
    render: (value: any): React.FC => value,
  },
];

export default UnitsResumeTableColumns;
