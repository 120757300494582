import React from 'react';

import clarkeLogo from '@assets/images/clarke-black-horizontal.png';
import energyTowerImage from '@assets/images/energy-tower.png';

import { FlexItemsEnd } from '@components/molecules/general/simulation-styled-components';

import {
  GridBody,
  GridContainerGreen,
  EnergyTowerIllustration,
  GridColumn,
  TitleContainer,
  NormalTitle,
  BoldTitle,
  ClarkeLogoHorizontal,
  BlackHeaderLine,
} from './style';

interface ICover {
  companyName?: string;
}

const Cover = ({ companyName }: ICover): JSX.Element => (
  <GridContainerGreen>
    <GridBody>
      <GridColumn>
        <BlackHeaderLine />
        <TitleContainer>
          <NormalTitle>{companyName}:</NormalTitle>
          <BoldTitle>Conta de luz no Regulado x Livre</BoldTitle>
        </TitleContainer>
        <FlexItemsEnd>
          <ClarkeLogoHorizontal src={clarkeLogo} alt="clarke-logo" />
        </FlexItemsEnd>
      </GridColumn>
      <GridColumn>
        <EnergyTowerIllustration src={energyTowerImage} />
      </GridColumn>
    </GridBody>
  </GridContainerGreen>
);

export default Cover;
