import React from 'react';

import { IFeasibilityAnalysisSimulation } from '@contexts/feasibility-analysis/types';

import { SlideContainerGreater } from '@components/organisms/feasibility-analysis/simulation/styles';
import FeasibilityAnalysisProposalSectionBase from '@components/molecules/feasibility-analysis/simulation/section-base';

interface IFeasibilityAnalysisSimulationGroupEconomySection {
  hasMultipleUnits?: boolean;
  simulationResult?: IFeasibilityAnalysisSimulation;
  leadName?: string;
}

const FeasibilityAnalysisSimulationGroupEconomySection = ({
  hasMultipleUnits,
  simulationResult,
  leadName,
}: IFeasibilityAnalysisSimulationGroupEconomySection) => {
  return (
    <>
      {hasMultipleUnits && simulationResult && leadName && (
        <SlideContainerGreater>
          <FeasibilityAnalysisProposalSectionBase
            leadName={leadName}
            simulationResult={simulationResult}
            generalAnalysis
          />
        </SlideContainerGreater>
      )}
    </>
  );
};

export default FeasibilityAnalysisSimulationGroupEconomySection;
