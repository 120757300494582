import React from 'react';
import numeral from 'numeral';

import { IFeasibilityAnalysisSimulationTariffFlagScenarios } from '@contexts/feasibility-analysis/types';

import YellowFlag from '@assets/images/yellow-flag.svg';

import TableWithSuperheader from '@components/molecules/general/table-with-superheader';
import EconomyCard from '@components/atoms/general/economy-card';
import GridElements from '@components/atoms/general/grid-elements';

import GeneralInformationsTableColumns from './general-informations-table/columns';
import GeneralInformationsTableHeaders from './general-informations-table/headers';

import PremisesTableColumns from './premises-table/columns';
import PremisesTableHeaders from './premises-table/headers';

import FreeMarketPricesTableColumns from './free-market-prices-table/columns';
import FreeMarketPricesTableHeaders from './free-market-prices-table/headers';

export interface IFeasibilityAnalysisProposalSectionBaseSideSubsection {
  freeMarketPricesTableData?: Array<Record<string, string>>;
  generalDataTableData?: Array<Record<string, string>>;
  premisesTableData?: Array<Record<string, string>>;
  tariffFlagScenarios?: IFeasibilityAnalysisSimulationTariffFlagScenarios;
  targetYear?: string | number;
  generalAnalysis?: boolean;
}

const FeasibilityAnalysisProposalSectionBaseSideSubsection = ({
  freeMarketPricesTableData,
  generalDataTableData,
  premisesTableData,
  tariffFlagScenarios,
  targetYear,
  generalAnalysis,
}: IFeasibilityAnalysisProposalSectionBaseSideSubsection) => {
  return (
    <div className="flex flex-col lg:flex-row w-full lg:w-[50%] gap-5">
      <div className="flex flex-col gap-5">
        {generalDataTableData && (
          <TableWithSuperheader
            gridElements
            columns={GeneralInformationsTableColumns}
            tableHeaders={GeneralInformationsTableHeaders}
            data={[<GridElements key="generalDataTableData" data={generalDataTableData} />]}
          />
        )}
        {premisesTableData && (
          <TableWithSuperheader
            gridElements
            columns={PremisesTableColumns}
            tableHeaders={PremisesTableHeaders}
            data={[<GridElements key="premisesTableData" data={premisesTableData} />]}
          />
        )}
        {freeMarketPricesTableData && (
          <TableWithSuperheader
            gridElements
            columns={FreeMarketPricesTableColumns}
            tableHeaders={FreeMarketPricesTableHeaders}
            data={[<GridElements key="freeMarketPricesTableData" data={freeMarketPricesTableData} />]}
          />
        )}
        {tariffFlagScenarios && (
          <>
            <EconomyCard
              CardTitle={
                <div className="flex flex-row justify-between items-center w-full">
                  <h2 className="text-xs sm:text-sm">
                    Economia {tariffFlagScenarios?.yellow.sampleMonthSimulation.report.monthLabel}{' '}
                    {tariffFlagScenarios?.yellow.sampleMonthSimulation.report.year}
                  </h2>
                  <img className="h-6" src={YellowFlag} />
                </div>
              }
              valueSaving={`${numeral(
                Math.round(tariffFlagScenarios?.yellow.sampleMonthSimulation.report.totalSavingWithTaxes),
              ).format('$ 0,0')}`}
              valueSavingPercentage={`${numeral(
                tariffFlagScenarios?.yellow.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes,
              ).format('0,0%')}`}
            />
            <EconomyCard
              CardTitle={
                <h2 className="text-xs sm:text-sm">
                  Economia em {targetYear} ano(s) <span className="text-green-500">*</span>
                </h2>
              }
              valueSaving={`${numeral(Math.round(tariffFlagScenarios?.default.totalSavingWithInitialExpenses)).format(
                '$ 0,0',
              )}`}
              valueSavingPercentage={`${numeral(
                tariffFlagScenarios?.default[
                  // @ts-expect-error: provisory threatment to backend-generated ambiguous types
                  generalAnalysis
                    ? 'totalSavingWithInitialExpensesInPercentage'
                    : 'totalSavingInPercentageWithInitialExpenses'
                ],
              ).format('% 0,0')}`}
            />
          </>
        )}

        <p>
          <span className="text-green-500">*</span> Para a projeção de economia consideramos as seguintes bandeiras
          tarifárias ano a ano: Verde (Outubro até Abril), Amarela (Maio e Setembro), Vermelha P1 (Junho e Agosto) e
          Vermelha P2 (Julho)
        </p>
      </div>
    </div>
  );
};

export default FeasibilityAnalysisProposalSectionBaseSideSubsection;
