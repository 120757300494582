import { ILeadProposalsHubBase } from '@contexts/types';
import { ILowTensionProposal } from '@contexts/low-tension/types';

import useLowTension from '@hooks/use-low-tension';
import useLeadProposals from '@hooks/use-lead-proposals';

import { LeadProposalsHubContainer } from '@components/atoms/general/lead-proposals-hub/container';
import LeadProposalsHubHeader from '@components/molecules/general/lead-proposals-hub/header';
import LowTensionLeadProposalsHubHistory from '@components/molecules/low-tension/lead-proposals-hub/history';
import LowTensionProposalPreviewBody from '@components/molecules/low-tension/lead-proposals-hub/body';

const LowTensionLeadProposalsHub = ({
  setIsDrawerOpen,
  selectedProposalId,
  setSelectedProposalId,
}: ILeadProposalsHubBase): JSX.Element => {
  const { getLowTensionProposals, deleteLowTensionProposalsHandler } = useLowTension();
  const { feedbackNotificationsContent, loading, leadProposals, leadId, alertMessage, onClickDeleteProposal } =
    useLeadProposals<ILowTensionProposal>(getLowTensionProposals, deleteLowTensionProposalsHandler);

  const mostRecentProposal = leadProposals[0];

  return (
    <LeadProposalsHubContainer setIsDrawerOpen={setIsDrawerOpen} loading={loading} alertMessage={alertMessage}>
      <>
        <LeadProposalsHubHeader
          titleContent={mostRecentProposal?.lead.name}
          createdAt={mostRecentProposal?.createdAt}
        />
        <LowTensionProposalPreviewBody
          leadProposal={mostRecentProposal}
          leadId={leadId}
          selectedProposalId={selectedProposalId}
          setSelectedProposalId={setSelectedProposalId}
          onClickDeleteProposal={onClickDeleteProposal}
          feedbackNotificationsContent={feedbackNotificationsContent}
          isMainContent
        />
        {leadProposals.length > 1 && (
          <LowTensionLeadProposalsHubHistory
            leadId={leadId}
            selectedProposalId={selectedProposalId}
            setSelectedProposalId={setSelectedProposalId}
            proposalsList={leadProposals.slice(1)}
            onClickDeleteProposal={onClickDeleteProposal}
            feedbackNotificationsContent={feedbackNotificationsContent}
          />
        )}
      </>
    </LeadProposalsHubContainer>
  );
};

export default LowTensionLeadProposalsHub;
