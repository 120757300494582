const parseFreeMarketBillMinimalTableData = (
  totalDistribution: number | undefined,
  totalEnergy: number | undefined,
  total: number | undefined,
) => {
  return [
    {
      item: '1. Fatura de Distribuição',
      value: `${totalDistribution}`,
    },
    {
      item: '2. Fatura de Energia',
      value: `${totalEnergy}`,
    },
    {
      item: 'Total Distribuição + Energia',
      value: `${total}`,
    },
  ];
};

export default parseFreeMarketBillMinimalTableData;
