import React from 'react';

import { ILowTensionProposal } from '@contexts/low-tension/types';
import useLowTension from '@hooks/use-low-tension';

import LowTensionLeadProposalsHub from '@components/organisms/low-tension/lead-proposals-hub';
import LeadsHistory from '@components/organisms/general/leads-history';

const LowTensionLeadsHistory = (): JSX.Element => {
  const { deleteLowTensionAllProposalsByLeadIdHandler } = useLowTension();

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  return (
    <LeadsHistory<ILowTensionProposal>
      simulatorType="LOW_TENSION"
      deleteAllProposalsByLeadIdHandler={deleteLowTensionAllProposalsByLeadIdHandler}
      selectedLeadId={selectedLeadId}
      setSelectedLeadId={setSelectedLeadId}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <>
        {isDrawerOpen && selectedLeadId && (
          <LowTensionLeadProposalsHub
            selectedProposalId={selectedLeadId}
            setSelectedProposalId={setSelectedLeadId}
            setIsDrawerOpen={setIsDrawerOpen}
            leadId={selectedLeadId}
          />
        )}
      </>
    </LeadsHistory>
  );
};

export default LowTensionLeadsHistory;
