import React from 'react';

import MinimalTable from '@components/atoms/general/minimal-table';
import { FlexItemsEnd } from '@components/molecules/general/simulation-styled-components';

import BillComponent, { IBillGeneralData } from '../bill-component';

import {
  GridBody,
  GridContainer,
  BillBox,
  EnergyBill,
  BoldTitle,
  GreenHeaderLine,
  Description,
  GridColumnBigger,
  GridColumnLower,
} from './style';
import columnsFreeMarketEnergyBill from './free-market-energy-bill/columns';
import columnsFreeMarketDistributionBill from './free-market-distribution-bill/columns';
import columnsMinimalTable from './minimal-table/columns';

export interface IFreeMarketBill {
  aclMinimalTableData?: Record<string, any>[];
  distributionFreeMarketBillData?: Record<string, any>[];
  distributionFreeMarketBillGeneralData?: IBillGeneralData;
  energyFreeMarketBillData?: Record<string, any>[];
  energyFreeMarketBillGeneralData?: IBillGeneralData;
}

const FreeMarketBill = ({
  aclMinimalTableData,
  distributionFreeMarketBillData,
  distributionFreeMarketBillGeneralData,
  energyFreeMarketBillData,
  energyFreeMarketBillGeneralData,
}: IFreeMarketBill): JSX.Element => {
  return (
    <GridContainer id="aclBillSection">
      <GridBody>
        <GridColumnLower>
          <GreenHeaderLine />
          <BoldTitle>
            Conta depois: <br /> Mercado Livre
          </BoldTitle>
          <Description></Description>
          <FlexItemsEnd>
            {aclMinimalTableData && (
              <MinimalTable
                data={aclMinimalTableData}
                columns={columnsMinimalTable}
                lastRowHighlight
                id="aclMinimalTable"
              />
            )}
          </FlexItemsEnd>
        </GridColumnLower>
        <GridColumnBigger>
          {distributionFreeMarketBillData && distributionFreeMarketBillGeneralData && (
            <BillBox>
              {' '}
              1. &nbsp;
              <BillComponent
                data={distributionFreeMarketBillData}
                columns={columnsFreeMarketDistributionBill}
                billGeneralData={distributionFreeMarketBillGeneralData}
                dashedItemsIndexes={[3, 4, 5]}
                id="aclDistributionBill"
              />
            </BillBox>
          )}
          {energyFreeMarketBillData && energyFreeMarketBillGeneralData && (
            <EnergyBill>
              {' '}
              2. &nbsp;
              <BillComponent
                data={energyFreeMarketBillData}
                columns={columnsFreeMarketEnergyBill}
                billGeneralData={energyFreeMarketBillGeneralData}
                showBarcode
                id="aclEnergyBill"
              />
            </EnergyBill>
          )}
        </GridColumnBigger>
      </GridBody>
    </GridContainer>
  );
};

export default FreeMarketBill;
