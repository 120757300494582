import { IEconomyChart } from '.';

const buildChartData = ({
  savingValues,
  targetValues,
  currentValues,
  alternativeBarsColor,
  labels,
}: IEconomyChart) => ({
  labels: labels,
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      'livre-y-axis': {
        display: false,
        grace: 80,
        beginAtZero: true,
        position: 'left',
      },
      'economia-y-axis': {
        display: false,
        grace: 80,
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        ticks: {
          callback(value: any, _index: any, _ticks: any) {
            return `${value} %`;
          },
        },
      },
    },
    plugins: {
      datalabels: {
        padding: 12,
        borderRadius: 30,
        backgroundColor: 'white',
        color: 'black',
        borderWidth: 1,
        borderColor: 'black',
        formatter(value: any) {
          return `${value}%`;
        },
      },
      legend: {
        display: false,
      },
    },
  },
  datasets: [
    {
      yAxisID: 'economia-y-axis',
      type: 'line',
      label: 'Economia (%)',
      borderColor: '#000',
      borderWidth: 2,
      fill: false,
      data: savingValues,
    },
    {
      type: 'bar',
      label: 'Mercado de Origem',
      backgroundColor: alternativeBarsColor ? '#A3F5A7' : '#F2F2F2',
      data: currentValues,
      borderColor: 'white',
      borderWidth: 2,
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
    {
      yAxisID: 'livre-y-axis',
      type: 'bar',
      label: 'Mercado Alvo',
      backgroundColor: alternativeBarsColor ? '#008C4C' : '#00DF7C',
      data: targetValues,
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
  ],
});

export default buildChartData;
