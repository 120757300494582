import React from 'react';
import illustration from '@assets/images/eolica-capa.jpg';
import clarkeLogo from '@assets/images/clarke-black-horizontal.png';

import { FlexItemsEnd } from '@components/molecules/general/simulation-styled-components';

import {
  GridBody,
  GridContainerGreen,
  IllustrationImage,
  GridColumn,
  TitleContainer,
  NormalTitle,
  BoldTitle,
  ClarkeLogoTopLeftCorner,
} from './style';

interface ICover {
  companyName: string;
}

const Cover = ({ companyName }: ICover): JSX.Element => (
  <GridContainerGreen>
    <GridBody>
      <GridColumn>
        <TitleContainer>
          <NormalTitle>Análise de Viabilidade</NormalTitle>
          <BoldTitle>{companyName}</BoldTitle>
        </TitleContainer>
        <FlexItemsEnd>
          <ClarkeLogoTopLeftCorner src={clarkeLogo} alt="clarke-logo" />
        </FlexItemsEnd>
      </GridColumn>
      <GridColumn>
        <IllustrationImage src={illustration} />
      </GridColumn>
    </GridBody>
  </GridContainerGreen>
);

export default Cover;
