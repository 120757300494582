import { Button } from '@clarke-energia/foton';

export interface IContractFormDifferenceSection {
  children?: JSX.Element;
  fieldsController?: Record<string, boolean>;
  removeDifference?: (unitNum: number) => void;
  appendDifference?: () => void;
  differenceNum: number;
  differencesLength?: number;
}
type IIDifferentialsControls = IContractFormDifferenceSection;

export function DifferentialsControls({
  differenceNum,
  removeDifference,
  appendDifference,
  differencesLength = 1,
}: IIDifferentialsControls) {
  const isLastDifference = differencesLength - 1 === differenceNum;
  const isOnlyOneDifference = differencesLength === 1;
  const isOnTheMaxDifferential = differencesLength === 4;

  return (
    <div className="relative mt-3 md:col-span-2">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center gap-8">
        {!isOnlyOneDifference && removeDifference && (
          <Button
            kind="chip"
            label="Remover"
            size="small"
            type="button"
            onClick={() => removeDifference(differenceNum)}
          />
        )}
        {!isOnTheMaxDifferential && isLastDifference && (
          <Button kind="secondary" label="+ Adicionar" size="small" type="button" onClick={appendDifference} />
        )}
      </div>
    </div>
  );
}
