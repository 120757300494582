import { COMPARISON_BILL_PATH } from '@routers/constants';

import { IComparisonBillProposal } from '@contexts/comparison-bill/types';
import { IProposalPreviewBodyBase } from '@contexts/types';
import useComparisonBill from '@hooks/use-comparison-bill';

import ProposalPreviewDescriptionAndUsers from '@components/atoms/general/lead-proposals-hub/proposal-preview-description-and-users';
import LeadProposalsHubBodyWrapper from '@components/molecules/simulations-list/lead-proposals-hub-body-wrapper';

import { ComparisonBillProposalKeyMetricsPanel } from './helper';

interface IComparisonBillProposalPreviewBody extends IProposalPreviewBodyBase {
  leadProposal: IComparisonBillProposal;
}

const ComparisonBillProposalPreviewBody = ({
  leadProposal,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IComparisonBillProposalPreviewBody): JSX.Element => {
  const { updateSellerOfComparisonBillProposal } = useComparisonBill();

  const isProposalComplete = leadProposal?.calculatorMetadata !== undefined;

  return (
    <LeadProposalsHubBodyWrapper
      isProposalComplete={isProposalComplete}
      simulationPreviewFooterProps={{
        simulatorType: 'COMPARISON_BILL',
        simulatorPath: COMPARISON_BILL_PATH,
        simulationId: leadProposal?.id,
        leadName: leadProposal?.id,
        inputsValuesToCopy: leadProposal?.inputs,
        leadId: leadId,
        selectedProposalId: selectedProposalId,
        setSelectedProposalId: setSelectedProposalId,
        onClickDeleteProposal: onClickDeleteProposal,
        feedbackNotificationsContent: feedbackNotificationsContent,
      }}
    >
      <>
        <ProposalPreviewDescriptionAndUsers
          proposalId={leadProposal.id}
          description={leadProposal.inputs.description}
          author={leadProposal.author}
          seller={leadProposal.seller}
          leadId={leadId}
          updateProposalSeller={updateSellerOfComparisonBillProposal}
        />
        <ComparisonBillProposalKeyMetricsPanel
          energyDistributionCompany={leadProposal.calculatorMetadata.unit.energyDistributionCompany}
          currentTotalWithTaxes={
            leadProposal.calculatorMetadata.tariffFlagScenarios.default.sampleMonthSimulation.currentMarket
              .totalWithTaxes
          }
          targetTotalWithTaxes={
            leadProposal.calculatorMetadata.tariffFlagScenarios.default.sampleMonthSimulation.targetMarket
              .totalWithTaxes
          }
          totalSavingWithTaxes={
            leadProposal.calculatorMetadata.tariffFlagScenarios.default.sampleMonthSimulation.report
              .totalSavingWithTaxes
          }
        />
      </>
    </LeadProposalsHubBodyWrapper>
  );
};

export default ComparisonBillProposalPreviewBody;
