import { twMerge } from 'tailwind-merge';

import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';
import { IProposalPreviewBodyBase } from '@contexts/types';
import { FEASIBILITY_ANALYSIS_PATH } from '@routers/constants';

import useFeasibilityAnalysis from '@hooks/use-feasibility-analysis';

import ProposalPreviewDescriptionAndUsers from '@components/atoms/general/lead-proposals-hub/proposal-preview-description-and-users';
import LeadProposalsHubBodyWrapper from '@components/molecules/simulations-list/lead-proposals-hub-body-wrapper';

import { FeasibilityAnalysisProposalKeyMetricsPanel, IFeasibilityAnalysisFlagEconomy } from './helper';

interface IFeasibilityAnalysisProposalPreviewBody extends IProposalPreviewBodyBase {
  leadProposal: IFeasibilityAnalysisProposal;
}

const FeasibilityAnalysisProposalPreviewBody = ({
  leadProposal,
  isMainContent,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IFeasibilityAnalysisProposalPreviewBody): JSX.Element => {
  const { updateSellerOfFeasibilityAnalysisProposal } = useFeasibilityAnalysis();

  const dinamicClass = !isMainContent && 'mx-3';
  const isProposalComplete = leadProposal?.calculatorMetadata !== undefined;

  return (
    <LeadProposalsHubBodyWrapper
      isProposalComplete={isProposalComplete}
      simulationPreviewFooterProps={{
        simulatorType: 'FEASIBILITY_ANALYSIS',
        simulatorPath: FEASIBILITY_ANALYSIS_PATH,
        simulationId: leadProposal?.id,
        leadName: leadProposal?.id,
        inputsValuesToCopy: leadProposal?.inputs,
        leadId: leadId,
        selectedProposalId: selectedProposalId,
        setSelectedProposalId: setSelectedProposalId,
        onClickDeleteProposal: onClickDeleteProposal,
        feedbackNotificationsContent: feedbackNotificationsContent,
      }}
    >
      <>
        <ProposalPreviewDescriptionAndUsers
          proposalId={leadProposal.id}
          description={leadProposal.inputs.description}
          author={leadProposal.author}
          seller={leadProposal.seller}
          leadId={leadId}
          updateProposalSeller={updateSellerOfFeasibilityAnalysisProposal}
        />
        <div className={twMerge('mt-8 border-t-1 border-neutral-30', dinamicClass)}>
          {['green', 'yellow', 'redP1', 'redP2'].map((tariffFlag) => (
            <FeasibilityAnalysisProposalKeyMetricsPanel
              key={`feasibility-analysis-economy-in-flag-${tariffFlag}`}
              simulationData={leadProposal?.calculatorMetadata}
              tariffFlag={tariffFlag as IFeasibilityAnalysisFlagEconomy['tariffFlag']}
            />
          ))}
        </div>
      </>
    </LeadProposalsHubBodyWrapper>
  );
};

export default FeasibilityAnalysisProposalPreviewBody;
