import React from 'react';
import numeral from 'numeral';

import { ILowTensionSimulationResult } from '@contexts/low-tension/types';

import { DescriptionItem } from '@components/atoms/description-item-builder';

interface ILowTensionProposalPreviewEconomyResults {
  simulationResults: Array<ILowTensionSimulationResult | undefined>;
}

const LowTensionProposalPreviewEconomyResults = ({ simulationResults }: ILowTensionProposalPreviewEconomyResults) => {
  return (
    <div>
      {simulationResults.map(
        (result, index) =>
          result && (
            <div
              key={`low-tension-proposal-preview-economy-${index}`}
              className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6 border-b-1 border-neutral-20"
            >
              <DescriptionItem
                title={`Economia em ${result.targetYearGroup} ano(s) (R$)`}
                description={numeral(result.totalSaving).format('$ 0,0.00')}
              />
              <DescriptionItem
                title={`Porcentagem de economia em ${result.targetYearGroup} ano(s) (%)`}
                description={numeral(result.totalSavingPercentage).format('% 0.00')}
              />
              {result.unitsReports.map(({ nickname, premises }) => (
                <div
                  key={`low-tension-proposal-preview-units-reports-${nickname}`}
                  className="col-span-full py-5 sm:grid sm:grid-cols-3 sm:gap-6"
                >
                  <p className="col-span-full text-paragraph-large">Unidade {nickname}</p>
                  <DescriptionItem
                    title="Consumo Ponta (janeiro)"
                    description={numeral(premises.consumptionPeak['1']).format('0,0.00').concat(' kWh')}
                  />
                  <DescriptionItem
                    title="Consumo Fora Ponta (janeiro)"
                    description={numeral(premises.consumptionOffPeak['1']).format('0,0.00').concat(' kWh')}
                  />
                  <DescriptionItem
                    title="Demanda Fora Ponta"
                    description={numeral(premises.aclContractedDemandOffPeak).format('0').concat(' kW')}
                  />
                </div>
              ))}
            </div>
          ),
      )}
    </div>
  );
};

export default LowTensionProposalPreviewEconomyResults;
