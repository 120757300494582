/* eslint-disable react/require-default-props */
import React from 'react';
import './style.css';

export interface Column {
  key?: string;
  label?: string;
  render: (value?: any) => any;
}

export interface TableHeader {
  label?: string;
  icon?: string | null;
  columnsRange: number;
}

interface ITableWithSuperheaderProps {
  columns: Column[] | any[];
  data: any[];
  tableHeaders?: TableHeader[];
  lastRowHighlight?: boolean;
  gridElements?: boolean;
  id?: string;
}

const TableWithSuperheader: React.FC<ITableWithSuperheaderProps> = ({
  data,
  id,
  columns,
  tableHeaders,
  lastRowHighlight,
  gridElements,
}: ITableWithSuperheaderProps) => (
  <div className="w-full">
    <table className="default-table" id={id}>
      <thead className="default-table-header">
        <tr className="table-head-box">
          {tableHeaders
            ? tableHeaders.map((tableHeader, index) => (
                <>
                  {tableHeader.label && (
                    <th
                      key={index.toString()}
                      scope="col"
                      className="table-header-title"
                      colSpan={tableHeader.columnsRange}
                    >
                      <p className="flex flex-row justify-start items-center">
                        <span className="mr-2">{tableHeader.label}</span>
                        {tableHeader.icon && <img className="h-4" alt="flag" src={tableHeader.icon} />}
                      </p>
                    </th>
                  )}
                </>
              ))
            : columns?.map((columnName, index) => (
                <>
                  {columnName.label && (
                    <th key={index.toString()} scope="col" className="table-head-title">
                      {columnName.label}
                    </th>
                  )}
                </>
              ))}
        </tr>
      </thead>
      <tbody className="default-table-body">
        {!gridElements && tableHeaders && (
          <tr className="table-subheader-gray-box">
            {columns?.map((column) => (
              <td key={column.label} className="table-subheader-title">
                <p className="flex flex-row justify-start items-center">
                  <span className="mr-3">{column.label}</span>
                  {column.icon && <img className="h-5" alt="flag" src={column.icon} />}
                </p>
              </td>
            ))}
          </tr>
        )}

        {data &&
          data.map((item, index) => (
            <tr
              key={`item-${index}`}
              className={`${
                lastRowHighlight && index === data.length - 1 ? 'table-body-box-highlight-with-no-bg' : 'table-body-box'
              }`}
            >
              {columns?.map((column, columnIndex) =>
                column.key ? (
                  <td
                    key={columnIndex.toString()}
                    className={`table-body-title text-[12px]${
                      lastRowHighlight &&
                      index === data.length - 1 &&
                      'table-body-title-highlight text-[12px] font-bold'
                    }`}
                  >
                    {column.render(item[column.key])}
                  </td>
                ) : (
                  column.render(item)
                ),
              )}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default TableWithSuperheader;
