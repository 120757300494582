import React from 'react';

interface IDescriptionItem {
  title: string;
  description: string;
}

export const DescriptionItem = ({ title, description }: IDescriptionItem) => (
  <>
    <dt className="text-sm font-medium text-gray-500">{title}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{description}</dd>
  </>
);
