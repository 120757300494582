import { twMerge } from 'tailwind-merge';

import SlideHeader from '@components/atoms/simulation/slide-header';
import NewsletterIcon from '@assets/images/newsletter-icon.png';
import EnergyIcon from '@assets/images/energy-icon.png';
import Configurationicon from '@assets/images/configuration-icon.png';
import clarkeLogo from '@assets/images/clarke-black-horizontal.png';

const marketConditionsCards = [
  {
    isMainCard: true,
    title: null,
    cardContent: [
      {
        title: 'Contrato de gestão',
        iconName: NewsletterIcon,
        value: 'Você é livre para sair sem multa.',
      },
      {
        title: 'Contratação de energia',
        iconName: EnergyIcon,
        value: 'Os melhores preços do mercado, com mais de 50 fornecedores parceiros.',
      },
      {
        title: 'Preço de gestão',
        iconName: Configurationicon,
        value: 'A melhor gestão pelo menor preço.',
      },
    ],
  },
  {
    isMainCard: false,
    title: 'Comercializadora',
    cardContent: [
      {
        title: 'Contrato de gestão',
        iconName: NewsletterIcon,
        value: 'Você fica preso, com contratos de gestão e energia vinculados.',
      },
      {
        title: 'Contratação de energia',
        iconName: EnergyIcon,
        value: 'Tem interesse em vender a própria energia, mesmo mais cara.',
      },
      {
        title: 'Preço de gestão',
        iconName: Configurationicon,
        value: 'Ilusão do ”custo zero” que significa energia mais cara.',
      },
    ],
  },
  {
    isMainCard: false,
    title: 'Gestora Tradicional',
    cardContent: [
      {
        title: 'Contrato de gestão',
        iconName: NewsletterIcon,
        value: 'Você paga multa se quiser sair.',
      },
      {
        title: 'Contratação de energia',
        iconName: EnergyIcon,
        value: 'Pouca diferença de preços, com 3 a 5 fornecedores consultados.',
      },
      {
        title: 'Preço de gestão',
        iconName: Configurationicon,
        value: 'De 3x a 5x mais caro do que a Clarke por unidade.',
      },
    ],
  },
];

interface ICardContent {
  title: string;
  iconName: string;
  value: string;
}

interface ICard {
  isMainCard: boolean;
  title: string | null;
  cardContent: ICardContent[];
}

const Card = ({ cardContent, isMainCard, title }: ICard) => {
  const cardBorder = isMainCard && 'border-primary-60 ';
  const cardOpacity = isMainCard ? 'opacity-100' : 'opacity-50';
  const cardBackgroundColor = isMainCard ? 'bg-white' : 'bg-[#FAFAFA]';
  const cardClassDynamic = [cardBorder, cardOpacity, cardBackgroundColor].join(' ');
  const cardClassFixed = 'col-span-1 rounded-medium h-full bg-red-100';

  const headerColor = isMainCard ? 'bg-primary-60' : 'bg-neutral-10 rounded-t-medium';

  return (
    <div className={twMerge(cardClassFixed, cardClassDynamic)}>
      <div className={twMerge('h-[5.25rem] p-7', headerColor)}>
        {!isMainCard ? (
          <h1 className="text-heading-small font-bold">{title}</h1>
        ) : (
          <img className="h-[1.75rem]" src={clarkeLogo} />
        )}
      </div>
      <div className="p-6 flex flex-col gap-6">
        {cardContent.map(({ title, iconName, value }) => (
          <div key={`product-one-simulation-market-conditions-slide-${title}`} className="flex flex-row gap-4 h-full">
            <img className="h-8 w-8" src={iconName} />
            <div className="flex flex-col">
              <h1 className="text-paragraph-medium text-neutral-50">{title}</h1>
              <h2 className="text-heading-xsmall">{value}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MarketConditions = (): JSX.Element => (
  <div className="flex flex-col w-full">
    <SlideHeader title="Contratar a Clarke é ter liberdade de escolha e acesso às melhores condições do mercado livre de energia" />
    <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-6 px-10 md:px-[9rem] pb-[8.75rem] pt-9 h-fit">
      {marketConditionsCards.map(({ cardContent, isMainCard, title }, idx) => (
        <div key={`card-${idx}`}>
          <Card cardContent={cardContent} isMainCard={isMainCard} title={title} />
        </div>
      ))}
    </div>
  </div>
);

export default MarketConditions;
