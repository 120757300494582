import React from 'react';

import MinimalTable from '@components/atoms/general/minimal-table';
import { FlexItemsEnd } from '@components/molecules/general/simulation-styled-components';

import BillComponent, { IBillGeneralData } from '../bill-component';

import {
  GridBody,
  GridContainer,
  BillBox,
  BoldTitle,
  GreenHeaderLine,
  Description,
  GridColumnBigger,
  GridColumnLower,
} from './style';

import columnsCaptiveMarketBill from './captive-market-bill/columns';
import columnsMinimalTable from './minimal-table/columns';

export interface ICaptiveMarketBill {
  acrMinimalTableData?: Record<string, any>[];
  billData?: Record<string, any>[];
  captiveMarketBillGeneralData?: IBillGeneralData;
}

const CaptiveMarketBill = ({
  acrMinimalTableData,
  billData,
  captiveMarketBillGeneralData,
}: ICaptiveMarketBill): JSX.Element => {
  return (
    <GridContainer id="acrBillSection">
      <GridBody>
        <GridColumnLower>
          <GreenHeaderLine />
          <BoldTitle>
            Conta antes: <br /> Mercado Regulado
          </BoldTitle>
          <Description></Description>
          {acrMinimalTableData && (
            <FlexItemsEnd>
              <MinimalTable
                data={acrMinimalTableData}
                columns={columnsMinimalTable}
                lastRowHighlight
                id="acrMinimalTable"
              />
            </FlexItemsEnd>
          )}
        </GridColumnLower>
        {captiveMarketBillGeneralData && billData && (
          <GridColumnBigger>
            <BillBox>
              <BillComponent
                data={billData}
                columns={columnsCaptiveMarketBill}
                billGeneralData={captiveMarketBillGeneralData}
                showBarcode
                id="acrBill"
              />
            </BillBox>
          </GridColumnBigger>
        )}
      </GridBody>
    </GridContainer>
  );
};

export default CaptiveMarketBill;
