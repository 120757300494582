import numeral from 'numeral';

export const columnsMinimalTable = [
  {
    key: 'item',
    render: (value: string): string => value,
  },
  {
    key: 'value',
    render: (value: string): string => numeral(Number(value).toFixed(2).replace('.', ',')).format('$ 0,0.00'),
  },
];

export default columnsMinimalTable;
