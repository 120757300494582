import { AxiosResponse } from 'axios';

import { thisOrZero, transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';
import { ENERGY_DISTRIBUTION_COMPANIES } from '@utils/constants';

import { IResponseBase, ISimulationPremisesTariff } from '@contexts/types';
import { parseFinancialProjectionPayload } from '@contexts/parser';

import { ICaptiveMarketBill } from '@components/molecules/comparison-bill/simulation/captive-market-bill';
import { IBillGeneralData } from '@components/molecules/comparison-bill/simulation/bill-component';
import { IFreeMarketBill } from '@components/molecules/comparison-bill/simulation/free-market-bill';
import { IComparisonResult } from '@components/molecules/comparison-bill/simulation/comparison-result';
import parseCaptiveMarketBillTableData from '@components/molecules/comparison-bill/simulation/captive-market-bill/captive-market-bill/parser';
import parseCaptiveMarketBillMinimalTableData from '@components/molecules/comparison-bill/simulation/captive-market-bill/minimal-table/parser';
import parseFreeMarketBillMinimalTableData from '@components/molecules/comparison-bill/simulation/free-market-bill/minimal-table/parser';
import ParserFreeMarketEnergyBill from '@components/molecules/comparison-bill/simulation/free-market-bill/free-market-energy-bill/parser';
import ParserFreeMarketDistributionBill from '@components/molecules/comparison-bill/simulation/free-market-bill/free-market-distribution-bill/parser';

import {
  IComparisonBillSimulation,
  IComparisonBillProposal,
  IComparisonBillProposals,
  IComparisonBillSimulatorInputs,
  IComparisonBillSimulatorInputsWithMultipleUnits,
} from './types';

export const parseComparisonBillSimulationPayload = (
  simulationData: IComparisonBillSimulatorInputsWithMultipleUnits,
) => {
  const parsedSimulationData: IComparisonBillSimulatorInputs = {
    unit: simulationData.units?.[0],
    ...simulationData,
    manualDiscountInputs: simulationData.hasManualDiscount
      ? parseFinancialProjectionPayload(simulationData.manualDiscountInputs, true)
      : undefined,
    manualAclPrice: simulationData.hasManualPrice
      ? parseFinancialProjectionPayload(simulationData.manualAclPrice)
      : undefined,
    calculationType: 'month_to_month',
  };

  delete parsedSimulationData.units;
  delete parsedSimulationData.targetYears;
  delete parsedSimulationData.hasManualDiscount;
  delete parsedSimulationData.hasManualPrice;
  delete parsedSimulationData.proposalValidity;
  delete parsedSimulationData.productType;

  return transformObjectKeysToSnakeCase(parsedSimulationData);
};

export const parseComparisonBillSimulatorResult = (
  createSimulationResponse: AxiosResponse<IResponseBase<any>>,
): IComparisonBillSimulation => {
  const simulatorResult: Record<string, any> = createSimulationResponse.data.data;
  return transformObjectKeysToCamelCase(simulatorResult) as IComparisonBillSimulation;
};

export const parseComparisonBillProposalPayload = (simulatorResult: IComparisonBillSimulation): Record<string, any> => {
  const proposalPayload = {
    lead: simulatorResult.inputs.lead,
    author: simulatorResult.inputs.author,
    seller: simulatorResult.inputs.seller,
    inputs: simulatorResult.inputs,
    calculatorMetadata: simulatorResult,
  };

  return transformObjectKeysToSnakeCase(proposalPayload);
};

export const parseComparisonBillProposal = (proposal: Record<string, any>): IComparisonBillProposal => {
  return transformObjectKeysToCamelCase(proposal) as IComparisonBillProposal;
};

export const parseComparisonBillProposals = (proposals: Record<string, any>): IComparisonBillProposals => {
  return transformObjectKeysToCamelCase(proposals) as IComparisonBillProposals;
};

export const parseComparisonBillSimulatorInputsToMultipleUnits = (
  proposal: IComparisonBillProposal,
): IComparisonBillSimulatorInputsWithMultipleUnits | undefined => {
  const simulationUnit = proposal.inputs.unit;

  if (!simulationUnit) return undefined;

  return {
    ...proposal.inputs,
    units: [simulationUnit],
  };
};

export class ComparisonBillSimulationParser {
  proposal: IComparisonBillProposal;
  simulationResult: IComparisonBillSimulation;
  simulationDefaultScenarioTariff: ISimulationPremisesTariff;

  constructor(proposal: IComparisonBillProposal) {
    this.proposal = proposal;
    this.simulationResult = proposal.calculatorMetadata;

    const simulationDefaultScenarioTariff = this.getSimulationDefaultScenarioTariff(this.simulationResult);
    if (!simulationDefaultScenarioTariff) throw 'Comparison Bill simulation tariff not identified.';
    this.simulationDefaultScenarioTariff = simulationDefaultScenarioTariff;
  }

  parseCaptiveMarketBillData = (): ICaptiveMarketBill | undefined => {
    const acrMinimalTableData = parseCaptiveMarketBillMinimalTableData(
      this.simulationResult.tariffFlagScenarios.default.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
    );
    const billData = parseCaptiveMarketBillTableData(this.simulationResult, this.simulationDefaultScenarioTariff);
    const captiveMarketBillGeneralData = this.parseBillGeneralData('Fatura de Distribuição e Energia');

    return {
      acrMinimalTableData,
      billData,
      captiveMarketBillGeneralData,
    };
  };

  parseFreeMarketBillData = (): IFreeMarketBill | undefined => {
    const distributionFreeMarketBillGeneralData = this.parseBillGeneralData('Fatura de Distribuição');
    const energyFreeMarketBillGeneralData = this.parseBillGeneralData('Fatura de Energia');

    const { totalBill, totalDistributionBill, totalEnergyBill } = this.getFreeMarketBillValues();
    const distributionFreeMarketBillData = ParserFreeMarketDistributionBill(
      this.simulationResult,
      totalDistributionBill,
      this.simulationDefaultScenarioTariff,
    );
    const energyFreeMarketBillData = ParserFreeMarketEnergyBill(this.simulationResult, totalEnergyBill);
    const aclMinimalTableData = parseFreeMarketBillMinimalTableData(totalDistributionBill, totalEnergyBill, totalBill);

    return {
      aclMinimalTableData,
      distributionFreeMarketBillData,
      distributionFreeMarketBillGeneralData,
      energyFreeMarketBillData,
      energyFreeMarketBillGeneralData,
    };
  };

  parseComparisonResultData = (): IComparisonResult => {
    const energyDistributionCompanyId: number = this.simulationResult?.inputs?.unit
      ?.energyDistributionCompany as number;

    const energyDistributionCompany = ENERGY_DISTRIBUTION_COMPANIES[energyDistributionCompanyId] ?? undefined;

    const parsedComparisonResultData = {
      currentMarket: {
        edc: energyDistributionCompany,
        price:
          this.simulationResult?.tariffFlagScenarios?.default?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      },
      targetMarket: {
        edc: energyDistributionCompany,
        provider: 'Fornecedor ACL',
        price: this.simulationResult?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      },
      economy: this.simulationResult?.tariffFlagScenarios?.default?.sampleMonthSimulation?.report?.totalSavingWithTaxes,
      generalData: {
        groupName: this.proposal.lead.name,
        unitName: `Unidade ${this.simulationResult.inputs?.unit?.nickname}`,
      },
    };

    return { comparisonResultData: parsedComparisonResultData };
  };

  parseBillGeneralData = (title: string) => {
    const groupName = this.proposal.lead.name;
    const unitName = this.simulationResult.inputs?.unit?.nickname as string;

    const billGeneralData: IBillGeneralData = {
      title,
      groupName,
      unitName,
    };

    return billGeneralData;
  };

  getFreeMarketBillValues = () => {
    const aclMonthSimulation = this.simulationResult.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket;

    const totalDistributionBill =
      thisOrZero(aclMonthSimulation?.distributionTariffOffPeak) +
      thisOrZero(aclMonthSimulation?.distributionTariffPeak) +
      thisOrZero(aclMonthSimulation?.distributionIcms) +
      thisOrZero(aclMonthSimulation?.distributionPisCofins) +
      thisOrZero(aclMonthSimulation?.distributionTariffPeakDiscount) +
      thisOrZero(aclMonthSimulation?.tariff?.stimulatedDemandOffPeak) *
        thisOrZero(Number(this.simulationResult.unit?.aclContractedDemandOffPeak)) +
      thisOrZero(aclMonthSimulation?.tariff?.stimulatedDemandPeak) *
        thisOrZero(Number(this.simulationResult.unit?.aclContractedDemandPeak));

    const totalEnergyBill =
      thisOrZero(aclMonthSimulation?.electricityTariff) +
      thisOrZero(aclMonthSimulation?.electricityIcms) +
      thisOrZero(aclMonthSimulation?.pisCofinsHighlight) +
      thisOrZero(aclMonthSimulation?.securityAndModulationEnergyCharges) +
      thisOrZero(this.simulationResult.premises.general.clarkeManagement) +
      thisOrZero(aclMonthSimulation?.contributionCcee);

    const totalBill = totalDistributionBill + totalEnergyBill;

    return { totalBill, totalDistributionBill, totalEnergyBill };
  };

  getSimulationDefaultScenarioTariff = (simulationResult: IComparisonBillSimulation) =>
    simulationResult.premises?.tariffsList?.find((tariff) => {
      return tariff.tariffAdjustmentDate.includes(
        this.simulationResult.tariffFlagScenarios?.default?.sampleMonthSimulation?.currentMarket?.monthYear?.slice(
          0,
          -15,
        ),
      );
    });
}
