import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName } from '@utils/common';

import { ILowTensionProposal, ILowTensionSimulatorInputs } from '@contexts/low-tension/types';
import { parseLowTensionProposal } from '@contexts/low-tension/parser';
import { parseProductOneSimulationResults as parseLowTensionSimulationResults } from '@contexts/product-one/parser';
import useLowTension from '@hooks/use-low-tension';

import LowTensionSimulationIntroductorySection from '@components/organisms/product-one/simulation/product-one-simulation-introductory-section';
import LowTensionSimulationGroupEconomySection from '@components/organisms/product-one/simulation/product-one-simulation-group-economy-section';
import LowTensionSimulationUnitsEconomySection from '@components/organisms/product-one/simulation/product-one-simulation-units-economy-section';
import LowTensionSimulationConclusiveSection from '@components/organisms/product-one/simulation/product-one-simulation-conclusive-section';

import { Container } from '@components/molecules/general/simulation-styled-components';
import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';

const getGroupManagementPrice = (simulationInputs?: ILowTensionSimulatorInputs) => {
  return simulationInputs?.units?.reduce((accumulated, unit) => accumulated + unit.managementPrice, 0);
};

const LowTensionSimulation = () => {
  const { id } = useParams();
  const { getLowTensionProposalById } = useLowTension();
  const { user } = useAuth();

  const navigate = useNavigate();

  const [proposal, setProposal] = React.useState<ILowTensionProposal>({} as ILowTensionProposal);
  const simulationResults = parseLowTensionSimulationResults(proposal);
  const [groupManagementPrice, setGroupManagementPrice] = React.useState<number>();

  const getProposalValidity = (proposal: ILowTensionProposal) => {
    const simulationInputs = proposal?.inputs;
    const groupManagementPrice = getGroupManagementPrice(simulationInputs);
    setGroupManagementPrice(groupManagementPrice);
  };

  const getProposal = (id: string) => {
    getLowTensionProposalById(id)
      .then((response) => {
        if (response.data) {
          const parsedProposal = parseLowTensionProposal(response.data.data);
          setProposal(parsedProposal);
          getProposalValidity(parsedProposal);
          eventTracker.trackEvent(eventLabels.SIMULATION_PAGE_VIEWED, {
            product: getCalculatorName(location.href),
            user_groups: user?.groups ? user?.groups.join(', ') : '',
            user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
          });
        } else navigate(SIMULATION_NOT_FOUND_PATH);
      })
      .catch(() => navigate(SIMULATION_NOT_FOUND_PATH));
  };

  React.useEffect(() => {
    if (id) getProposal(id);
  }, [id]);

  return (
    <Container className="flex flex-col items-center">
      <LowTensionSimulationIntroductorySection leadName={proposal?.lead?.name} />
      <LowTensionSimulationGroupEconomySection {...{ simulationResults }} />
      <LowTensionSimulationUnitsEconomySection {...{ simulationResults }} />
      {simulationResults && groupManagementPrice !== undefined && (
        <LowTensionSimulationConclusiveSection
          simulationResult={simulationResults[0]}
          groupManagementPrice={groupManagementPrice}
        />
      )}
    </Container>
  );
};

export default LowTensionSimulation;
