import React from 'react';

export const footerInformations = [
  'Clarke Energia',
  'CNPJ 35.472.171/0001-06',
  'Rua dos Pinheiros, 498 - São Paulo, SP',
  'contato@clarke.com.br',
  '+55 11 934 090 834',
];

const FeasibilityAnalysisSimulationFooter = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between mt-10 border-solid border-t border-black">
      {footerInformations.map((info) => (
        <p key={info} className="text-xs mt-2">
          {info}
        </p>
      ))}
    </div>
  );
};

export default FeasibilityAnalysisSimulationFooter;
