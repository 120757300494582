import { twMerge } from 'tailwind-merge';
import logoIcon from '@assets/newProposal/raios-icon.svg';
import logoIconOrange from '@assets/newProposal/logoIconOrange.svg';
import scheduleLogo from '@assets/newProposal/logo-c-mini.svg';
import useWhiteLabel from '@hooks/use-white-label';

function LegendContainer({
  legend,
  colorSecondary,
  className,
  isScheduleSection,
  legendTextColor,
  isWhiteLabelProposal,
}: {
  legend: string;
  colorSecondary?: boolean;
  className?: string;
  isScheduleSection?: boolean;
  legendTextColor?: string;
  isWhiteLabelProposal?: boolean;
}) {
  const logoSrc = isScheduleSection ? scheduleLogo : !colorSecondary ? logoIcon : logoIconOrange;
  const { whiteLabelSupplier } = useWhiteLabel();
  const colorMain = whiteLabelSupplier?.colors?.main;
  return (
    <span className={twMerge(className, 'w-full flex justify-end  items-center gap-4 mb-5 break-words')}>
      <p
        className={twMerge(
          'max-w-52 sm:max-w-full text-paragraph-large sm:text-paragraph-medium xl:text-paragraph-large 2xl:text-heading-xsmall font-normal text-center text-neutral-50',
          isScheduleSection && 'text-white',
          legendTextColor,
          isWhiteLabelProposal && `text-end border-b-4 whitespace-nowrap xl:min-w-40 w-fit`,
        )}
        style={{
          borderColor: isWhiteLabelProposal ? colorMain : undefined,
        }}
      >
        {legend}
      </p>
      {!isWhiteLabelProposal && (
        <img className="w-9 h-9" src={logoSrc} alt="Icone logo Clarke - raios verdes sobrepostos" />
      )}
    </span>
  );
}

export default LegendContainer;
