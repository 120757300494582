import numeral from 'numeral';
import { ISimulationPremisesTariff } from '@contexts/types';

const ParserFreeMarketDistributionBill = (
  data: Record<string, any> | undefined,
  total: number,
  tariff: ISimulationPremisesTariff | undefined,
): Record<string, string>[] => {
  const initialValues = {
    demandOffPeak: {
      quantity: data?.unit?.aclContractedDemandOffPeak,
      tariff: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.tariff?.stimulatedDemandOffPeak,
    },
    demandPeak: {
      quantity: data?.unit?.aclContractedDemandPeak,
      tariff: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.tariff?.stimulatedDemandPeak,
    },
    tusdPeak: {
      quantity: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.consumptionPeak,
      tariff:
        data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.tariff
          ?.stimulatedDistributionTariffPeak,
    },
    tusdOffPeak: {
      quantity: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.consumptionOffPeak,
      tariff: tariff?.distributionTariffOffPeak,
    },
    tePeak: {
      quantity: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.consumptionPeak,
      tariff: tariff?.electricityTariffPeak,
    },
    teOffPeak: {
      quantity: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.consumptionOffPeak,
      tariff: tariff?.electricityTariffOffPeak,
    },
    discounts: {
      quantity: null,
      tariff: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionTariffPeakDiscount,
    },
    totalWithoutTaxes: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.totalWithoutTaxes,
    totalWithTaxes:
      data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionTariffOffPeak +
      data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionTariffPeak,
  };

  const result = [
    {
      item: 'Demanda Contratada Fora Ponta (kW)',
      quantity: `${Number(initialValues.demandOffPeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.demandOffPeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') ||
        '-',
      total:
        numeral(
          `${(Number(initialValues.demandOffPeak?.quantity) * Number(initialValues.demandOffPeak?.tariff))
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'TUSD Ponta (MWh)',
      quantity: `${Number(initialValues.tusdPeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.tusdPeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        numeral(
          `${Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionTariffPeak)
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'TUSD Fora Ponta (MWh)',
      quantity: `${Number(initialValues.tusdOffPeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.tusdOffPeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        numeral(
          `${Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionTariffOffPeak)
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'TE Ponta (MWh)',
      quantity: `${Number(initialValues.tePeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff: numeral(`${Number(initialValues.tePeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        numeral(
          `${(Number(initialValues.tePeak?.quantity) * Number(initialValues.tePeak?.tariff))
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'TE Fora Ponta (MWh)',
      quantity: `${Number(initialValues.teOffPeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.teOffPeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        numeral(
          `${(Number(initialValues.teOffPeak?.quantity) * Number(initialValues.teOffPeak?.tariff))
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'Bandeira Tarifária',
      quantity: '-',
      tariff: '-',
      total: '-',
    },
    {
      item: 'Descontos',
      quantity: `${Number(initialValues.discounts?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.discounts?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        numeral(`${Number(initialValues.discounts?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
    },
    {
      item: 'Impostos',
      quantity: '-',
      tariff: '-',
      total:
        numeral(
          `${(
            Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionIcms) +
            Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.distributionPisCofins)
          )
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'Total (c/ impostos):',
      quantity: '',
      tariff: '',
      total: numeral(total).format('$ 0,0.00') || '-',
    },
  ];

  if (Number(initialValues.demandPeak.quantity) > 0) {
    result.splice(1, 0, {
      item: 'Demanda Contratada Ponta (kW)',
      quantity: `${Number(initialValues.demandPeak?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.demandPeak?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') || '-',
      total:
        (initialValues.demandPeak.tariff &&
          numeral(
            `${(Number(initialValues.demandPeak?.quantity) * Number(initialValues.demandPeak?.tariff))
              .toFixed(2)
              .replace('.', ',')}`,
          ).format('$ 0,0.00')) ||
        '-',
    });
  }

  return result;
};

export default ParserFreeMarketDistributionBill;
