import { ButtonInline } from '@clarke-energia/foton';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

import {
  ChargesCoverageTypeEnum,
  GuaranteeTypeEnum,
  ISimulationPayloadBase,
  ManagementTerminationFineEnum,
  ManagerTypeEnum,
  ProductTypeEnum,
} from '@contexts/types';

import { ISimulatorFormCompetitorsListItem } from '@components/molecules/form/simulator/sections/simulator-form-competitors-list-section/simulator-form-competitor-section';

import { getSimulatorCompetitorDefaultValues } from '@components/molecules/form/simulator/default-values-getters';

import { SelectFormField } from '../fields/select-form-field';
import { NumericFormField } from '../fields/numeric-form-field';

interface ISimulatorCompetitorGeneralSubsection extends ISimulatorFormCompetitorsListItem {
  removeCompetitor?: UseFieldArrayRemove;
  appendCompetitor?: UseFieldArrayAppend<ISimulationPayloadBase, 'competitorsList'>;
  isNotClarkeCompetitor: boolean;
}

export const competitorIdentificationDictionary: Record<string, string> = {
  0: 'Clarke',
  1: 'Concorrente A',
  2: 'Concorrente B',
};

export function SimulatorCompetitorGeneralSubsection({
  appendCompetitor,
  removeCompetitor,
  competitorIndex,
  competitorsNumber,
  simulatorType,
  isNotClarkeCompetitor,
}: ISimulatorCompetitorGeneralSubsection) {
  const maxCompetitorsValidation = competitorsNumber && competitorsNumber === 3;
  const isLastCompetitor = competitorsNumber && competitorsNumber === 2;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <label
          id={`competitor-name.${competitorIndex}`}
          htmlFor="nickname"
          className="mb-5 block text-heading-medium font-bold"
        >
          {competitorIdentificationDictionary[competitorIndex]}
        </label>
        <div className="flex flex-row gap-4">
          {!isLastCompetitor && isNotClarkeCompetitor && removeCompetitor && (
            <ButtonInline
              id={`exclude-competitor-button.${competitorIndex}`}
              label="Excluir"
              kind="secondary"
              size="xSmall"
              onClick={() => removeCompetitor(competitorIndex)}
            />
          )}
          {!maxCompetitorsValidation && appendCompetitor && (
            <ButtonInline
              id={`add-competitor-button.${competitorIndex}`}
              label="Adicionar Concorrente"
              kind="secondary"
              size="xSmall"
              onClick={() => {
                appendCompetitor(getSimulatorCompetitorDefaultValues(simulatorType));
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-4">
        {isNotClarkeCompetitor && (
          <SelectFormField<ISimulationPayloadBase>
            id={`competitorsList.${competitorIndex}.productType`}
            field={`competitorsList.${competitorIndex}.productType`}
            label="Modalidade"
            inputOptions={Object.entries(ProductTypeEnum).map((productTypeEntry) => ({
              value: productTypeEntry[0],
              optionLabel: productTypeEntry[1],
            }))}
            options={{ required: { value: true, message: 'Campo é obrigatório' } }}
          />
        )}
        <SelectFormField<ISimulationPayloadBase>
          id={`competitorsList.${competitorIndex}.managerType`}
          field={`competitorsList.${competitorIndex}.managerType`}
          label="Tipo de Empresa"
          inputOptions={Object.entries(ManagerTypeEnum).map((managerTypeEntry) => ({
            value: managerTypeEntry[0],
            optionLabel: managerTypeEntry[1],
          }))}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />
        <NumericFormField<ISimulationPayloadBase>
          id={`competitorsList.${competitorIndex}.upperFlexibility`}
          field={`competitorsList.${competitorIndex}.upperFlexibility`}
          label="Flexibilidade Superior"
          formatProps={{
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 10,
            prefix: '+ ',
            suffix: '%',
          }}
          options={{
            validate: (value) => (value <= 100 && value >= 0) || `Insira um valor entre 0 e 100`,
            required: { value: true, message: 'Campo é obrigatório' },
          }}
        />
        <NumericFormField<ISimulationPayloadBase>
          id={`competitorsList.${competitorIndex}.lowerFlexibility`}
          field={`competitorsList.${competitorIndex}.lowerFlexibility`}
          label="Flexibilidade Inferior"
          formatProps={{
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 10,
            prefix: '- ',
            suffix: '%',
          }}
          options={{
            validate: (value) => (value <= 100 && value >= 0) || `Insira um valor entre 0 e 100`,
            required: { value: true, message: 'Campo é obrigatório' },
          }}
        />
        <SelectFormField<ISimulationPayloadBase>
          id={`competitorsList.${competitorIndex}.guaranteeType`}
          field={`competitorsList.${competitorIndex}.guaranteeType`}
          label="Tipo de Garantia"
          inputOptions={Object.entries(GuaranteeTypeEnum).map((guaranteeTypeEntry) => ({
            value: guaranteeTypeEntry[0],
            optionLabel: guaranteeTypeEntry[1],
          }))}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />
        {isNotClarkeCompetitor && (
          <SelectFormField<ISimulationPayloadBase>
            id={`competitorsList.${competitorIndex}.chargesCoverageType`}
            field={`competitorsList.${competitorIndex}.chargesCoverageType`}
            label="Encargos"
            inputOptions={Object.entries(ChargesCoverageTypeEnum).map((chargesCoverageTypeConditionEntry) => ({
              value: chargesCoverageTypeConditionEntry[0],
              optionLabel: chargesCoverageTypeConditionEntry[1],
            }))}
            options={{
              required: { value: true, message: 'Campo é obrigatório' },
            }}
          />
        )}
        {isNotClarkeCompetitor && (
          <NumericFormField<ISimulationPayloadBase>
            id={`competitorsList.${competitorIndex}.postMigrationManagementPrice`}
            field={`competitorsList.${competitorIndex}.postMigrationManagementPrice`}
            label="Preço da gestão / mês (pós-migração)"
            formatProps={{
              mask: '_',
              allowNegative: false,
              fixedDecimalScale: false,
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 10,
              prefix: 'R$ ',
            }}
            options={{ required: { value: true, message: 'Campo é obrigatório' } }}
          />
        )}
        <SelectFormField<ISimulationPayloadBase>
          id={`competitorsList.${competitorIndex}.managementTerminationFine`}
          field={`competitorsList.${competitorIndex}.managementTerminationFine`}
          label="Multa de rescisão de gestão"
          inputOptions={Object.entries(ManagementTerminationFineEnum).map((managementTerminationFineEntry) => ({
            value: managementTerminationFineEntry[0],
            optionLabel: managementTerminationFineEntry[1],
          }))}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />
      </div>
    </div>
  );
}
