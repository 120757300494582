import { Button } from '@clarke-energia/foton';
import { useAuth } from '@src/auth-wrapper';
import useWhiteLabel from '@hooks/use-white-label';
import { IWhiteLabelSupplierPayload } from '@contexts/white-label/type';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { MultiSelectFormField } from '@components/atoms/form/mult-select';

export function WhiteLabelSupplierFormUsersSection() {
  const { user } = useAuth();
  const { useWhiteLabelSupplierFormUsers } = useWhiteLabel();
  const { fields, handleInputChange, remove, setSearchText, usersWhiteLabelSupplier } =
    useWhiteLabelSupplierFormUsers();

  return (
    <>
      <ContractSectionContainer>
        <div className="grid grid-cols-3 col-span-full min-w-full">
          <ContractTitleAndSubtitleSubsection
            title="Usuários"
            subtitle="Usuários relacionados a fornecedora, que poderão fazer propostas."
          />
          <div className="col-span-2 columns-1 gap-4">
            <div className="flex flex-col gap-6 items-baseline">
              {user?.isSuperAdmin && (
                <MultiSelectFormField<IWhiteLabelSupplierPayload>
                  id="select-input-users-auth0"
                  label="Adicionar"
                  isLoading={usersWhiteLabelSupplier.length === 0}
                  inputOptions={usersWhiteLabelSupplier.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  placeholder="Selecione um usuário"
                  onInputChange={setSearchText}
                  options={{
                    onChange: handleInputChange,
                    disabled: !user?.isSuperAdmin,
                    required: fields.length === 0,
                  }}
                />
              )}
            </div>
            {fields.length > 0 ? (
              <div className="col-span-2 columns-1 mt-5">
                <div className="flex flex-col gap-6 items-baseline">
                  <div className="grid grid-cols-1 col-span-1 w-full gap-4">
                    <table id="user-list-table" className="min-w-full table-auto order-1 outline-1">
                      <thead className="text-brand-gray-30 border-b">
                        <tr>
                          <th id="table-header-name" className="text-start px-4 py-2 font-semibold">
                            Nome
                          </th>
                          <th id="table-header-email" className="text-start px-4 py-2 font-semibold">
                            Email
                          </th>
                          <th id="table-header-actions" className="text-start px-4 py-2 font-semibold" />
                        </tr>
                      </thead>
                      <tbody id="user-list-body">
                        {fields.map((field, index) => (
                          <tr
                            key={`white-label-user-${field.id}-${index}`}
                            id={`user-row-${field.id}`}
                            className="border-t"
                          >
                            <td id={`user-name-${field.id}`} className="px-4 py-2">
                              {field.name}
                            </td>
                            <td id={`user-email-${field.id}`} className="px-4 py-2">
                              {field.email}
                            </td>
                            <td className="px-4 py-2">
                              <Button
                                id={`remove-user-${field.id}`}
                                className="border-none ring-0 outline-0 bg-transparent hover:bg-transparent"
                                icon="TrashIcon"
                                label=""
                                size="small"
                                onClick={() => remove(index)}
                                disabled={!user?.isSuperAdmin}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <h1
                id="no-users-message"
                className="text-paragraph-medium text-center font-semibold mt-10 text-brand-gray-30"
              >
                {!user?.isSuperAdmin
                  ? 'Nenhum usuário foi vinculado a essa fornecedora. Por favor, entre em contato com a nossa equipe para efetuar vínculos.'
                  : 'Nenhum usuário foi vinculado a essa fornecedora.'}
              </h1>
            )}
          </div>
        </div>
      </ContractSectionContainer>
    </>
  );
}
