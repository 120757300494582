import axios from 'axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createContext } from 'use-context-selector';

import config from '@config';
import { useAuth } from '@src/auth-wrapper';
import useProductOne from '@hooks/use-product-one';
import { IPaginatedResponseBase, IResponseBase } from '@contexts/types';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';
import { parseWhiteLabelSupplier, parseWhiteLabelSupplierPayload, parseWhiteLabelSuppliers } from './parser';
import { IWhiteLabelSupplier, IWhiteLabelSupplierPayload } from './type';

export interface IWhiteLabelContext {
  whiteLabelSupplier: IWhiteLabelSupplier;
  isWhiteLabelProposal: boolean;
  isUserMissingSupplier: boolean;
  allWhiteLabelSuppliers: IResponseBase<IPaginatedResponseBase<IWhiteLabelSupplier>>;
  isLoading: boolean;
  openSuccessModalWhiteLabel: boolean;
  openErrorModalWhiteLabel: boolean;
  setOpenSuccessModalWhiteLabel: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenErrorModalWhiteLabel: React.Dispatch<React.SetStateAction<boolean>>;
  createNewWhiteLabelSupplier: (data: IWhiteLabelSupplierPayload) => Promise<boolean>;
  getWhiteLabelSupplierByUserId: (user_id: string) => Promise<void>;
  getWhiteLabelSupplierById: (id: string) => Promise<void>;
  getAllWhiteLabelSuppliers: (page?: number) => Promise<void>;
  updateWhiteLabelSupplier: (data: IWhiteLabelSupplierPayload, id: string) => Promise<boolean>;
  deleteWhiteLabelSupplier: (id: string) => Promise<boolean>;
}

export const WhiteLabelContext = createContext({} as IWhiteLabelContext);

interface ProviderProps {
  children: React.ReactNode;
}

const WhiteLabelProvider: React.FC<ProviderProps> = ({ children }) => {
  const whiteLabelSupplierSalesBaseURL = `${config.SALES_API_HOST}/api/v1/white-label/supplier`;
  const {
    authStatus: { accessToken },
  } = useAuth();

  const salesApiClient = axios.create({
    baseURL: whiteLabelSupplierSalesBaseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    formSerializer: { metaTokens: false, indexes: null },
  });
  const navigate = useNavigate();
  const { proposal, getProductOneProposalById } = useProductOne();
  const { id } = useParams();
  const [isWhiteLabelProposal, setIsWhiteLabelProposal] = React.useState<boolean>(false);
  const [whiteLabelSupplier, setWhiteLabelSupplier] = React.useState<
    IWhiteLabelSupplier | IWhiteLabelSupplierPayload
  >();
  const [isUserMissingSupplier, setIsUserMissingSupplier] = React.useState<boolean>(false);
  const [allWhiteLabelSuppliers, setAllWhiteLabelSuppliers] = React.useState<Array<IWhiteLabelSupplier>>();
  const [openSuccessModalWhiteLabel, setOpenSuccessModalWhiteLabel] = React.useState<boolean>(false);
  const [openErrorModalWhiteLabel, setOpenErrorModalWhiteLabel] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getProposal = async (id: string) => {
    const response = await getProductOneProposalById(id);
    !response && navigate(SIMULATION_NOT_FOUND_PATH);
  };

  React.useEffect(() => {
    if (id && !proposal) {
      getProposal(id);
    } else if (proposal) {
      const supplierId = proposal?.inputs?.whiteLabelSupplierId;
      if (supplierId) {
        getWhiteLabelSupplierById(supplierId);
        setIsWhiteLabelProposal(true);
      }
    }
  }, [id, proposal]);

  async function createNewWhiteLabelSupplier(data: IWhiteLabelSupplierPayload): Promise<boolean> {
    setIsLoading(true);
    try {
      const response = await salesApiClient.post<IWhiteLabelSupplier>(`/`, parseWhiteLabelSupplierPayload(data));
      const newWhiteLabelSupplier = response.data;
      setWhiteLabelSupplier(newWhiteLabelSupplier);
      return true;
    } catch (error) {
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  async function updateWhiteLabelSupplier(data: IWhiteLabelSupplier, id: string): Promise<boolean> {
    setIsLoading(true);
    try {
      const response = await salesApiClient.post<IWhiteLabelSupplier>(`/${id}`, parseWhiteLabelSupplierPayload(data));
      const updatedWhiteLabelSupplier = response.data;
      setWhiteLabelSupplier(updatedWhiteLabelSupplier);
      return true;
    } catch (error) {
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteWhiteLabelSupplier(id: string): Promise<boolean> {
    setIsLoading(true);
    try {
      const response = await salesApiClient.post(`/delete/${id}`);
      if (response.status === 200 || response.status === 204) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  async function getAllWhiteLabelSuppliers(page?: number): Promise<void> {
    setIsLoading(true);
    try {
      const response = await salesApiClient.get('/', {
        params: { page: page },
      });
      const parsedWhiteLabelSuppliers = parseWhiteLabelSuppliers(response.data);
      setAllWhiteLabelSuppliers(parsedWhiteLabelSuppliers);
    } catch (error) {
      const message = error instanceof Error ? `: ${error.message}.` : '.';
      throw new Error(`Error getting all white label suppliers${message}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function getWhiteLabelSupplierById(id?: string): Promise<void> {
    setIsLoading(true);
    try {
      const { data: response } = await salesApiClient.get(`/${id}`);
      const parsedWhiteLabelSupplier = parseWhiteLabelSupplier(response.data);
      setWhiteLabelSupplier(parsedWhiteLabelSupplier);
    } catch (error) {
      const message = error instanceof Error ? `: ${error.message}.` : '.';
      throw new Error(`Error when obtaining white label supplier by supplier id${message}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function getWhiteLabelSupplierByUserId(user_id: string): Promise<void> {
    setIsLoading(true);
    try {
      const { data: response } = await salesApiClient.get(`/get-by-user-id/${user_id}`);
      const parsedWhiteLabel = parseWhiteLabelSupplier(response.data);
      parsedWhiteLabel?.id == undefined ? setIsUserMissingSupplier(true) : setWhiteLabelSupplier(parsedWhiteLabel);
    } catch (error) {
      const message = error instanceof Error ? `: ${error.message}.` : '.';
      throw new Error(`Error getting white label supplier by user id${message}`);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    //@ts-expect-error
    <WhiteLabelContext.Provider
      value={{
        whiteLabelSupplier,
        allWhiteLabelSuppliers,
        isLoading,
        openSuccessModalWhiteLabel,
        setOpenSuccessModalWhiteLabel,
        openErrorModalWhiteLabel,
        setOpenErrorModalWhiteLabel,
        isWhiteLabelProposal,
        isUserMissingSupplier,
        createNewWhiteLabelSupplier,
        updateWhiteLabelSupplier,
        getWhiteLabelSupplierByUserId,
        getWhiteLabelSupplierById,
        getAllWhiteLabelSuppliers,
        deleteWhiteLabelSupplier,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
};

export default WhiteLabelProvider;
