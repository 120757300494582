import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from '@components/atoms/general/bar-chart';

import buildChartData from './helper';

type IGridColumns = {
  totalColumns: number;
  yearsColumns: number;
};

export interface IEconomyChart {
  labels: Array<string>;
  currentValues: Array<number>;
  targetValues: Array<number>;
  savingValues: Array<number>;
  alternativeBarsColor?: boolean;
  gridColumns?: IGridColumns;
}

const EconomyChart = ({
  labels,
  currentValues,
  targetValues,
  savingValues,
  alternativeBarsColor,
  gridColumns,
}: IEconomyChart): JSX.Element => {
  if (!targetValues || !currentValues || !savingValues || !gridColumns) return <></>;

  const chartData = buildChartData({ currentValues, targetValues, savingValues, alternativeBarsColor, labels });

  const gridStyle: React.HTMLAttributes<HTMLDivElement>['style'] = {
    display: 'grid',
    width: '100%',
    height: 'auto',
    position: 'relative',
    gridTemplateColumns: `repeat(${gridColumns?.totalColumns}, 1fr)`,
  };

  const colStyle: React.HTMLAttributes<HTMLDivElement>['style'] = {
    gridColumnStart: '3',
    gridColumnEnd: `span ${gridColumns?.yearsColumns}`,
  };

  return (
    <div style={gridStyle}>
      <div style={colStyle}>
        <BarChart
          plugins={[ChartDataLabels]}
          options={chartData.options}
          datasets={chartData.datasets}
          labels={chartData.labels}
        />
      </div>
    </div>
  );
};

export default EconomyChart;
