import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { useFormContext } from 'react-hook-form';

import { IBusinessHours, IBusinessHoursSet } from '@contexts/types';
import { ILowTensionSimulatorInputs } from '@contexts/low-tension/types';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';

import { isStringEmpty, removeNonNumericFromString } from '@utils/common';

import { TimeFormField } from '../fields/time-form-field';

interface ISimulatorUnitBusinessHoursSubsection extends ISimulatorFormUnitSection {
  selectedTariff?: number;
}

const businessHoursLimits: Array<keyof IBusinessHours> = ['openingTime', 'closingTime'];

interface IBusinessHoursRowProps {
  weekPeriod: keyof IBusinessHoursSet;
  unitNum: number;
}

const BusinessHours: React.FC<IBusinessHoursRowProps> = ({ unitNum, weekPeriod }: IBusinessHoursRowProps) => {
  const isBusinessDaysPeriod = weekPeriod === 'businessDays';

  const [isPeriodActive, setIsPeriodActive] = React.useState<boolean>(isBusinessDaysPeriod);
  const { setValue } = useFormContext<ILowTensionSimulatorInputs>();

  const handleChangePeriodActivation = (isChecked: boolean) => {
    setIsPeriodActive(isChecked);
    if (!isChecked) {
      const weekPeriodField = `units.${unitNum}.businessHours.${weekPeriod}` as keyof ILowTensionSimulatorInputs;
      setValue(weekPeriodField, undefined);
    }
  };

  const formatTime = (value: string) => {
    const numericStringValue = removeNonNumericFromString(value);
    if (numericStringValue.length < 2) return numericStringValue;
    else return `${numericStringValue.slice(0, 2)}:${numericStringValue.slice(2, 4)}h`;
  };

  return (
    <div className="flex gap-8 w-full items-center rounded-2xl bg-neutral-10 py-5 px-7">
      <div className="flex gap-3">
        <Checkbox
          label=""
          defaultChecked={isBusinessDaysPeriod ? true : isPeriodActive}
          onChange={(e) => handleChangePeriodActivation(e.target.checked)}
          disabled={isBusinessDaysPeriod}
        />
        <p className="w-full">{isBusinessDaysPeriod ? 'Dia Útil' : weekPeriod === 'saturday' ? 'Sábado' : 'Domingo'}</p>
      </div>
      {businessHoursLimits.map((businessHour) => {
        const fieldName =
          `units.${unitNum}.businessHours.${weekPeriod}.${businessHour}` as keyof ILowTensionSimulatorInputs;
        return (
          <TimeFormField<ILowTensionSimulatorInputs>
            key={`unit-${unitNum}-${fieldName}`}
            id={`unit-${unitNum}-${fieldName}`}
            field={fieldName}
            label={businessHour === 'openingTime' ? 'Abertura' : 'Fechamento'}
            formatProps={{
              format: formatTime,
              allowNegative: false,
              fixedDecimalScale: false,
              isNumericString: true,
            }}
            step={30}
            className="w-full"
            options={{
              validate: (value) =>
                isStringEmpty(value) ? true : (value as string).length == 4 || 'Insira um horário válido',
              required: isPeriodActive && {
                value: true,
                message: 'Informe um horário para dias úteis.',
              },
            }}
            disabled={!isPeriodActive}
          />
        );
      })}
    </div>
  );
};

export function SimulatorUnitBusinessHoursSubsection({
  unitNum,
  fieldsController,
}: ISimulatorUnitBusinessHoursSubsection) {
  const weekPeriods: Array<keyof IBusinessHoursSet> = ['businessDays', 'saturday', 'sunday'];

  return (
    <div>
      {fieldVerificator({ fieldName: 'businessHours', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
        <div className="flex flex-col w-full">
          <p className="text-paragraph-large font-semibold mb-4">Horários de Funcionamento</p>
          <div className="flex flex-col gap-5 w-full">
            {weekPeriods.map((weekPeriod) => (
              <BusinessHours
                key={`business-hours-week-period-${weekPeriod}-unit-${unitNum}`}
                unitNum={unitNum}
                weekPeriod={weekPeriod}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
