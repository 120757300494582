import { IProductTwoProposal } from '@contexts/product-two/types';
import { IProductOneProposal } from '@contexts/product-one/types';

import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';

import {
  extractAnnualDetail,
  extractAnnualDetailRetail,
  extractChartData,
  extractChartDataRetail,
  extractEconomyData,
} from '../helpers';

export function parseRetailFeasibilityData() {
  const { proposal: retailProposal } = useProductTwo();

  const chartData = extractChartDataRetail(
    retailProposal?.calculatorMetadata?.default?.bestRetailTraderResult?.calculatorMetadata,
  );
  const annualDetail = extractAnnualDetailRetail(
    retailProposal?.calculatorMetadata?.default?.bestRetailTraderResult?.calculatorMetadata,
  );

  const annualRef = annualDetail.length;
  const gridCols = annualRef > 5 ? 4 : annualRef;
  const lastCard = annualDetail.length - 1;

  const economyData = {
    ...extractEconomyData(
      retailProposal?.calculatorMetadata?.default?.bestRetailTraderResult
        ?.inputs as IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['inputs'],
      retailProposal?.calculatorMetadata?.default?.bestRetailTraderResult
        ?.calculatorMetadata as IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['calculatorMetadata'],
    ),
  };
  const leadName = retailProposal?.lead?.name;

  return {
    leadName,
    chartData,
    annualDetail,
    annualRef,
    gridCols,
    lastCard,
    economyData,
    pricesExpirationDate: retailProposal?.inputs?.retailTradersPrices[0].expirationDate,
  };
}

export function parseManagementFeasibilityData() {
  const { proposal: managementProposal } = useProductOne();

  const chartData = extractChartData(managementProposal?.calculatorMetadata?.firstResult);
  const annualDetail = extractAnnualDetail(managementProposal?.calculatorMetadata?.firstResult);

  const annualRef = annualDetail.length;
  const gridCols = annualRef > 5 ? 4 : annualRef;
  const lastCard = annualDetail.length - 1;

  const economyData = {
    ...extractEconomyData(
      managementProposal?.calculatorMetadata?.inputs as IProductOneProposal['calculatorMetadata']['inputs'],
      managementProposal?.calculatorMetadata?.firstResult as IProductOneProposal['calculatorMetadata']['firstResult'],
    ),
  };

  const scenariosRiskComparisonData = managementProposal?.calculatorMetadata?.firstResult?.scenariosRiskComparison;
  const risk = managementProposal?.calculatorMetadata?.inputs?.risk;
  const leadName = managementProposal?.lead?.name;

  return {
    leadName,
    chartData,
    annualDetail,
    annualRef,
    gridCols,
    lastCard,
    economyData,
    scenariosRiskComparisonData,
    risk,
  };
}
