import React from 'react';

import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';
import useFeasibilityAnalysis from '@hooks/use-feasibility-analysis';

import FeasibilityAnalysisLeadProposalsHub from '@components/organisms/feasibility-analysis/lead-proposals-hub';
import LeadsHistory from '@components/organisms/general/leads-history';

const FeasibilityAnalysisLeadsHistory = (): JSX.Element => {
  const { deleteFeasibilityAnalysisAllProposalsByLeadIdHandler } = useFeasibilityAnalysis();

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  return (
    <LeadsHistory<IFeasibilityAnalysisProposal>
      simulatorType="FEASIBILITY_ANALYSIS"
      deleteAllProposalsByLeadIdHandler={deleteFeasibilityAnalysisAllProposalsByLeadIdHandler}
      selectedLeadId={selectedLeadId}
      setSelectedLeadId={setSelectedLeadId}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <>
        {isDrawerOpen && selectedLeadId && (
          <FeasibilityAnalysisLeadProposalsHub
            selectedProposalId={selectedLeadId}
            setSelectedProposalId={setSelectedLeadId}
            setIsDrawerOpen={setIsDrawerOpen}
            leadId={selectedLeadId}
          />
        )}
      </>
    </LeadsHistory>
  );
};

export default FeasibilityAnalysisLeadsHistory;
