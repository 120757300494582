import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { isStringEmpty } from './common';

dayjs.extend(customParseFormat);

export const isValidDate = (value: string, minimumDate?: dayjs.Dayjs) => {
  if (isStringEmpty(value)) return false;

  const parsedDate = dayjs(value, 'DD/MM/YYYY');

  if (!parsedDate.isValid()) return false;

  if (minimumDate && parsedDate.isBefore(minimumDate)) return false;

  return true;
};

export const formatDate = (value: string | undefined, format: string) => {
  return dayjs(value).format(format);
};

export const checkValidityPeriod = (validityDate?: string): boolean => {
  if (!validityDate) return false;

  const parsedValidityDate = `${validityDate.slice(3, 5)}/${validityDate.slice(0, 2)}/${validityDate.slice(6, 11)}`;

  return new Date(parsedValidityDate) >= new Date() ? true : false;
};

export function addWorkdays(startDate: dayjs.Dayjs, days: number): dayjs.Dayjs {
  let currentDate = startDate;
  let remainingDays = days;

  while (remainingDays > 0) {
    currentDate = currentDate.add(1, 'day');
    if (currentDate.day() >= 1 && currentDate.day() <= 5) {
      remainingDays--;
    }
  }

  return currentDate;
}

export function startDate() {
  const contractDate = new Date().toLocaleDateString('pt-BR');
  const startDate = dayjs(contractDate, 'DD/MM/YYYY');
  return startDate;
}

export function validationDatePricesExpirationDate(pricesExpirationDate: string | undefined) {
  const dateFormat = 'DD/MM/YYYY';
  const dateTimeFormat = `${dateFormat} [às] HH:mm[h]`;

  let validationDate = addWorkdays(startDate(), 2).format(dateFormat);

  if (pricesExpirationDate) {
    const today = dayjs();
    const expirationDate = dayjs(pricesExpirationDate, 'YYYY/MM/DD');
    const isValidDatePricesExpirationDate = expirationDate.isValid() && expirationDate.isAfter(today, 'day');

    const isManagementPath = window.location.pathname.includes('clarke-gestao');
    if (isValidDatePricesExpirationDate) {
      validationDate = isManagementPath ? expirationDate.format(dateFormat) : expirationDate.format(dateTimeFormat);
    } else {
      validationDate = isManagementPath
        ? addWorkdays(startDate(), 2).format(dateFormat)
        : dayjs(pricesExpirationDate).format(dateTimeFormat);
    }
  }

  return validationDate;
}
