import React from 'react';

import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';

import { SlideContainerGreater } from '@components/organisms/feasibility-analysis/simulation/styles';
import FeasibilityAnalysisProposalSectionBase from '@components/molecules/feasibility-analysis/simulation/section-base';

interface IFeasibilityAnalysisSimulationUnitsEconomySection {
  proposal?: IFeasibilityAnalysisProposal;
}

const FeasibilityAnalysisSimulationUnitsEconomySection = ({
  proposal,
}: IFeasibilityAnalysisSimulationUnitsEconomySection) => (
  <>
    {proposal?.calculatorMetadata.unitsReport.map((unitReport, index) => (
      <SlideContainerGreater key={`feasibility-analysis-proposal-unit-economy-${index}`}>
        <FeasibilityAnalysisProposalSectionBase
          leadName={proposal.lead.name}
          simulationResult={proposal.calculatorMetadata}
          unitReport={unitReport}
        />
      </SlideContainerGreater>
    ))}
  </>
);

export default FeasibilityAnalysisSimulationUnitsEconomySection;
