import numeral from 'numeral';
import { ILowTensionSimulation } from '@contexts/low-tension/types';

interface ILowTensionProposalKeyMetricsPanel {
  calculatorMetadata: ILowTensionSimulation;
}

const LowTensionProposalKeyMetricsPanel = ({ calculatorMetadata }: ILowTensionProposalKeyMetricsPanel) => {
  const simulationResults = [
    calculatorMetadata?.firstResult,
    calculatorMetadata?.secondResult,
    calculatorMetadata?.thirdResult,
  ];

  return (
    <div>
      {simulationResults.map(
        (result, index) =>
          result && (
            <div key={`low-tension-proposal-preview-economy-${index}`} className="py-5 grid grid-cols-2 gap-6 px-6">
              <div className="flex flex-col col-span-1">
                <dt className="text-sm font-medium text-gray-500">{`Economia em ${result.targetYearGroup} ano(s) (R$)`}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {numeral(result.totalSaving).format('$ 0,0.00')}
                </dd>
              </div>
              <div className="flex flex-col col-span-1">
                <dt className="text-sm font-medium text-gray-500">{`Porcentagem de economia em ${result.targetYearGroup} ano(s) (%)`}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {numeral(result.totalSavingPercentage).format('% 0.00')}
                </dd>
              </div>
            </div>
          ),
      )}
    </div>
  );
};

export default LowTensionProposalKeyMetricsPanel;
