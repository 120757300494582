import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import config from '@config';

import { ILowTensionSimulation, ILowTensionSimulatorInputs } from '@contexts/low-tension/types';

import { parseLowTensionSimulationPayload, parseLowTensionSimulatorResultToProposalPayload } from './parser';
import { ILowTensionContext } from './types';
import { IUpdateSellerPayload } from '@contexts/types';

export const LowTensionContext = createContext({} as ILowTensionContext);

interface Provider {
  children: React.ReactNode;
}

const LowTensionProvider: React.FC<Provider> = ({ children }) => {
  const lowTensionCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v1/low-tension`;
  const calculatorApiClient = axios.create({
    baseURL: lowTensionCalculatorBaseURL,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  function createLowTensionSimulation(simulationData: ILowTensionSimulatorInputs): AxiosPromise {
    const simulationPayload = parseLowTensionSimulationPayload(simulationData);
    return calculatorApiClient.post('/simulation', simulationPayload);
  }

  function createLowTensionProposal(simulatorResult: ILowTensionSimulation): AxiosPromise {
    const proposalPayload = parseLowTensionSimulatorResultToProposalPayload(simulatorResult);
    return calculatorApiClient.post('proposals', proposalPayload);
  }

  function getLowTensionProposals(leadId?: string, sellerId?: string): AxiosPromise {
    return calculatorApiClient.get('proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  function getLowTensionProposalById(proposalId: string): AxiosPromise {
    return calculatorApiClient.get(`proposals/${proposalId}`);
  }

  function deleteLowTensionProposalsHandler(leadId: string, proposalsIds: Array<string>): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteLowTensionAllProposalsByLeadIdHandler(leadId: string): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }
  function updateSellerOfLowTensionProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise {
    return calculatorApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  return (
    <LowTensionContext.Provider
      value={{
        createLowTensionSimulation,
        createLowTensionProposal,
        getLowTensionProposals,
        getLowTensionProposalById,
        deleteLowTensionProposalsHandler,
        deleteLowTensionAllProposalsByLeadIdHandler,
        updateSellerOfLowTensionProposal,
      }}
    >
      {children}
    </LowTensionContext.Provider>
  );
};

export default LowTensionProvider;
