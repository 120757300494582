import { AxiosPromise, AxiosResponse } from 'axios';

import { parseComparisonBillSimulatorResult } from '@contexts/comparison-bill/parser';
import { parseFeasibilityAnalysisSimulatorResult } from '@contexts/feasibility-analysis/parser';
import { parseProductOneSimulatorResult } from '@contexts/product-one/parser';
import { parseLowTensionSimulatorResult } from '@contexts/low-tension/parser';
import { IResponseBase, SimulatorTypeEnum } from '@contexts/types';

export interface ISimulatorHandlers {
  createSimulation: (inputs: any) => AxiosPromise<IResponseBase<any>>;
  createProposal?: (simulationData: any) => AxiosPromise<IResponseBase<any>>;
}

export class AutoProposalGenerator {
  simulatorInputs: Record<string, any>;
  simulatorType: keyof typeof SimulatorTypeEnum;

  constructor(simulatorInputs: Record<string, any>, simulatorType: keyof typeof SimulatorTypeEnum) {
    this.simulatorInputs = simulatorInputs;
    this.simulatorType = simulatorType;
  }

  simulatorParserIndexer: Record<
    keyof typeof SimulatorTypeEnum,
    undefined | ((createSimulationResponse: AxiosResponse<IResponseBase<any>>) => any)
  > = {
    CLARKE_MANAGEMENT: parseProductOneSimulatorResult,
    FEASIBILITY_ANALYSIS: parseFeasibilityAnalysisSimulatorResult,
    COMPARISON_BILL: parseComparisonBillSimulatorResult,
    CLARKE_RETAIL: undefined,
    CONTRACTED_DEMAND: undefined,
    LOW_TENSION: parseLowTensionSimulatorResult,
  };

  create = async (simulatorHandlers: ISimulatorHandlers) => {
    try {
      const { createSimulation, createProposal } = simulatorHandlers;

      const createSimulationResponse = await createSimulation(this.simulatorInputs);

      if (this.simulatorType === 'CLARKE_RETAIL') {
        return this._checkProductTwoSimulationCreationResult(createSimulationResponse);
      } else if (createProposal) {
        const parseSimulationResponse = this.simulatorParserIndexer[this.simulatorType] as (
          createSimulationResponse: AxiosResponse<IResponseBase<any>>,
        ) => any;

        const parsedSimulatorResponse = parseSimulationResponse(createSimulationResponse);
        const createProposalResponse = await createProposal({
          ...parsedSimulatorResponse,
          inputs: this.simulatorInputs,
        });

        if (this.simulatorType === 'COMPARISON_BILL')
          return this._checkComparisonBillProposalCreationResult(createProposalResponse);
        else return this._checkGeneralProposalCreationResult(createProposalResponse);
      }
    } catch {
      return false;
    }
  };

  _checkGeneralProposalCreationResult = (createProposalResponse: AxiosResponse) => {
    if (createProposalResponse.data.data.id) return true;
    else return false;
  };

  _checkComparisonBillProposalCreationResult = (createProposalResponse: AxiosResponse) => {
    if (createProposalResponse.data.data.id) return true;
    else return false;
  };

  _checkProductTwoSimulationCreationResult = (createSimulationResponse: AxiosResponse) => {
    if (createSimulationResponse.data.data.id) return true;
    else return false;
  };
}
