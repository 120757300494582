import React from 'react';
import config from '@config';
import axios, { AxiosPromise } from 'axios';
import { createContext } from 'use-context-selector';
import { IUpdateSellerPayload } from '@contexts/types';

import {
  IComparisonBillContext,
  IComparisonBillSimulation,
  IComparisonBillSimulatorInputsWithMultipleUnits,
} from './types';
import { parseComparisonBillProposalPayload, parseComparisonBillSimulationPayload } from './parser';

export const ComparisonBillContext = createContext({} as IComparisonBillContext);

interface Provider {
  children: React.ReactNode;
}

const ComparisonBillProvider: React.FC<Provider> = ({ children }: Provider) => {
  const comparisonBillCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v1/comparison-bill`;
  const calculatorApiClient = axios.create({
    baseURL: comparisonBillCalculatorBaseURL,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  function createComparisonBillSimulationHandler(
    simulationData: IComparisonBillSimulatorInputsWithMultipleUnits,
  ): AxiosPromise {
    const simulationPayload = parseComparisonBillSimulationPayload(simulationData);
    return calculatorApiClient.post('/simulation', simulationPayload);
  }

  function createComparisonBillProposalHandler(proposalData: IComparisonBillSimulation): AxiosPromise {
    const proposalPayload = parseComparisonBillProposalPayload(proposalData);
    return calculatorApiClient.post('proposals', proposalPayload);
  }

  function getComparisonBillProposalsHandler(leadId?: string, sellerId?: string): AxiosPromise {
    return calculatorApiClient.get('proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  function getComparisonBillProposalByIdHandler(proposalId: string) {
    return calculatorApiClient.get(`proposals/${proposalId}`);
  }

  function deleteComparisonBillProposalsHandler(leadId: string, proposalsIds: Array<string>) {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteComparisonBillAllProposalsByLeadIdHandler(leadId: string): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }

  function updateSellerOfComparisonBillProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise {
    return calculatorApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  return (
    <ComparisonBillContext.Provider
      value={{
        createComparisonBillSimulationHandler,
        createComparisonBillProposalHandler,
        getComparisonBillProposalByIdHandler,
        getComparisonBillProposalsHandler,
        deleteComparisonBillProposalsHandler,
        deleteComparisonBillAllProposalsByLeadIdHandler,
        updateSellerOfComparisonBillProposal,
      }}
    >
      {children}
    </ComparisonBillContext.Provider>
  );
};

export default ComparisonBillProvider;
