/* eslint-disable react/require-default-props */
import React from 'react';
import './style.css';

interface Column {
  key?: string;
  label?: string;
  render: (value?: any) => any;
}

interface ITableProps {
  columns: Column[] | any[];
  data: any[];
  lastRowHighlight?: boolean;
  id: string;
}

const MinimalTable: React.FC<ITableProps> = ({ data, id, columns, lastRowHighlight }: ITableProps) => (
  <div className="table-section max-w-lg origin-left">
    <table id={id} className="default-table">
      <tbody className="default-table-body">
        {data &&
          data.map((item, index) => (
            <tr
              key={index.toString()}
              className={`${
                lastRowHighlight && index === data.length - 1 ? 'table-body-box-highlight' : 'table-body-box'
              }`}
            >
              {columns.map((column, columnIndex) => (
                <td
                  key={columnIndex.toString()}
                  className={`table-body-title ${columnIndex === columns.length - 1 && 'text-right'} ${
                    lastRowHighlight && index === data.length - 1 && 'table-body-title-highlight'
                  }`}
                >
                  {column.render(item[column.key])}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default MinimalTable;
