import { twMerge } from 'tailwind-merge';
import { useEffect, useState } from 'react';

import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';
import useWhiteLabel from '@hooks/use-white-label';

import { IProductOneProposal } from '@contexts/product-one/types';
import { IProductTwoProposal } from '@contexts/product-two/types';

import LegendContainer from '@components/atoms/new-proposal/legend-container';
import ManagementCardDetail from '@components/atoms/new-proposal/icon-card/management';
import RetailCardDetail from '@components/atoms/new-proposal/icon-card/retail';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

import { validationDatePricesExpirationDate } from '@utils/datetime';

import { createManagementcardData, dataForManagementProposalDetail } from './management-helper';
import { createRetailCardData } from './helper';
import './styles.css';

function ProposalValidity({
  leadName,
  pricesExpirationDate,
  isWhiteLabelProposal,
}: {
  leadName?: string;
  pricesExpirationDate?: string;
  isWhiteLabelProposal: boolean;
}) {
  return (
    <p
      className={twMerge(
        isWhiteLabelProposal ? 'text-white-label-main ' : 'text-primary-70',
        ' justify-start items-start w-full flex',
      )}
    >
      Proposta válida para {leadName} <br></br> até {validationDatePricesExpirationDate(pricesExpirationDate)}
    </p>
  );
}

function CompanyDifferentialsLabel({
  isWhiteLabelProposal,
  isManagementPath,
}: {
  isWhiteLabelProposal: boolean;
  isManagementPath: boolean;
}) {
  if (isWhiteLabelProposal) {
    return (
      <span className="text-heading-medium xl:text-heading-xlarge 2xl:text-display-small mx-auto xl:leading-[3rem] text-[#2E2E2E]">
        <a className={`text-white-label-main`}>Condição especial</a>
        <p>de migração e gestão de mercado livre de energia</p>
      </span>
    );
  } else if (isManagementPath) {
    return (
      <span className="xl:w-full xl:h-full xl:flex xl:justify-center xl:items-center">
        <p className="text-heading-medium xl:text-heading-xlarge 2xl:text-display-small mx-auto xl:leading-[3rem] text-[#2E2E2E]">
          A Clarke é a gestora independente mais <a className="text-primary-70">relevante</a>,{' '}
          <a className="text-primary-70">tecnológica</a> e com o <b className="text-primary-70">melhor preço</b> do
          mercado.
        </p>
      </span>
    );
  } else {
    return (
      <span className="xl:w-full xl:h-full xl:flex xl:justify-center xl:items-center flex flex-col relative">
        <p className="text-heading-medium xl:text-heading-xlarge 2xl:text-display-small mx-auto xl:leading-[3rem] text-[#2E2E2E]">
          A Clarke e a Energisa vão te oferecer a experiência mais <a className="text-primary-70">simples</a>,{' '}
          <a className="text-primary-70">econômica</a>, <a className="text-primary-70">tecnológica</a> e{' '}
          <a className="text-primary-70">segura</a>
        </p>
      </span>
    );
  }
}

function ManagementProposalDetail() {
  const { proposal: managementProposal } = useProductOne();
  const { proposal: retailProposal } = useProductTwo();

  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const [proposal, setProposal] = useState<IProductOneProposal | IProductTwoProposal>();
  const pricesExpirationDate = retailProposal?.inputs?.retailTradersPrices[0].expirationDate;
  useEffect(() => {
    setProposal(isManagementPath ? managementProposal : retailProposal);
  }, [managementProposal, retailProposal, isManagementPath]);

  const calculatorMetadata = (proposal as IProductOneProposal | IProductTwoProposal)?.calculatorMetadata;
  const managementProposalDetail = dataForManagementProposalDetail(
    calculatorMetadata as IProductOneProposal['calculatorMetadata'],
  );
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();

  const leadName = proposal?.lead?.name;

  return (
    <SlideContainer className="min-h-fit h-full">
      <section className="management-detail-section-styles min-h-fit">
        <div className="management-detail-text-container my-10 sm:my-0">
          <CompanyDifferentialsLabel isWhiteLabelProposal={isWhiteLabelProposal} isManagementPath={isManagementPath} />
          <ProposalValidity
            leadName={leadName}
            pricesExpirationDate={pricesExpirationDate}
            isWhiteLabelProposal={isWhiteLabelProposal}
          />
        </div>
        <div
          style={{
            backgroundColor: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.darker : 'none',
          }}
          className="management-detail-div-container"
        >
          <LegendContainer
            isWhiteLabelProposal={isWhiteLabelProposal}
            legend={isManagementPath ? 'Proposta de gestão' : 'Resumo da proposta'}
            legendTextColor="text-neutral-40"
            className="absolute top-4 right-6 xl:top-2 xl:relative"
          />
          {isManagementPath && (
            <ManagementCardDetail
              ManagementcardData={createManagementcardData(
                managementProposalDetail,
                calculatorMetadata as IProductOneProposal['calculatorMetadata'],
              )}
            />
          )}
          {isRetailPath && (
            <RetailCardDetail
              retailCardData={createRetailCardData(calculatorMetadata as IProductTwoProposal['calculatorMetadata'])}
            />
          )}
        </div>
      </section>
    </SlideContainer>
  );
}

export default ManagementProposalDetail;
