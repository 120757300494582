import { AxiosResponse } from 'axios';
import {
  IFeasibilityAnalysisProposal,
  IFeasibilityAnalysisProposals,
  IFeasibilityAnalysisSimulation,
  IFeasibilityAnalysisSimulationExtendedResult,
  IFeasibilityAnalysisSimulationTariffFlagScenario,
  IFeasibilityAnalysisSimulationTariffFlagScenarios,
  IFeasibilityAnalysisSimulationUnitReport,
} from '@contexts/feasibility-analysis/types';
import {
  parseFinancialProjectionPayload,
  parseProposalEconomyProjectionTableColumns,
  parseProposalEconomyProjectionTableData,
} from '@contexts/parser';
import { IResponseBase } from '@contexts/types';
import { isObjectEmpty, transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';

import { IFeasibilityAnalysisProposalSectionBaseSideSubsection } from '@components/molecules/feasibility-analysis/simulation/section-base/section-base-side-subsection';
import { IFeasibilityAnalysisProposalSectionBaseLowerSubsection } from '@components/molecules/feasibility-analysis/simulation/section-base/section-base-lower-subsection';
import { IFeasibilityAnalysisProposalSectionBaseUpperSubsection } from '@components/molecules/feasibility-analysis/simulation/section-base/section-base-upper-subsection';
import parseFreeMarketPricesResumeTable from '@components/molecules/feasibility-analysis/simulation/section-base/free-market-prices-table/parser';
import parseUnitsResumeTable from '@components/molecules/feasibility-analysis/simulation/section-base/units-resume-table/parser';
import parseCaptiveMarketTable from '@components/molecules/feasibility-analysis/simulation/section-base/captive-market-table/parser';
import parseFreeMarketTable from '@components/molecules/feasibility-analysis/simulation/section-base/free-market-table/parser';
import parseGeneralInformationsTable from '@components/molecules/feasibility-analysis/simulation/section-base/general-informations-table/parser';
import parsePremisesTable from '@components/molecules/feasibility-analysis/simulation/section-base/premises-table/parser';
import parseTariffFlagEconomyTable from '@components/molecules/feasibility-analysis/simulation/section-base/tariff-flag-economy-table/parser';
import { IEconomyChart } from '@components/molecules/general/economy-chart';

import { IFeasibilityAnalysisSimulatorInputs } from './types';

export const parseFeasibilityAnalysisSimulationPayload = (
  simulationData: IFeasibilityAnalysisSimulatorInputs,
): Record<string, any> => {
  const simulationDataWithManualPricesAndDiscounts = {
    ...simulationData,
    manualDiscountInputs: simulationData.hasManualDiscount
      ? parseFinancialProjectionPayload(simulationData.manualDiscountInputs, true)
      : undefined,
    manualAclPrice: simulationData.hasManualPrice
      ? parseFinancialProjectionPayload(simulationData.manualAclPrice)
      : undefined,
    calculationType: 'month_to_month',
  };

  return transformObjectKeysToSnakeCase(simulationDataWithManualPricesAndDiscounts);
};

export const parseFeasibilityAnalysisProposal = (proposal: Record<string, any>): IFeasibilityAnalysisProposal => {
  return transformObjectKeysToCamelCase(proposal) as IFeasibilityAnalysisProposal;
};

export const parseFeasibilityAnalysisProposals = (proposals: Record<string, any>): IFeasibilityAnalysisProposals => {
  return transformObjectKeysToCamelCase(proposals) as IFeasibilityAnalysisProposals;
};

export const parseFeasibilityAnalysisSimulatorResult = (
  createSimulationResponse: AxiosResponse<IResponseBase<any>>,
): IFeasibilityAnalysisSimulation => {
  const simulatorResult: Record<string, any> = createSimulationResponse.data.data;
  return transformObjectKeysToCamelCase(simulatorResult) as IFeasibilityAnalysisSimulation;
};

export const parseFeasibilityAnalysisProposalPayload = (
  simulatorResult: IFeasibilityAnalysisSimulation,
): Record<string, any> => {
  const proposalPayload = {
    lead: simulatorResult.inputs.lead,
    author: simulatorResult.inputs.author,
    seller: simulatorResult.inputs.seller,
    inputs: simulatorResult.inputs,
    calculatorMetadata: simulatorResult,
  };

  return transformObjectKeysToSnakeCase(proposalPayload);
};

export class FeasibilityAnalysisSimulationParser {
  leadName: string;
  simulationResult: IFeasibilityAnalysisSimulation;
  unitReport?: IFeasibilityAnalysisSimulationUnitReport;
  generalAnalysis?: boolean;

  constructor(
    leadName: string,
    simulationResult: IFeasibilityAnalysisSimulation,
    unitReport?: IFeasibilityAnalysisSimulationUnitReport,
    generalAnalysis?: boolean,
  ) {
    this.leadName = leadName;
    this.simulationResult = simulationResult;
    this.unitReport = unitReport;
    this.generalAnalysis = generalAnalysis;
  }

  parseUpperSubsectionData = (scenarioWrapper: IFeasibilityAnalysisSimulationTariffFlagScenarios) => {
    const aclTableData = !this.generalAnalysis && this.unitReport ? parseFreeMarketTable(this.unitReport) : undefined;
    const acrTable =
      !this.generalAnalysis && this.unitReport
        ? parseCaptiveMarketTable(this.unitReport, scenarioWrapper.yellow)
        : undefined;
    const unitsResumeTableData = this.generalAnalysis ? parseUnitsResumeTable(this.simulationResult) : undefined;

    const parsedUpperSubsectionData: IFeasibilityAnalysisProposalSectionBaseUpperSubsection = {
      aclTableData,
      acrTable,
      generalAnalysis: this.generalAnalysis,
      unitsResumeTableData,
    };
    return parsedUpperSubsectionData;
  };

  parseSideSubsectionsData = (
    simulationExtendedResult: IFeasibilityAnalysisSimulationExtendedResult,
    scenarioWrapper: IFeasibilityAnalysisSimulationTariffFlagScenarios,
  ) => {
    const freeMarketPrices = this.getFreeMarketPrices();
    const freeMarketPricesTableData = parseFreeMarketPricesResumeTable(
      simulationExtendedResult.simulationResultYearsList,
      freeMarketPrices,
    );

    const generalDataTableData = parseGeneralInformationsTable(
      this.simulationResult.generalReport,
      this.leadName,
      this.unitReport,
      this.generalAnalysis,
    );

    const premisesTableData = parsePremisesTable(
      this.simulationResult.targetYear,
      this.simulationResult.generalReport,
      this.unitReport,
      this.generalAnalysis,
    );

    const parsedSideSubsectionData: IFeasibilityAnalysisProposalSectionBaseSideSubsection = {
      freeMarketPricesTableData,
      premisesTableData,
      generalDataTableData,
      targetYear: this.simulationResult?.inputs.targetYear,
      tariffFlagScenarios: scenarioWrapper,
      generalAnalysis: this.generalAnalysis,
    };
    return parsedSideSubsectionData;
  };

  parseLowerSubsectionData = (
    simulationExtendedResult: IFeasibilityAnalysisSimulationExtendedResult,
    scenarioWrapper: IFeasibilityAnalysisSimulationTariffFlagScenarios,
  ) => {
    const parsedEconomyProjectionTableData = parseProposalEconomyProjectionTableData(simulationExtendedResult);
    const parsedEconomyProjectionTableColumns = parseProposalEconomyProjectionTableColumns(
      simulationExtendedResult.simulationResultYearsList,
    );

    const economyProjectionTable = {
      columns: parsedEconomyProjectionTableColumns,
      data: parsedEconomyProjectionTableData,
    };

    const chartData: IEconomyChart = {
      labels: simulationExtendedResult.simulationResultYearsList,
      currentValues: simulationExtendedResult.currentSpentByYearList,
      targetValues: simulationExtendedResult.targetSpentByYearList,
      savingValues: simulationExtendedResult.savingByYearPercentageList,
    };

    const tariffFlagEconomyTable = parseTariffFlagEconomyTable(scenarioWrapper);

    const parsedLowerSubsectionData: IFeasibilityAnalysisProposalSectionBaseLowerSubsection = {
      chartData,
      economyProjectionTable,
      tariffFlagEconomyTable,
    };
    return parsedLowerSubsectionData;
  };

  parseFeasibilityAnalysisSimulationExtendedResultByTariffFlag = (
    tariffFlagScenario: IFeasibilityAnalysisSimulationTariffFlagScenario,
  ): IFeasibilityAnalysisSimulationExtendedResult => {
    const parseMarketsFinancialDataComparison = (
      simulationResultYearsList: Array<string>,
      currentSpentByYear: Record<string, number>,
      savingByYearInPercentage: Record<string, number>,
      targetSpentByYear: Record<string, number>,
    ) => {
      const targetSpentByYearList = simulationResultYearsList.map((year) => targetSpentByYear[year]);
      const currentSpentByYearList = simulationResultYearsList.map((year) => currentSpentByYear[year]);
      const savingByYearPercentageList = simulationResultYearsList.map((year) =>
        Math.round(savingByYearInPercentage[year] * 100),
      );
      const targetSpent = targetSpentByYearList.reduce((accumulated, value) => accumulated + value);
      const currentSpent = currentSpentByYearList.reduce((accumulated, value) => accumulated + value);

      return {
        currentSpent,
        currentSpentByYear,
        currentSpentByYearList,
        savingByYearPercentageList,
        targetSpent,
        targetSpentByYear,
        targetSpentByYearList,
      };
    };

    const simulationResultYearsList = Object.keys(tariffFlagScenario.savingByYear);
    const {
      currentSpent,
      currentSpentByYear,
      currentSpentByYearList,
      savingByYearPercentageList,
      targetSpent,
      targetSpentByYear,
      targetSpentByYearList,
    } = parseMarketsFinancialDataComparison(
      simulationResultYearsList,
      tariffFlagScenario.currentSpentByYear,
      tariffFlagScenario.savingByYearInPercentage,
      tariffFlagScenario.targetSpentByYear,
    );

    const initialExpenses =
      tariffFlagScenario.initialExpenses ??
      tariffFlagScenario.totalSaving - tariffFlagScenario.totalSavingWithInitialExpenses;
    return {
      ...tariffFlagScenario,
      totalSavingByMonth: tariffFlagScenario.averageSavingByMonth,
      currentSpent,
      currentSpentByYear,
      currentSpentByYearList,
      initialExpenses,
      saving: tariffFlagScenario.totalSavingWithInitialExpenses,
      savingByYearPercentageList,
      savingPercentage: tariffFlagScenario.totalSavingInPercentage,
      simulationResultYearsList,
      targetSpent,
      targetSpentByYear,
      targetSpentByYearList,
    };
  };

  getFreeMarketPrices = () => {
    const manualAclPrice = this.simulationResult.inputs.manualAclPrice;

    return isObjectEmpty(manualAclPrice)
      ? (this.simulationResult.unitsReport[0].premises.aclPrices as Record<string, any>)
      : (manualAclPrice as Record<string, any>);
  };
}
