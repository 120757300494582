import numeral from 'numeral';

const parseFreeMarketPricesResumeTable = (
  simulationResultYearsList?: Array<string>,
  listOfPrices?: Record<string, any>,
): Record<string, any>[] | undefined => {
  if (!simulationResultYearsList) return undefined;

  const targetYear = [...simulationResultYearsList].pop() as string;

  const parsedStartYear = parseInt(simulationResultYearsList[0], 10);
  const parsedTargetYear = parseInt(targetYear, 10);

  const finalList: Record<string, string>[] = [];

  if (Array.isArray(listOfPrices)) {
    const sortedListOfPrices = [...listOfPrices].sort((a, b) => {
      if (a.year < b.year) {
        return -1;
      }
      if (a.year > b.year) {
        return 1;
      }
      return 0;
    });

    sortedListOfPrices.forEach((item: Record<any, any>) => {
      if (parseInt(item.year, 10) >= parsedStartYear && parseInt(item.year, 10) <= parsedTargetYear) {
        finalList.push({
          title: item.year,
          value: numeral(item.fixedPrice).format('$ 0,0.00'),
        });
      }
    });
  } else if (listOfPrices) {
    Object.keys(listOfPrices).forEach((years) => {
      Object.keys(listOfPrices[years]).forEach((aclYear) => {
        finalList.push({
          title: aclYear,
          value: numeral(listOfPrices[years][aclYear]).format('$ 0,0.00'),
        });
      });
    });
  }

  return finalList;
};

export default parseFreeMarketPricesResumeTable;
