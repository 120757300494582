import { useContextSelector } from 'use-context-selector';

import { ComparisonBillContext } from '@contexts/comparison-bill';
import { IComparisonBillContext } from '@contexts/comparison-bill/types';

const useComparisonBill = (): IComparisonBillContext => {
  const createComparisonBillSimulationHandler = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.createComparisonBillSimulationHandler,
  );
  const createComparisonBillProposalHandler = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.createComparisonBillProposalHandler,
  );
  const getComparisonBillProposalsHandler = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.getComparisonBillProposalsHandler,
  );
  const getComparisonBillProposalByIdHandler = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.getComparisonBillProposalByIdHandler,
  );
  const deleteComparisonBillProposalsHandler = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.deleteComparisonBillProposalsHandler,
  );
  const deleteComparisonBillAllProposalsByLeadIdHandler = useContextSelector(
    ComparisonBillContext,
    (sales) => sales.deleteComparisonBillAllProposalsByLeadIdHandler,
  );
  const updateSellerOfComparisonBillProposal = useContextSelector(
    ComparisonBillContext,
    (comparisonBill) => comparisonBill.updateSellerOfComparisonBillProposal,
  );

  return {
    createComparisonBillSimulationHandler,
    createComparisonBillProposalHandler,
    getComparisonBillProposalsHandler,
    getComparisonBillProposalByIdHandler,
    deleteComparisonBillProposalsHandler,
    deleteComparisonBillAllProposalsByLeadIdHandler,
    updateSellerOfComparisonBillProposal,
  };
};

export default useComparisonBill;
