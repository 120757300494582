import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { IFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/types';
import { parseFeasibilityAnalysisProposal } from '@contexts/feasibility-analysis/parser';

import useFeasibilityAnalysis from '@hooks/use-feasibility-analysis';

import { getCalculatorName } from '@utils/common';

import { Container } from '@components/molecules/general/simulation-styled-components';
import FeasibilityAnalysisSimulationIntroductorySection from '@components/organisms/feasibility-analysis/simulation/feasibility-analysis-simulation-introductory-section';
import FeasibilityAnalysisSimulationUnitsEconomySection from '@components/organisms/feasibility-analysis/simulation/feasibility-analysis-simulation-units-economy-section';
import FeasibilityAnalysisSimulationGroupEconomySection from '@components/organisms/feasibility-analysis/simulation/feasibility-analysis-simulation-group-economy-section';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';
import { useAuth } from '@src/auth-wrapper';

const FeasibilityAnalysisSimulation = () => {
  const { id } = useParams();
  const { getFeasibilityAnalysisProposalById } = useFeasibilityAnalysis();
  const { user } = useAuth();

  const navigate = useNavigate();

  const [proposal, setProposal] = React.useState<IFeasibilityAnalysisProposal>();

  const getProposal = (id: string) => {
    getFeasibilityAnalysisProposalById(id)
      .then((response) => {
        if (response.data) {
          const parsedProposal = parseFeasibilityAnalysisProposal(response.data.data);
          setProposal(parsedProposal);

          eventTracker.trackEvent(eventLabels.SIMULATION_PAGE_VIEWED, {
            product: getCalculatorName(location.href),
            user_groups: user?.groups ? user?.groups.join(', ') : '',
            user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
          });
        } else navigate(SIMULATION_NOT_FOUND_PATH);
      })
      .catch(() => navigate(SIMULATION_NOT_FOUND_PATH));
  };

  React.useEffect(() => {
    if (id) getProposal(id);
  }, [id]);

  const hasMultipleUnits =
    proposal?.calculatorMetadata.unitsReport && proposal?.calculatorMetadata.unitsReport.length > 1;

  return (
    <Container className="flex flex-col">
      <FeasibilityAnalysisSimulationIntroductorySection
        simulationResult={proposal}
        hasMultipleUnits={hasMultipleUnits}
      />
      <FeasibilityAnalysisSimulationUnitsEconomySection {...{ proposal }} />
      <FeasibilityAnalysisSimulationGroupEconomySection
        simulationResult={proposal?.calculatorMetadata}
        hasMultipleUnits={hasMultipleUnits}
        leadName={proposal?.lead.name}
      />
    </Container>
  );
};

export default FeasibilityAnalysisSimulation;
