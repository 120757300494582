import { useContextSelector } from 'use-context-selector';

import { FeasibilityAnalysisContext } from '@contexts/feasibility-analysis';
import { IFeasibilityAnalysisContext } from '@contexts/feasibility-analysis/types';

const useFeasibilityAnalysis = (): IFeasibilityAnalysisContext => {
  const createFeasibilityAnalysisSimulation = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.createFeasibilityAnalysisSimulation,
  );
  const createFeasibilityAnalysisProposal = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.createFeasibilityAnalysisProposal,
  );
  const getFeasibilityAnalysisProposals = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.getFeasibilityAnalysisProposals,
  );
  const getFeasibilityAnalysisProposalById = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.getFeasibilityAnalysisProposalById,
  );
  const deleteFeasibilityAnalysisProposalsHandler = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.deleteFeasibilityAnalysisProposalsHandler,
  );
  const deleteFeasibilityAnalysisAllProposalsByLeadIdHandler = useContextSelector(
    FeasibilityAnalysisContext,
    (sales) => sales.deleteFeasibilityAnalysisAllProposalsByLeadIdHandler,
  );
  const updateSellerOfFeasibilityAnalysisProposal = useContextSelector(
    FeasibilityAnalysisContext,
    (feasibilityAnalysis) => feasibilityAnalysis.updateSellerOfFeasibilityAnalysisProposal,
  );

  return {
    createFeasibilityAnalysisSimulation,
    createFeasibilityAnalysisProposal,
    getFeasibilityAnalysisProposals,
    getFeasibilityAnalysisProposalById,
    deleteFeasibilityAnalysisProposalsHandler,
    deleteFeasibilityAnalysisAllProposalsByLeadIdHandler,
    updateSellerOfFeasibilityAnalysisProposal,
  };
};

export default useFeasibilityAnalysis;
