import { ILowTensionSimulationProps } from '@pages/low-tension/simulator/low-tension-simulator-result';

import LowTensionProposalPreviewEconomyResults from '@components/molecules/low-tension/lead-proposals-hub/economy-results';

type ILowTensionSimulationCore = ILowTensionSimulationProps;

const LowTensionSimulatorResultCore = ({ simulatorResult }: ILowTensionSimulationCore) => {
  const simulationResults = [simulatorResult.firstResult, simulatorResult.secondResult, simulatorResult.thirdResult];
  return (
    <div className="mb-8">
      <LowTensionProposalPreviewEconomyResults simulationResults={simulationResults} />
    </div>
  );
};

export default LowTensionSimulatorResultCore;
