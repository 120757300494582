import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { IFeasibilityAnalysisSimulatorInputs } from '@contexts/feasibility-analysis/types';

import SimulatorFormGeneralSection from '@components/molecules/form/simulator/sections/simulator-form-general-section';
import SimulatorFormFinancialProjectionSection from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';
import SimulatorFormTargetYearsSection from '@components/molecules/form/simulator/sections/simulator-form-target-years-section';
import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';

interface IFeasibilityAnalysisSimulatorFormCore {
  formController: UseFormReturn<IFeasibilityAnalysisSimulatorInputs, any>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: IFeasibilityAnalysisSimulatorInputs) => void;
}

const FeasibilityAnalysisSimulatorFormCore = ({
  formController,
  formRef,
  onSubmit,
}: IFeasibilityAnalysisSimulatorFormCore) => {
  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormGeneralSection<IFeasibilityAnalysisSimulatorInputs>
        fields={{
          productType: true,
        }}
      />
      <SimulatorFormTargetYearsSection />
      <SimulatorFormFinancialProjectionSection uniqueTargetYears />
      <SimulatorFormUnitsSection<IFeasibilityAnalysisSimulatorInputs> />
    </form>
  );
};

export default FeasibilityAnalysisSimulatorFormCore;
