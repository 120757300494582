import React from 'react';

import { SlideContainer } from '@components/molecules/general/simulation-styled-components';
import Cover from '@components/molecules/comparison-bill/simulation/cover';

interface IComparisonBillSimulationIntroductorySection {
  leadName?: string;
}

const ComparisonBillSimulationIntroductorySection = ({ leadName }: IComparisonBillSimulationIntroductorySection) => {
  return (
    <>
      <SlideContainer>
        <Cover companyName={leadName} />
      </SlideContainer>
    </>
  );
};

export default ComparisonBillSimulationIntroductorySection;
