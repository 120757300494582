import * as outlineIcons from '@heroicons/react/outline';
import * as solidIcons from '@heroicons/react/solid';

export interface IHeroIcon {
  id?: string;
  icon: keyof typeof outlineIcons;
  useSolid?: boolean;
  className?: string;
  onClick?: () => void;
}

export function HeroIconSolidOrOutline({ id, icon, className, useSolid, onClick }: IHeroIcon) {
  const HeroIconComponent = useSolid ? solidIcons[icon] : outlineIcons[icon];
  return <HeroIconComponent id={id} className={className} onClick={onClick} />;
}
