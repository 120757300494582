import { formatNumber } from '@utils/numeral';

interface IComparisonBillSimulationItem {
  label: string;
  description: string;
  value?: number;
}

const ComparisonBillProposalPreviewResultItem = ({ label, description, value }: IComparisonBillSimulationItem) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-paragraph-small font-normal text-neutral-50">{label}</dt>
      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
        <p className="text-paragraph-medium font-normal	 text-black">{description}</p>
        <p className="mt-2 text-paragraph-medium font-normal	text-black">{formatNumber(value, '$ 0,0')}</p>
      </dd>
    </div>
  );
};

export default ComparisonBillProposalPreviewResultItem;
