import { sum } from 'lodash';
import { ISimulationUnitPayloadBase } from '@contexts/types';

export const calculateManagementPriceSuggestion = (
  consumptionOffPeak: ISimulationUnitPayloadBase['consumptionOffPeak'],
  consumptionPeak: ISimulationUnitPayloadBase['consumptionPeak'],
) => {
  const consumptionOffPeakSum = consumptionOffPeak ? sum(Object.values(consumptionOffPeak)) : 0;
  const consumptionPeakSum = consumptionPeak ? sum(Object.values(consumptionPeak)) : 0;

  const suggestedManagementPrice = applyMultiplicativeFactorOnConsumption(consumptionOffPeakSum, consumptionPeakSum);
  const standardizedManagementPrice = standardizeManagementPrice(suggestedManagementPrice);

  return standardizedManagementPrice;
};

const applyMultiplicativeFactorOnConsumption = (consumptionOffPeakSum: number, consumptionPeakSum: number) => {
  const consumptionAverage = (consumptionOffPeakSum + consumptionPeakSum) / 12;
  const valueConvertedFromKWhToMWh = consumptionAverage / 1000;

  return valueConvertedFromKWhToMWh * 15;
};

export const standardizeManagementPrice = (suggestedManagementPrice: number) => {
  const roundedValue = Math.round(suggestedManagementPrice / 50) * 50;
  const [minValueLimit, maxValueLimit] = [200, 5000];

  if (roundedValue >= minValueLimit && roundedValue <= maxValueLimit) {
    return roundedValue;
  } else if (roundedValue < minValueLimit) {
    return minValueLimit;
  } else if (roundedValue > maxValueLimit) {
    return maxValueLimit;
  }
};
