import React from 'react';
import numeral from 'numeral';

import {
  GridBody,
  GridContainer,
  GridColumnLower,
  GridColumnBigger,
  BoldTitle,
  FlexItemsEnd,
  GreenHeaderLine,
  EconomyBox,
  EconomyBoxColumn,
  LargeText,
  NormalText,
  XLargeText,
  LargeBoldText,
  XXLargeText,
  EconomyResultBox,
} from './style';

export interface IComparisonResult {
  comparisonResultData: Record<string, any>;
}

const ComparisonResult = ({ comparisonResultData }: IComparisonResult): JSX.Element => {
  return (
    <GridContainer id="resultSection">
      <GridBody>
        <GridColumnLower>
          <GreenHeaderLine />
          <BoldTitle>Comparativo e economia</BoldTitle>
        </GridColumnLower>
        <GridColumnBigger>
          <EconomyBox>
            <EconomyBoxColumn>
              <LargeText>Antes: Mercado Cativo</LargeText>
              <NormalText>Distribuição + Energia ({comparisonResultData.currentMarket.edc})</NormalText>
            </EconomyBoxColumn>
            <EconomyBoxColumn>
              <XLargeText>{numeral(comparisonResultData.currentMarket.price).format('$ 0,0.00')}</XLargeText>
            </EconomyBoxColumn>
          </EconomyBox>
          <EconomyBox>
            <EconomyBoxColumn>
              <LargeText>Depois: Mercado Livre</LargeText>
              <NormalText>
                Distribuição ({comparisonResultData.targetMarket.edc}) + Energia (
                {comparisonResultData.targetMarket.provider})
              </NormalText>
            </EconomyBoxColumn>
            <EconomyBoxColumn>
              <XLargeText>{numeral(comparisonResultData.targetMarket.price).format('$ 0,0.00')}</XLargeText>
            </EconomyBoxColumn>
          </EconomyBox>
          <FlexItemsEnd>
            <NormalText>{comparisonResultData.generalData.groupName}</NormalText>
            <NormalText>{comparisonResultData.generalData.unitName}</NormalText>
            <EconomyResultBox>
              <LargeBoldText>Economia</LargeBoldText>
              <XXLargeText>{numeral(comparisonResultData.economy).format('$ 0,0.00')}</XXLargeText>
            </EconomyResultBox>
          </FlexItemsEnd>
        </GridColumnBigger>
      </GridBody>
    </GridContainer>
  );
};
export default ComparisonResult;
