import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import { ISimulationPayloadBase } from '@contexts/types';

import { getMonthNameFromIndex } from '@utils/constants';

import { fieldVerificator, requiredFieldSymbol } from '@components/molecules/form/simulator/helper';
import validateSimulatorFormFields from '@components/molecules/form/simulator/validate-simulator-form-fields';
import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';

import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';

import { NumericFormField } from '../fields/numeric-form-field';
import { Button, Tooltip } from '@clarke-energia/foton';

import { CONTRACTED_DEMAND_PATH, GENERAL_CALCULATOR_PATH } from '@routers/constants';

type ISimulatorUnitConsumptionData = ISimulatorFormUnitSection;

interface ISimulatorUnitConsumptionSubsection extends ISimulatorFormUnitSection, ISimulatorUnitConsumptionData {
  consumptionType: 'PEAK' | 'OFF_PEAK';
  errorMessage?: string;
  handleChange: (value: any, fieldName: string) => void;
  unitNum: number;
  validateFields: () => void;
}
function SimulatorUnitConsumptionSubsection({
  consumptionType,
  errorMessage,
  handleChange,
  unifiedConsumption,
  unitNum,
  validateFields,
}: ISimulatorUnitConsumptionSubsection) {
  const { setValue } = useFormContext<ISimulationPayloadBase>();

  const isDemand = window.location.pathname === `${CONTRACTED_DEMAND_PATH}/${GENERAL_CALCULATOR_PATH}` ? true : false;
  const consumptionTypeTranslation = `${consumptionType === 'OFF_PEAK' ? 'Fora' : ''} Ponta`;
  const consumptionHistoryTitle = `Histórico de ${isDemand ? 'Demanda' : 'Consumo'} ${
    !unifiedConsumption ? consumptionTypeTranslation : ''
  }`;
  const consumptionKey = unifiedConsumption
    ? 'consumption'
    : consumptionType === 'OFF_PEAK'
    ? 'consumptionOffPeak'
    : 'consumptionPeak';

  const monthsIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const prefixFieldName = `units.${unitNum}.${consumptionKey}`;

  const clearHistoryConsumptionPeakAndOffPeak = () => {
    monthsIndexes.forEach((num) => {
      const fieldName = `${prefixFieldName}.${num}` as keyof ISimulationPayloadBase;
      setValue(fieldName, '');
    });
  };
  return (
    <div>
      <div className="flex justify-between gap-2 mb-3 items-center">
        <div className="flex justify-start">
          <h2 className="text-paragraph-large font-bold">
            {consumptionHistoryTitle} {requiredFieldSymbol}
          </h2>
          <Tooltip content={tooltipsContent.unit.consumption} />
        </div>
        <Button
          kind="secondary"
          label="Limpar histórico de consumo"
          size="xSmall"
          type="button"
          onClick={() => clearHistoryConsumptionPeakAndOffPeak()}
        />
      </div>
      {errorMessage && <p className="text-paragraph-small text-danger-60 mb-2">{errorMessage}</p>}
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4">
        {monthsIndexes.map((num) => {
          const fieldName = `${prefixFieldName}.${num}` as keyof ISimulationPayloadBase;
          return (
            <NumericFormField<ISimulationPayloadBase>
              key={`unit-${unitNum}-${consumptionKey}-month-${num}`}
              id={fieldName}
              field={fieldName}
              label={getMonthNameFromIndex(num)}
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                suffix: isDemand ? ' kW' : ' kWh',
              }}
              options={{
                onChange: (value) => {
                  const normalizedValue = value >= 1 ? value : undefined;
                  handleChange(normalizedValue, fieldName);
                },
                onBlur: () => validateFields(),
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export function SimulatorUnitConsumptionData({
  unitNum,
  fieldsController,
  unifiedConsumption,
}: ISimulatorUnitConsumptionData) {
  const {
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors, touchedFields },
  } = useFormContext<ISimulationPayloadBase>();
  const consumptionValueParser = (value: string) => {
    return !Number.isNaN(value) && value !== null && value !== '' ? parseFloat(value) : undefined;
  };
  const handleChange = (value: any, fieldName: string) => {
    const parsedConsumptionValue = consumptionValueParser(value);
    setValue(fieldName as keyof ISimulationPayloadBase, parsedConsumptionValue);
  };

  const unitFieldName = `units.${unitNum}`;
  const consumptionPeakFieldName = `${unitFieldName}.consumptionPeak` as keyof ISimulationPayloadBase;
  const consumptionOffPeakFieldName = `${unitFieldName}.consumptionOffPeak` as keyof ISimulationPayloadBase;
  const consumptionFieldName = `${unitFieldName}.consumption` as keyof ISimulationPayloadBase;

  const unitWatch = useWatch({ name: unitFieldName });
  const consumptionPeakFieldWatch = unitWatch.consumptionPeak;
  const consumptionOffPeakFieldWatch = unitWatch.consumptionOffPeak;

  const shouldShowConsumptionPeak = fieldVerificator({
    fieldName: 'consumptionPeak',
    fieldPriority: 'PRIMARY',
    fields: fieldsController,
  });
  const shouldShowConsumptionOffPeak = fieldVerificator({
    fieldName: 'consumptionOffPeak',
    fieldPriority: 'PRIMARY',
    fields: fieldsController,
  });

  const touchedFieldsNumber = Object.keys(touchedFields).length;
  const validateFields = () => {
    const fieldsNamesList = unifiedConsumption
      ? [consumptionFieldName]
      : [
          shouldShowConsumptionPeak ? consumptionPeakFieldName : '',
          shouldShowConsumptionOffPeak ? consumptionOffPeakFieldName : '',
        ];

    const values = watch();

    validateSimulatorFormFields({
      ...{ values, setError, clearErrors },
      onlyFields: fieldsNamesList,
    });
  };

  React.useEffect(() => {
    if (touchedFieldsNumber > 0) {
      validateFields();
    }
  }, [touchedFieldsNumber]);

  const consumptionPeakFieldErrorMessage = get(errors, consumptionPeakFieldName)?.message as string | undefined;
  const consumptionOffPeakFieldErrorMessage = get(errors, consumptionOffPeakFieldName)?.message as string | undefined;
  const consumptionFieldErrorMessage = get(errors, consumptionFieldName)?.message as string | undefined;

  return (
    <div className="flex flex-col gap-10 mb-3">
      {shouldShowConsumptionPeak && !unifiedConsumption && (
        <SimulatorUnitConsumptionSubsection
          unitNum={unitNum}
          consumptionType="PEAK"
          handleChange={handleChange}
          errorMessage={consumptionPeakFieldErrorMessage}
          unifiedConsumption={unifiedConsumption}
          validateFields={validateFields}
        />
      )}
      {shouldShowConsumptionOffPeak && (
        <SimulatorUnitConsumptionSubsection
          unitNum={unitNum}
          consumptionType="OFF_PEAK"
          handleChange={handleChange}
          errorMessage={consumptionOffPeakFieldErrorMessage ?? consumptionFieldErrorMessage}
          unifiedConsumption={unifiedConsumption}
          validateFields={validateFields}
        />
      )}
    </div>
  );
}
