import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName } from '@utils/common';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';

import { parseComparisonBillProposal } from '@contexts/comparison-bill/parser';
import { IComparisonBillProposal } from '@contexts/comparison-bill/types';
import useComparisonBill from '@hooks/use-comparison-bill';

import { Container } from '@components/molecules/general/simulation-styled-components';
import ComparisonBillSimulationIntroductorySection from '@components/organisms/comparison-bill/simulation/comparison-bill-simulation-introductory-section';
import ComparisonBillSimulationEconomySection from '@components/organisms/comparison-bill/simulation/comparison-bill-simulation-economy-section';
import ComparisonBillSimulationConclusiveSection from '@components/organisms/comparison-bill/simulation/comparison-bill-simulation-conclusive-section';
import { useAuth } from '@src/auth-wrapper';

const ComparisonBill = () => {
  const { id } = useParams();
  const { getComparisonBillProposalByIdHandler } = useComparisonBill();
  const { user } = useAuth();

  const navigate = useNavigate();

  const [proposal, setProposal] = useState<IComparisonBillProposal>();

  const getProposal = (id: string) => {
    getComparisonBillProposalByIdHandler(id)
      .then((response) => {
        if (response.data.data) {
          const parsedProposal = parseComparisonBillProposal(response.data.data);
          setProposal(parsedProposal);

          eventTracker.trackEvent(eventLabels.SIMULATION_PAGE_VIEWED, {
            product: getCalculatorName(location.href),
            user_groups: user?.groups ? user?.groups.join(', ') : '',
            user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
          });
        } else navigate(SIMULATION_NOT_FOUND_PATH);
      })
      .catch(() => navigate(SIMULATION_NOT_FOUND_PATH));
  };

  React.useEffect(() => {
    id && getProposal(id);
  }, [id]);

  return (
    <>
      <Container className="flex flex-col items-center">
        <ComparisonBillSimulationIntroductorySection leadName={proposal?.lead?.name} />
        <ComparisonBillSimulationEconomySection {...{ proposal }} />
        <ComparisonBillSimulationConclusiveSection />
      </Container>
    </>
  );
};

export default ComparisonBill;
