import React from 'react';
import numeral from 'numeral';

const ParserFreeMarketEnergyBill = (data: any, total: number): Record<string, any>[] => {
  function findAclPriceInYear(year: number) {
    const aclObject = data?.premises?.aclPrices?.find((item: Record<string, any>) => parseInt(item.year, 10) === year);
    return aclObject?.fixedPrice;
  }

  const initialValues = {
    energyConsumption: {
      quantity: data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.electricityWithLosses,
      tariff: findAclPriceInYear(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.report?.year),
    },
    pisCofins: {
      quantity: null,
      tariff: 9.25,
    },
    icms: {
      quantity: null,
      tariff: data?.premises?.icms?.percentageValue,
    },
    totalWithTaxes:
      data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.consumptionOffPeak +
      data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.totalWithTaxes,
  };

  const othersCosts =
    Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.securityAndModulationEnergyCharges) +
    Number(data?.premises.general.clarkeManagement) +
    Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.contributionCcee);

  return [
    {
      item: 'Energia (kW)',
      quantity: `${Number(initialValues.energyConsumption?.quantity).toFixed(2).replace('.', ',')}` || '-',
      tariff:
        numeral(`${Number(initialValues.energyConsumption?.tariff).toFixed(2).replace('.', ',')}`).format('$ 0,0.00') ||
        '-',
      total:
        numeral(
          `${Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.electricityTariff)
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: <div className="text-gray-500">Destaque PIS/COFINS</div>,
      quantity: '-',
      tariff: Number(initialValues.pisCofins?.tariff).toFixed(2).replace('.', ',') + ' %' || '-',
      total:
        numeral(
          `${Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.pisCofinsHighlight)
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'ICMS',
      quantity: '-',
      tariff: Number(initialValues.icms?.tariff).toFixed(2).replace('.', ',') + ' %' || '-',
      total:
        numeral(
          `${Number(data?.tariffFlagScenarios?.default?.sampleMonthSimulation?.targetMarket.electricityIcms)
            .toFixed(2)
            .replace('.', ',')}`,
        ).format('$ 0,0.00') || '-',
    },
    {
      item: 'Outros',
      quantity: '-',
      tariff: '-',
      total: othersCosts ? numeral(othersCosts).format('$ 0,0.00') : '-',
    },
    {
      item: 'Total Energia',
      quantity: '',
      tariff: '',
      total: numeral(total).format('$ 0,0.00') || '-',
    },
  ];
};

export default ParserFreeMarketEnergyBill;
