import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { CustomClipboardProvider } from '@contexts/custom-clipboard';
import LeadsProvider from '@contexts/lead';
import SalesProvider from '@contexts/sales';
import { UsersProvider } from '@contexts/users';
import ManagementContractProvider from '@contexts/management-contract';
import WhiteLabelProvider from '@contexts/white-label';
import ProductOneProvider from '@contexts/product-one';
import { WhiteLabelHistory } from '@pages/white-label';

import ScreenLayout from '@components/templates/screen-layout';

import NotFound404 from '@pages/404';

import ProductOneSimulator from '@pages/product-one/simulator';
import ProductOneSimulation from '@pages/product-one/simulation';
import ProductOneSimplifiedProposal from '@pages/product-one/simplified-proposal';
import ProductOneLeadsHistory from '@pages/product-one/leads-history';
import ProductOneContractGenerator from '@pages/product-one/management-contract';

import ProductTwoSimulator from '@pages/product-two/simulator';
import ProductTwoSimulation from '@pages/product-two/simulation';
import ProductTwoSimplifiedProposal from '@pages/product-two/simplified-proposal';
import ProductTwoLeadsHistory from '@pages/product-two/leads-history';
import ProductTwoContractGenerator from '@pages/product-two/management-contract';

import FeasibilityAnalysisSimulator from '@pages/feasibility-analysis/simulator';
import FeasibilityAnalysisSimulation from '@pages/feasibility-analysis/simulation';
import FeasibilityAnalysisLeadsHistory from '@pages/feasibility-analysis/leads-history';

import ComparisonBillSimulator from '@pages/comparison-bill/simulator';
import ComparisonBill from '@pages/comparison-bill/simulation';
import ComparisonBillLeadsHistory from '@pages/comparison-bill/leads-history';

import ContractedDemandSimulator from '@pages/contracted-demand/simulator';

import LowTensionSimulator from '@pages/low-tension/simulator';
import LowTensionLeadsHistory from '@pages/low-tension/leads-history';
import LowTensionSimulation from '@pages/low-tension/simulation';
import WhiteLabelSupplierFormPage from '@pages/white-label/form';

import Home from '@pages/home';

import PrivateRoute from './private-route';
import { ProtectedRoutes } from './protected-routes';
import SimulatorsRoute from './simulators-router';
import {
  COMPARISON_BILL_PATH,
  CONTRACTED_DEMAND_PATH,
  FEASIBILITY_ANALYSIS_PATH,
  CLARKE_MANAGEMENT_PATH,
  GENERAL_CALCULATOR_PATH,
  GENERAL_LIST_PATH,
  GENERAL_SIMPLIFIED_PROPOSAL_PATH,
  GENERAL_SIMULATION_PATH,
  HOME_PATH,
  CLARKE_RETAIL_PATH,
  LOW_TENSION_PATH,
  LEAD_PROPOSALS_PATH,
  GENERAL_CONTRACT_PATH,
  WHITE_LABEL_PATH,
  GENERAL_SUPPLIERS_PATH,
  UPSERT_SUPPLIERS_PROJECTION_PATH,
  ADD_SUPPLIERS_PROJECTION_PATH,
} from './constants';

const MainRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <LeadsProvider>
              <SalesProvider>
                <CustomClipboardProvider>
                  <SimulatorsRoute>
                    <ScreenLayout />
                  </SimulatorsRoute>
                </CustomClipboardProvider>
              </SalesProvider>
            </LeadsProvider>
          </PrivateRoute>
        }
      >
        <Route path={HOME_PATH} element={<Home />} />
        <Route
          path={CLARKE_MANAGEMENT_PATH}
          element={
            <UsersProvider>
              <WhiteLabelProvider>
                <Outlet />
              </WhiteLabelProvider>
            </UsersProvider>
          }
        >
          <Route
            index
            path={GENERAL_LIST_PATH}
            element={
              <ManagementContractProvider>
                <ProductOneLeadsHistory />
              </ManagementContractProvider>
            }
          />
          <Route
            index
            path={LEAD_PROPOSALS_PATH}
            element={
              <ManagementContractProvider>
                <ProductOneLeadsHistory />
              </ManagementContractProvider>
            }
          />
          <Route
            index
            path={GENERAL_CALCULATOR_PATH}
            element={
              <WhiteLabelProvider>
                <ProductOneSimulator />
              </WhiteLabelProvider>
            }
          />
          <Route
            index
            path={GENERAL_CONTRACT_PATH}
            element={
              <ManagementContractProvider>
                <ProductOneContractGenerator />
              </ManagementContractProvider>
            }
          />
        </Route>
        <Route
          path={CLARKE_RETAIL_PATH}
          element={
            <UsersProvider>
              <Outlet />
            </UsersProvider>
          }
        >
          <Route
            index
            path={GENERAL_LIST_PATH}
            element={
              <ManagementContractProvider>
                <ProductTwoLeadsHistory />
              </ManagementContractProvider>
            }
          />
          <Route
            index
            path={LEAD_PROPOSALS_PATH}
            element={
              <ManagementContractProvider>
                <ProductTwoLeadsHistory />
              </ManagementContractProvider>
            }
          />
          <Route index path={GENERAL_CALCULATOR_PATH} element={<ProductTwoSimulator />} />
          <Route
            index
            path={GENERAL_CONTRACT_PATH}
            element={
              <ManagementContractProvider>
                <ProductTwoContractGenerator />
              </ManagementContractProvider>
            }
          />
        </Route>
        <Route
          path={FEASIBILITY_ANALYSIS_PATH}
          element={
            <UsersProvider>
              <Outlet />
            </UsersProvider>
          }
        >
          <Route index path={GENERAL_LIST_PATH} element={<FeasibilityAnalysisLeadsHistory />} />
          <Route index path={LEAD_PROPOSALS_PATH} element={<FeasibilityAnalysisLeadsHistory />} />
          <Route index path={GENERAL_CALCULATOR_PATH} element={<FeasibilityAnalysisSimulator />} />
        </Route>
        <Route
          path={COMPARISON_BILL_PATH}
          element={
            <UsersProvider>
              <Outlet />
            </UsersProvider>
          }
        >
          <Route index path={GENERAL_LIST_PATH} element={<ComparisonBillLeadsHistory />} />
          <Route index path={LEAD_PROPOSALS_PATH} element={<ComparisonBillLeadsHistory />} />
          <Route index path={GENERAL_CALCULATOR_PATH} element={<ComparisonBillSimulator />} />
        </Route>
        <Route
          path={LOW_TENSION_PATH}
          element={
            <UsersProvider>
              <Outlet />
            </UsersProvider>
          }
        >
          <Route index path={GENERAL_LIST_PATH} element={<LowTensionLeadsHistory />} />
          <Route index path={LEAD_PROPOSALS_PATH} element={<LowTensionLeadsHistory />} />
          <Route index path={GENERAL_CALCULATOR_PATH} element={<LowTensionSimulator />} />
        </Route>
        <Route path={CONTRACTED_DEMAND_PATH} element={<Outlet />}>
          <Route index path={GENERAL_CALCULATOR_PATH} element={<ContractedDemandSimulator />} />
        </Route>
        <Route
          path={WHITE_LABEL_PATH}
          element={
            <WhiteLabelProvider>
              <Outlet />
            </WhiteLabelProvider>
          }
        >
          <Route element={<ProtectedRoutes permissionKeys={['isSuperAdmin']} redirectTo={HOME_PATH} />}>
            <Route index path={GENERAL_SUPPLIERS_PATH} element={<WhiteLabelHistory />} />
            <Route
              path={`${GENERAL_SUPPLIERS_PATH}${ADD_SUPPLIERS_PROJECTION_PATH}`}
              element={<WhiteLabelSupplierFormPage contractStep="ADD" />}
            />
          </Route>

          <Route element={<ProtectedRoutes permissionKeys={['isSuperAdmin', 'isWhiteLabel']} redirectTo={HOME_PATH} />}>
            <Route
              index
              path={`${GENERAL_SUPPLIERS_PATH}${UPSERT_SUPPLIERS_PROJECTION_PATH}/:id`}
              element={<WhiteLabelSupplierFormPage contractStep="UPDATE" />}
            />
          </Route>
        </Route>
      </Route>
      <Route
        element={
          <SimulatorsRoute>
            <Outlet />
          </SimulatorsRoute>
        }
      >
        <Route
          path={CLARKE_MANAGEMENT_PATH}
          element={
            <WhiteLabelProvider>
              <Outlet />
            </WhiteLabelProvider>
          }
        >
          <Route
            index
            path={GENERAL_SIMULATION_PATH}
            element={
              <ProductOneProvider>
                <ProductOneSimulation />
              </ProductOneProvider>
            }
          />
          <Route path={GENERAL_SIMPLIFIED_PROPOSAL_PATH} element={<ProductOneSimplifiedProposal />} />
        </Route>
        <Route path={CLARKE_RETAIL_PATH} element={<Outlet />}>
          <Route index path={GENERAL_SIMULATION_PATH} element={<ProductTwoSimulation />} />
          <Route path={GENERAL_SIMPLIFIED_PROPOSAL_PATH} element={<ProductTwoSimplifiedProposal />} />
        </Route>
        <Route path={FEASIBILITY_ANALYSIS_PATH} element={<Outlet />}>
          <Route index path={GENERAL_SIMULATION_PATH} element={<FeasibilityAnalysisSimulation />} />
        </Route>
        <Route path={COMPARISON_BILL_PATH} element={<Outlet />}>
          <Route index path={GENERAL_SIMULATION_PATH} element={<ComparisonBill />} />
        </Route>
        <Route path={LOW_TENSION_PATH} element={<Outlet />}>
          <Route index path={GENERAL_SIMULATION_PATH} element={<LowTensionSimulation />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;
